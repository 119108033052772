<template>
  <div class="flex flex-col text-theme-500 items-center min-h-screen bg-gray-100 px-2 text-center">
    <div class="font-bold text-3xl mt-2">FAQs</div>
    <div class="font-bold text-2xl text-black">Frequently Asked Questions</div>
    <div class="mb-4">We have shared some of the most frequently asked questions to help you out!</div>
    <retryable-error v-if="hasError" text="Error loading FAQ" @retry="fetchFaqs"></retryable-error>
    <loading v-else-if="faqs === null" text="Loading..." class="mt-3"></loading>
    <template v-else>
      <div class="flex flex-col px-8 gap-2 text-start w-full">
        <div class="input-group w-full">
          <div class="input-group-text w-42px"><i class="fas fa-filter" /></div>
          <input class="form-control group-text" type="text" v-model="searchText" placeholder="filter questions" />
        </div>
        <div class="flex flex-col gap-1 mb-4">
          <collapsible-text v-for="faq in faqs" :key="faq.question" :title="faq.question" v-show="shouldShowFaq(faq)" :content="generateContent(faq.answer)"></collapsible-text>
          <div v-if="filteredFaqs.length === 0" class="text-center mt-8">no matching questions found</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CollapsibleText from '@/components/CollapsibleText'
import Loading from '@/components/Loading'
import RetryableError from '@/components/RetryableError'

export default {
  name: 'faq',
  components: {
    CollapsibleText,
    Loading,
    RetryableError
  },
  data () {
    return {
      faqs: null,
      hasError: false,
      searchText: ''
    }
  },
  computed: {
    filteredFaqs () {
      const self = this
      return this.faqs.filter(faq => self.shouldShowFaq(faq))
    }
  },
  methods: {
    shouldShowFaq (faq) {
      return faq.question.toLowerCase().includes(this.searchText.toLowerCase())
    },
    generateContent (answer) {
      if (typeof answer === 'string') return answer
      return answer.join('\n\n')
    },
    async fetchFaqs () {
      this.faqs = null
      this.hasError = false
      try {
        const response = await this.$store.dispatch('fetchHelpFile', 'faq.json')
        const decoder = new TextDecoder('utf-8')
        this.faqs = JSON.parse(decoder.decode(response.data))
      } catch (error) {
        console.error('Error loading faq', error)
        this.hasError = true
      }
    }
  },
  mounted () {
    this.fetchFaqs()
  }
}
</script>
