import { createStore } from 'vuex'
import { jwtDecode } from 'jwt-decode'
import appSettings from '@/utils/settings'
import axios from 'axios'
import niFlow from './niFlowStore'
import Cookies from '@/utils/cookies'
import router from '@/router/router.js'
import _ from 'lodash'

function createNewState () {
  return {
    ...JSON.parse(JSON.stringify(appSettings)),
    appVersion: process.env.PACKAGE_VERSION,
    preAuthIntendedRoute: null,
    tokenWrapper: null,
    currentUser: null,
    presignableDocs: null,
    branding: null,
    datasetSearches: [],
    enableFeedback: false,
    selectedDataset: null,
    sourceDescriptions: {},
    filesetViewMode: 'list',
    filesetQueryParams: null,
    unifiedDatasetCategories: null,
    datasetFilters: null,
    tokenRefreshTimeout: null,
    favoriteDatasets: [],
    savedSearches: []
  }
}

export default createStore({
  state: createNewState(),
  mutations: {
    createNewState (state) {
      // preserve branding and enable feedback
      const branding = state.branding
      const enableFeedback = state.enableFeedback

      Object.assign(state, createNewState())

      state.branding = branding
      state.enableFeedback = enableFeedback
    },
    setPreAuthIntendedRoute (state, preAuthIntendedRoute) {
      state.preAuthIntendedRoute = preAuthIntendedRoute
    },
    setTokenWrapper (state, tokenWrapper) {
      state.tokenWrapper = tokenWrapper
      state.currentUser = jwtDecode(tokenWrapper.access_token)

      Cookies.set('JWT', tokenWrapper.access_token, `${tokenWrapper.expires_in}s`)
      if (typeof (Storage) !== 'undefined') {
        sessionStorage.tokenWrapper = JSON.stringify(tokenWrapper)
      }

      if (state.tokenRefreshTimeout !== null) {
        clearTimeout(state.tokenRefreshTimeout)
        state.tokenRefreshTimeout = null
      }

      // expires-in is in seconds, but refresh 1/2 time before expiring
      const expiresInMs = tokenWrapper.expires_in * 1000
      const self = this
      state.tokenRefreshTimeout = setTimeout(() => {
        // if it fails, try again in another half
        self.dispatch('refreshLoginToken', expiresInMs / 4)
      }, expiresInMs / 2)
    },
    setPresignableDocs (state, presignableDocs) {
      state.presignableDocs = presignableDocs
    },
    setBranding (state, branding) {
      state.branding = branding
    },
    addDatasetSearch (state, searchContext) {
      state.datasetSearches.push(searchContext)
    },
    removeDatasetSearch (state, searchId) {
      state.datasetSearches = state.datasetSearches.filter(search => search.id !== searchId)
    },
    clearDatasetSearches (state) {
      state.datasetSearches = []
    },
    setEnableFeedback (state, enableFeedback) {
      state.enableFeedback = enableFeedback
    },
    setSelectedDataset (state, selectedDataset) {
      state.selectedDataset = selectedDataset
    },
    clearSourceDescriptions (state) {
      state.sourceDescriptions = {}
    },
    setSourceDescriptions (state, { id, description }) {
      state.sourceDescriptions[id] = description
    },
    setFilesetViewMode (state, filesetViewMode) {
      state.filesetViewMode = filesetViewMode
    },
    setFilesetQueryParams (state, filesetQueryParams) {
      state.filesetQueryParams = filesetQueryParams
    },
    setUnifiedDatasetCategories (state, unifiedDatasetCategories) {
      state.unifiedDatasetCategories = unifiedDatasetCategories
    },
    setDatasetFilters (state, filters) {
      state.datasetFilters = filters
    },
    setSearchIsSearching (state, { searchId, isSearching }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      search.isSearching = isSearching
    },
    setSearchHasError (state, { searchId, hasError }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      search.searchError = hasError
    },
    setSearchCountError (state, { searchId, hasError }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      search.countError = hasError
    },
    setSearchResultCount (state, { searchId, resultCount }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      search.totalResultCount = resultCount
    },
    setSearchMapItems (state, { searchId, items }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      search.mapResults.items = items
    },
    setSearchMapItemsCurrentResults (state, { searchId, currentResults }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      for (const itemKey of Object.keys(currentResults)) {
        if (typeof search.mapResults.items[itemKey] !== 'undefined' || search.mapResults.items[itemKey] !== null) {
          search.mapResults.items[itemKey].currentResult = currentResults[itemKey]
        }
      }
    },
    clearMapItemCurrentResults (state, searchId) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return
      for (const itemKey of Object.keys(search.mapResults.items)) {
        search.mapResults.items[itemKey].currentResult = null
      }
    },
    setSearchMapLoading (state, { searchId, loadingData }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      search.mapResults.loadingData = loadingData
    },
    setSearchMapEndTimeMs (state, { searchId, endTimeMs }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      search.mapResults.endTimeMs = endTimeMs
    },
    setSearchMapErrorLoading (state, { searchId, errorLoading }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      search.mapResults.errorLoading = errorLoading
    },
    setSearchMapItemFilter (state, { searchId, itemFilter }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      search.mapResults.itemFilter = itemFilter
    },
    setSearchMapItemPathVisible (state, { searchId, itemKey, isVisible }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      if (typeof search.mapResults.items[itemKey] !== 'undefined' || search.mapResults.items[itemKey] !== null) {
        search.mapResults.items[itemKey].path.isVisible = isVisible
      }
    },
    setSearchMapItemPathIsLoading (state, { searchId, itemKey, isLoading }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      if (typeof search.mapResults.items[itemKey] !== 'undefined' || search.mapResults.items[itemKey] !== null) {
        search.mapResults.items[itemKey].path.isLoading = isLoading
      }
    },
    setSearchMapItemPathLoadingPercent (state, { searchId, itemKey, loadingPercent }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      if (typeof search.mapResults.items[itemKey] !== 'undefined' || search.mapResults.items[itemKey] !== null) {
        search.mapResults.items[itemKey].path.loadingPercent = loadingPercent
      }
    },
    setSearchMapItemPathPoints (state, { searchId, itemKey, points }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      if (typeof search.mapResults.items[itemKey] !== 'undefined' || search.mapResults.items[itemKey] !== null) {
        const availableLayers = search.mapResults.items[itemKey].path.availableLayers
        for (const layerName of Object.keys(points)) {
          for (const layer of availableLayers) {
            if (layerName !== layer.name) continue
            layer.points = points[layerName]
            break
          }
        }
      }
    },
    setAvailableLayers (state, { searchId, itemKey, availableLayers }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      if (typeof search.mapResults.items[itemKey] !== 'undefined' || search.mapResults.items[itemKey] !== null) {
        search.mapResults.items[itemKey].path.availableLayers = availableLayers
      }
    },
    setSearchMapItemMarker (state, { searchId, itemKey, mapMarker }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      if (typeof search.mapResults.items[itemKey] !== 'undefined' || search.mapResults.items[itemKey] !== null) {
        search.mapResults.items[itemKey].mapMarker = mapMarker
      }
    },
    addResultsToSearch (state, { searchId, results }) {
      const search = _.find(state.datasetSearches, ['id', searchId])
      if (typeof search === 'undefined') return

      if (search.results === null) search.results = []
      search.results = search.results.concat(results)
      search.currentOffset += results.length
    },
    setFavoriteDatasets (state, favoriteDatasets) {
      state.favoriteDatasets = favoriteDatasets
    },
    setSavedSearches (state, savedSearches) {
      state.savedSearches = savedSearches
    }
  },
  actions: {
    apiGet (context, { url, params, responseType }) {
      const fullUrl = context.state.gatewayApiLocation + url
      let rType = 'json'
      if (responseType) rType = responseType
      return axios.get(fullUrl, {
        responseType: rType,
        params,
        headers: {
          Authorization: `${context.state.tokenWrapper.token_type} ${context.state.tokenWrapper.access_token}`
        }
      })
    },
    apiPost (context, { url, postData }) {
      const fullUrl = context.state.gatewayApiLocation + url
      return axios.post(fullUrl, postData, {
        headers: {
          Authorization: `${context.state.tokenWrapper.token_type} ${context.state.tokenWrapper.access_token}`
        }
      })
    },
    apiPut (context, { url, putData, authHeaderValue }) {
      let authValue = `${context.state.tokenWrapper?.token_type} ${context.state.tokenWrapper?.access_token}`
      if (typeof authHeaderValue !== 'undefined' && authHeaderValue !== null) {
        authValue = authHeaderValue
      }
      const fullUrl = context.state.gatewayApiLocation + url
      return axios.put(fullUrl, putData, {
        responseType: 'json',
        headers: {
          Authorization: authValue
        }
      })
    },
    apiDelete (context, { url, deleteData }) {
      const fullUrl = context.state.gatewayApiLocation + url
      return axios.delete(fullUrl, {
        data: deleteData,
        responseType: 'json',
        headers: {
          Authorization: `${context.state.tokenWrapper.token_type} ${context.state.tokenWrapper.access_token}`
        }
      })
    },
    fetchFilesets (context) {
      const getParams = { url: '/filesets', params: null }
      return context.dispatch('apiGet', getParams)
    },
    fetchFilesetContents (context, { fileset, continuationToken, listPrefix }) {
      const getParams = { url: `/filesets/${fileset.id}/list`, params: {} }
      if (continuationToken) {
        getParams.params.continuationToken = continuationToken
      }
      if (listPrefix) {
        getParams.params.listPrefix = listPrefix
      }
      return context.dispatch('apiGet', getParams)
    },
    fetchFilesetFile (context, fileUrl) {
      const getParams = { url: fileUrl, params: null, responseType: 'arraybuffer' }
      return context.dispatch('apiGet', getParams)
    },
    fetchHelpFile (context, filename) {
      return context.dispatch('fetchFilesetFile', `/filesets/unacorn-help/download?file=${filename}`)
    },
    fetchPresignableDocs (context) {
      const getParams = { url: '/docs', params: null }
      return context.dispatch('apiGet', getParams)
    },
    getPresignedUrl (context, { docId, type }) {
      const getParams = { url: `/docs/${docId}/${type}/s3Url`, params: null, responseType: 'text' }
      return context.dispatch('apiGet', getParams)
    },
    submitFeedback (context, { username, name, email, type, description, datasets, attachments }) {
      const payload = { username, name, email, type, description, datasets, attachments }
      const postParams = { url: '/feedback/create', postData: payload }
      return context.dispatch('apiPost', postParams)
    },
    fetchUIConfiguration (context) {
      const url = `${context.state.gatewayApiLocation}/ui/config`
      const responseType = 'json'
      return axios.get(url, {
        responseType,
        Pragma: 'no-cache'
      })
    },
    uploadFeedbackAttachment (context, { attachmentData, progressDelegate }) {
      return axios.post(`${context.state.gatewayApiLocation}/feedback/upload`,
        attachmentData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Pragma: 'no-cache',
            Authorization: `${context.state.tokenWrapper.token_type} ${context.state.tokenWrapper.access_token}`
          },
          onUploadProgress: (progressEvent) => {
            if (typeof progressDelegate !== 'undefined' && progressDelegate !== null) {
              const percentComplete = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
              progressDelegate(percentComplete)
            }
          }
        }).then(response => response.data)
    },
    fetchTagsForFile (context, { filesetId, filename }) {
      const getParams = { url: `/filesets/${filesetId}/tags`, params: { file: filename } }
      return context.dispatch('apiGet', getParams)
    },
    fetchAdminEntities (context, type) {
      const getParams = { url: `/admin/${type}`, params: null }
      return context.dispatch('apiGet', getParams)
    },
    deleteAdminEntity (context, { type, id }) {
      const deleteParams = { url: `/admin/${type}/${id}`, deleteData: null }
      return context.dispatch('apiDelete', deleteParams)
    },
    saveAdminEntityEdit (context, { type, entity }) {
      const putParams = { url: `/admin/${type}`, putData: entity }
      return context.dispatch('apiPut', putParams)
    },
    saveAdminEntityNew (context, { type, entity }) {
      const postParams = { url: `/admin/${type}`, postData: entity }
      return context.dispatch('apiPost', postParams)
    },
    setAdminEntityEnabled (context, { type, id }) {
      const putParams = { url: `/admin/${type}/${id}/enable` }
      return context.dispatch('apiPut', putParams)
    },
    setAdminEntityDisabled (context, { type, id }) {
      const putParams = { url: `/admin/${type}/${id}/disable` }
      return context.dispatch('apiPut', putParams)
    },
    importAdminEntities (context, { type, entities, isReplace }) {
      const putData = {
        entities,
        isReplace
      }
      const putParams = { url: `/admin/${type}/import`, putData }
      return context.dispatch('apiPut', putParams)
    },
    filesetPresignedUrlUpload (context, { fileset, file }) {
      const getParams = { url: `/filesets/${fileset.id}/upload`, params: { file } }
      return context.dispatch('apiGet', getParams)
    },
    uploadFile (context, { url, file, progressDelegate }) {
      return axios.put(url, file, {
        onUploadProgress: (progressEvent) => {
          const percentComplete = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          progressDelegate(percentComplete)
        }
      })
    },
    fetchUnifiedDatasets (context) {
      const getParams = { url: '/datasets' }
      return context.dispatch('apiGet', getParams)
    },
    fetchUnifiedDatasetDescription (context, { category, datasetId }) {
      const getParams = { url: `/datasets/${category}/${datasetId}/describe` }
      return context.dispatch('apiGet', getParams)
    },
    sampleUnifiedDataset (context, { category, datasetId }) {
      const getParams = { url: `/datasets/${category}/${datasetId}/sample` }
      return context.dispatch('apiGet', getParams)
    },
    searchUnifiedDataset (context, { category, datasetId, searchData }) {
      const postParams = { url: `/datasets/${category}/${datasetId}/search`, postData: searchData }
      return context.dispatch('apiPost', postParams)
    },
    fetchTotalResultCount (context, { category, datasetId, searchData }) {
      const postParams = { url: `/datasets/${category}/${datasetId}/count`, postData: searchData }
      return context.dispatch('apiPost', postParams)
    },
    fetchAdminMetadata (context) {
      const getParams = { url: '/admin/metadata' }
      return context.dispatch('apiGet', getParams)
    },
    login (context, { username, password }) {
      const payload = { username, password }
      const fullUrl = `${context.state.gatewayApiLocation}/auth/login`
      return axios.post(fullUrl, payload, {
        headers: {
          Pragma: 'no-cache'
        }
      })
    },
    tokenCheck (context, refreshToken) {
      const payload = { refreshToken }
      const fullUrl = `${context.state.gatewayApiLocation}/auth/refresh`

      return axios.post(fullUrl, payload, {
        headers: {
          Pragma: 'no-cache'
        }
      })
    },
    refreshLoginToken ({ dispatch, commit, state }, nextFailureRefreshTimeMs) {
      dispatch('tokenCheck', state.tokenWrapper.refresh_token).then(response => {
        commit('setTokenWrapper', response.data)
      }).catch(error => {
        console.log('Error refreshing login token', error)
        if (state.tokenRefreshTimeout !== null) {
          clearTimeout(state.tokenRefreshTimeout)
          state.tokenRefreshTimeout = null
        }

        if (error.response.status === 401 || nextFailureRefreshTimeMs <= 10000) {
          // refresh token was invalid or we have less than 10 seconds to try again
          dispatch('performLogout')
          return
        }

        state.tokenRefreshTimeout = setTimeout(() => {
          // if it fails, try again in another half
          dispatch('refreshLoginToken', nextFailureRefreshTimeMs / 2)
        }, nextFailureRefreshTimeMs)
      })
    },
    performLogout (context) {
      if (context.state.tokenRefreshTimeout !== null) {
        clearTimeout(context.state.tokenRefreshTimeout)
        context.state.tokenRefreshTimeout = null
      }

      Cookies.remove('JWT')
      if (typeof (Storage) !== 'undefined') {
        sessionStorage.removeItem('tokenWrapper')
      }

      context.commit('createNewState')
      router.push({ name: 'Login' })
    },
    fetchFavoriteDatasets (context) {
      const getParams = { url: '/favorite/datasets' }
      return context.dispatch('apiGet', getParams)
    },
    favoriteDataset (context, { datasetCategory, datasetId }) {
      const postData = { datasetCategory, datasetId }
      const postParams = { url: '/favorite/datasets', postData }
      return context.dispatch('apiPost', postParams)
    },
    removeFavoriteDataset (context, { datasetCategory, datasetId }) {
      const deleteData = { datasetCategory, datasetId }
      const deleteParams = { url: '/favorite/datasets', deleteData }
      return context.dispatch('apiDelete', deleteParams)
    },
    fetchSavedQueries (context) {
      const getParams = { url: '/favorite/queries' }
      return context.dispatch('apiGet', getParams)
    },
    saveQuery (context, { datasetCategory, datasetId, queryName, query }) {
      const postData = { datasetCategory, datasetId, queryName, query }
      const postParams = { url: '/favorite/queries', postData }
      return context.dispatch('apiPost', postParams)
    },
    removeSavedQuery (context, { datasetCategory, datasetId, queryName }) {
      const deleteData = { datasetCategory, datasetId, queryName, query: {} }
      const deleteParams = { url: '/favorite/queries', deleteData }
      return context.dispatch('apiDelete', deleteParams)
    },
    resetPassword (context, { password, resetToken }) {
      const putData = { password }
      const putParams = { url: '/profile/reset-password', authHeaderValue: resetToken, putData }
      return context.dispatch('apiPut', putParams)
    },
    updateProfile (context, { email, firstName, lastName }) {
      const putData = { email, firstName, lastName }
      const putParams = { url: '/profile', putData }
      return context.dispatch('apiPut', putParams)
    },
    getUserGroups (context, userId) {
      const getParams = { url: `/admin/users/${userId}/groups` }
      return context.dispatch('apiGet', getParams)
    },
    getUserRoles (context, userId) {
      const getParams = { url: `/admin/users/${userId}/roles` }
      return context.dispatch('apiGet', getParams)
    },
    getUserLockStatus (context, userId) {
      const getParams = { url: `/admin/users/${userId}/lockStatus` }
      return context.dispatch('apiGet', getParams)
    },
    unlockUserAccount (context, userId) {
      const putParams = { url: `/admin/users/${userId}/unlock` }
      return context.dispatch('apiPut', putParams)
    },
    cacLogin (context, code) {
      // no auth token, dont use the helper which sends an auth token
      const postData = { code }
      const fullUrl = `${context.state.gatewayApiLocation}/geoAxis/cacLogin`
      return axios.post(fullUrl, postData)
    },
    cacStatus (context) {
      const getParams = { url: '/geoAxis/cacStatus' }
      return context.dispatch('apiGet', getParams)
    },
    linkCac (context, { password, code }) {
      const postData = { password, code }
      const postParams = { url: '/geoAxis/linkCac', postData }
      return context.dispatch('apiPost', postParams)
    },
    unlinkCac (context) {
      const deleteParams = { url: '/geoAxis/unlinkCac' }
      return context.dispatch('apiDelete', deleteParams)
    },
    fetchAuditEvents (context, { pageNumber, username, responseCode }) {
      const params = { pageNumber, username, responseCode }
      const getParams = { url: '/auditing/events', params }
      return context.dispatch('apiGet', getParams)
    },
    fetchAuditUsernames (context) {
      const getParams = { url: '/auditing/usernames' }
      return context.dispatch('apiGet', getParams)
    },
    fetchAuditResponseCodes (context) {
      const getParams = { url: '/auditing/responseCodes' }
      return context.dispatch('apiGet', getParams)
    },
    fetchApiKeys (context) {
      const getParams = { url: '/apiKeys' }
      return context.dispatch('apiGet', getParams)
    },
    deleteApiKey (context, keyName) {
      const deleteParams = { url: `/apiKeys/${keyName}` }
      return context.dispatch('apiDelete', deleteParams)
    },
    saveApiKey (context, apiKey) {
      const postParams = { url: '/apiKeys', postData: apiKey }
      return context.dispatch('apiPost', postParams)
    },
    updateApiKey (context, apiKey) {
      const putParams = { url: '/apiKeys', putData: apiKey }
      return context.dispatch('apiPut', putParams)
    },
    fetchNewNotices (context) {
      const getParams = { url: '/notices/new' }
      return context.dispatch('apiGet', getParams)
    },
    fetchAllNotices (context) {
      const getParams = { url: '/notices' }
      return context.dispatch('apiGet', getParams)
    },
    markNoticesViewed (context, noticeIds) {
      const putParams = { url: '/notices/viewed', putData: noticeIds }
      return context.dispatch('apiPut', putParams)
    },
    fetchLastResults (context, { category, datasetId, searchData }) {
      const postParams = { url: `/datasets/${category}/${datasetId}/search/last`, postData: searchData }
      return context.dispatch('apiPost', postParams)
    },
    fetchMapPath (context, { category, datasetId, searchData }) {
      const postParams = { url: `/datasets/${category}/${datasetId}/search/path`, postData: searchData }
      return context.dispatch('apiPost', postParams)
    },
    fetchMapPathCount (context, { category, datasetId, searchData }) {
      const postParams = { url: `/datasets/${category}/${datasetId}/count/path`, postData: searchData }
      return context.dispatch('apiPost', postParams)
    }
  },
  modules: {
    niFlow
  }
})
