<template>
  <form v-show="isInitial" enctype="multipart/form-data" novalidate>
    <div class="border-dashed border-2 border-theme-500 h-100 hover:bg-theme-200 cursor-pointer p-2" @click="$refs.fileUpload.click()">
      <input
        type="file"
        class="hidden"
        accept="*"
        id="file"
        name="file"
        ref="fileUpload"
        :multiple="false"
        @click="preupload($event.target)"
        @drop="saveFiles($event.dataTransfer.files)"
        @change="saveFiles($event.target.files)" />
      <div class="flex flex-col cursor-pointer items-center text-theme-500">
        <i class="fas fa-upload  block text-xl mb-2"></i>
        <span class="text-sm">Click to Upload a File</span>
        <span class="text-xs">10MB File Size Limit</span>
      </div>
    </div>
  </form>
  <div v-if="!isInitial" class="flex w-full justify-center items-center font-bold">
    <div v-if="isSaving" class="text-theme-500">
      Uploading {{currentFile.name}} - {{uploadProgress}}%
    </div>
    <div v-else-if="isSuccess" class="text-emerald-500">
      File successfully uploaded!
    </div>
    <div v-else-if="isFailed" class="text-red-500">
      File failed to upload.
    </div>
  </div>
</template>

<script>
const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3
const MAX_FILE_SIZE = 10000000 // 10 MB

export default {
  name: 'upload-file',
  emits: ['file-uploaded'],
  data () {
    return {
      currentStatus: STATUS_INITIAL,
      uploadProgress: 0,
      currentFile: null
    }
  },
  computed: {
    isInitial () {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    }
  },
  methods: {
    reset () {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadProgress = 0
    },
    saveFiles: async function (fileList) {
      if (fileList.length !== 1) {
        // do nothing (should never happen since multiple is set to false)
        return
      }

      for (const file of fileList) {
        if (file.size > MAX_FILE_SIZE) {
          this.$swal({
            icon: 'error',
            title: 'Large File',
            text: `The file "${file.name}" is too large, files must be under 10MB.`
          })
          return
        }
      }

      // use a loop incase we want to use multi-file uploads later
      //  for now, keeping it simple
      for (const file of fileList) {
        try {
          this.uploadProgress = 0
          await this.$nextTick() // guarantee an update to the progress bar
          await this.saveFile(file)
          this.currentStatus = STATUS_SUCCESS
        } catch (e) {
          console.error(e)
          this.currentStatus = STATUS_FAILED
        }
      }

      const self = this
      setTimeout(() => {
        self.reset()
      }, 2000)
    },
    saveFile: async function (file) {
      this.currentStatus = STATUS_SAVING
      this.currentFile = file

      // create FormData for direct file upload, then append the file to FormData
      const attachmentData = new FormData()
      attachmentData.append('file', file, file.name)

      const self = this
      const fileUploaded = await this.$store.dispatch('uploadFeedbackAttachment', {
        attachmentData,
        progressDelegate: (percentComplete) => {
          self.uploadProgress = percentComplete
        }
      })

      this.uploadProgress = 100
      this.$emit('file-uploaded', fileUploaded)
    },
    preupload (input) {
      // handle edge case where user uploads same file path with different contents
      input.value = null
    }
  },
  mounted () {
    this.reset()
  }
}
</script>
