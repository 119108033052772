<template>
  <div class="flex flex-col border-b border-theme-500">
    <!-- title bar -->
    <div class="p-1 flex justify-between bg-theme-500 items-center text-white">
      <inline-dropdown v-if="actions.length > 0" :options="actions" button-title="Actions"></inline-dropdown>
      <div v-else></div> <!-- empty div for flex spacing -->
      <div class="flex flex-col items-center gap-1">
        <div class="text-2xl font-bold">{{ title }}</div>
        <div v-if="iterations.length > 0" class="flex text-xs cursor-pointer border rounded">
          <div :class="['flex gap-1 items-center py-1 px-2 rounded hover:bg-theme-200', {'bg-theme-900': viewMode === 'diff'}]" @click="viewMode = 'diff'"><i class="fas fa-code-compare font-normal" />Diff</div>
          <div class="border-r"></div>
          <div :class="['flex gap-1 items-center py-1 px-2 rounded hover:bg-theme-200', {'bg-theme-900': viewMode === 'single'}]" @click="viewMode = 'single'"><i class="fas fa-file font-normal" />Single</div>
        </div>
      </div>
      <small>{{ subtitle }}</small>
    </div>

    <div v-if="viewMode === 'single'" class="flex w-full h-full overflow-auto">
      <!-- iteration bar -->
      <div class="p-2 max-w-1/5 w-1/5 bg-gray-300 flex flex-col text-theme-500 overflow-y-auto" ref="iterationBar">
        <div v-if="iterations.length > 0">
          <div v-for="iteration in iterations" :key="iteration.id" :class="['my-1 rounded p-1 cursor-pointer flex flex-col', {'bg-gray-100': selectedIteration?.id === iteration.id}]" @click="selectedIteration = iteration">
            <div class="flex justify-between">
              <span class="mx-1 font-bold"><i class="fas fa-file mr-1" />{{ iteration.label }}</span>
              <iteration-deployment-badge v-if="isUserAdmin" :iteration-id="iteration.id" :ref="`deploymentBadge-${iteration.id}`" :iteration-name="iteration.label"></iteration-deployment-badge>
            </div>
            <div class="flex w-full justify-between">
              <div class="text-xs flex flex-col">
                <span>{{ iteration.creatorUsername }}</span>
                <relative-time-display :time-ms="iteration.createdDateMs"></relative-time-display>
              </div>
              <i class="fas fa-download cursor-pointer self-end text-theme-500 hover:text-theme-300" @click.stop.prevent="downloadIteration(iteration)" />
            </div>
          </div>
        </div>
      </div>

      <!-- iteration content -->
      <div v-if="selectedIteration" class="max-w-4/5 w-4/5 overflow-y-auto h-full hljs">
        <highlightjs autodetect :code="selectedIteration.content"></highlightjs>
      </div>
    </div>
    <div v-else class="h-full w-full overflow-auto vue-diff-theme-dark flex flex-col text-theme-500">
      <div class="w-full bg-gray-300 flex justify-around cursor-pointer hover:text-theme-300">
        <inline-dropdown dropdown-style="text" button-title="Select an Iteration" :options="prevDiffOptions" :update-button-title="true" :disabled="diffRendering"></inline-dropdown>
        <inline-dropdown dropdown-style="text" button-title="Select an Iteration" :options="nextDiffOptions" :update-button-title="true" :disabled="diffRendering"></inline-dropdown>
      </div>
      <background-diff-renderer v-if="prevDiffIteration !== null && nextDiffIteration !== null"
        :properties="diffProps"
        @rendering="diffRendering = true"
        @rendered="diffRendering = false"
      >
      </background-diff-renderer>
      <div v-else class="flex items-center justify-center h-full text-gray-200"><div>Select 2 iterations to diff</div></div>
    </div>
    <modal v-if="deployDetails !== null">
      <template v-slot:header>
        <h1 class="text-2xl">Deploy Iteration</h1>
      </template>
      <template v-slot:body>
        <loading v-if="deployDetails.isDeploying" text="Deploying Iteration..."></loading>
        <template v-else>
          <div class="flex flex-col">
            <strong>Iteration</strong>
            <v-select :options="iterations" v-model="deployDetails.iteration" placeholder="select an iteration to deploy" :append-to-body="true"></v-select>
          </div>
          <div class="flex flex-col mt-2">
            <strong>Environment</strong>
            <v-select :options="flowEnvironments" v-model="deployDetails.environment" placeholder="select an environment for the deployment" :append-to-body="true"></v-select>
          </div>
          <div class="mt-4 text-sm w-full text-center"><strong>Note </strong>Enter either a username and password OR an auth token OR if the environment has no authentication, leave all blank.</div>
          <div class="mt-2">
            <div class="flex flex-col w-full">
              <strong>Username</strong>
              <input class="form-control" v-model="deployDetails.username" />
              <strong>Password</strong>
              <input class="form-control" v-model="deployDetails.password" type="password" />
            </div>
            <div class="flex flex-col w-full mt-2">
              <strong>Auth Token</strong>
              <input class="form-control" v-model="deployDetails.authToken" />
            </div>
          </div>
        </template>
      </template>
      <template v-slot:footer>
        <template v-if="!deployDetails.isDeploying">
          <button class="btn btn-theme-muted" @click="deployDetails = null"><i class="fas fa-ban mr-1" />Cancel</button>
          <button class="btn btn-theme" @click="deployRequest" :disabled="!isDeployValid"><i class="fas fa-paper-plane mr-1" />Deploy</button>
        </template>
      </template>
    </modal>
  </div>
</template>

<script>
import BackgroundDiffRenderer from '@/components/BackgroundDiffRenderer'
import IterationDeploymentBadge from '@/components/niflow/IterationDeploymentBadge'
import InlineDropdown from '@/components/InlineDropdown'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import RelativeTimeDisplay from '@/components/RelativeTimeDisplay'
import UserRolesMixin from '@/mixins/UserRolesMixin'
import hljsVuePlugin from '@highlightjs/vue-plugin'
import hljs from 'highlight.js/lib/core'

export default {
  name: 'iterations-display',
  components: {
    BackgroundDiffRenderer,
    IterationDeploymentBadge,
    InlineDropdown,
    Loading,
    Modal,
    RelativeTimeDisplay,
    highlightjs: hljsVuePlugin.component
  },
  mixins: [UserRolesMixin],
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: true },
    iterations: { type: Array, required: true },
    mainIteration: { type: Object, required: false, default: null },
    additionalActions: { type: Array, required: false, default: () => [] }
  },
  data () {
    return {
      viewMode: 'single',
      selectedIteration: null,
      prevDiffIteration: null,
      nextDiffIteration: null,
      diffLanguage: 'plaintext',
      diffRendering: false,
      flowEnvironments: [],
      deployDetails: null
    }
  },
  computed: {
    isDeployValid () {
      if (this.deployDetails === null) return false

      // not valid without an iteration
      if (this.deployDetails.iteration === null) return false

      // not valid without an env
      if (this.deployDetails.environment === null) return false

      return true
    },
    actions () {
      const baseActions = []
      const deployAction = {
        id: 'deploy',
        name: 'Deploy...',
        icon: 'fa-paper-plane',
        performAction: () => {
          this.deployDetails = {
            isDeploying: false,
            iteration: null,
            environment: null,
            username: null,
            password: null,
            authToken: null
          }
        }
      }

      if (this.isUserAdmin) {
        baseActions.push(deployAction)
      }

      return baseActions.concat(this.additionalActions)
    },
    diffProps () {
      return {
        mode: 'split',
        language: this.diffLanguage,
        prev: this.prevDiffIteration.content,
        current: this.nextDiffIteration.content
      }
    },
    isUserAdmin () {
      return this.userRoles.includes('admin')
    },
    prevDiffOptions () {
      return this.generateDiffOptions((iteration) => {
        this.prevDiffIteration = iteration
      })
    },
    nextDiffOptions () {
      return this.generateDiffOptions((iteration) => {
        this.nextDiffIteration = iteration
      })
    }
  },
  watch: {
    isUserAdmin () {
      this.loadFlowEnvironments()
    },
    iterations () {
      this.scrollToLatest()
    },
    viewMode () {
      this.prevDiffIteration = null
      this.nextDiffIteration = null
    },
    prevDiffIteration () {
      if (this.prevDiffIteration === null && this.nextDiffIteration === null) {
        this.diffLanguage = 'plaintext'
        return
      }

      this.diffLanguage = hljs.highlightAuto(this.prevDiffIteration.content).language
    }
  },
  methods: {
    downloadIteration (iteration) {
      const language = hljs.highlightAuto(iteration.content).language

      const downloadBlob = new Blob([iteration.content], { type: `text/${language}` })
      const exportUrl = URL.createObjectURL(downloadBlob)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = exportUrl
      link.download = `${this.title}_${iteration.label}.${language}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // revoke 2 seonds later to let the download finish
      setTimeout(() => {
        window.URL.revokeObjectURL(exportUrl)
      }, 2000)
    },
    async loadFlowEnvironments () {
      if (!this.isUserAdmin) return

      const response = await this.$store.dispatch('fetchAdminEntities', 'flowEnvironments')
      this.flowEnvironments = response.data.map(env => {
        return {
          id: env.name,
          label: `${env.displayName} (${env.baseUrl})`
        }
      })
    },
    async deployRequest () {
      if (!this.isDeployValid || !this.isUserAdmin || this.deployDetails === null) return

      this.deployDetails.isDeploying = true
      try {
        const data = {
          ...this.deployDetails,
          requestId: this.requestId
        }
        const response = await this.$store.dispatch('niFlow/deployIteration', data)
        this.$refs[`deploymentBadge-${data.iteration.id}`][0].addDeployment(response.data)
        this.deployDetails = null
      } catch (error) {
        console.error('Error deploying an iteration', error)
        this.$swal({
          icon: 'error',
          title: 'Error Deploying',
          text: 'An error occurred deploying the iteration. Please try again.'
        })
        this.deployDetails.isDeploying = false
      }
    },
    generateDiffOptions (performAction) {
      const diffIterations = this.iterations.map((iteration, idx) => {
        return {
          id: iteration.id,
          name: iteration.label,
          icon: null,
          performAction: () => {
            performAction(iteration)
          }
        }
      })

      if (this.mainIteration === null) return diffIterations

      return [{
        id: 'mainIteration',
        name: 'Main',
        icon: null,
        performAction: () => {
          performAction(this.mainIteration)
        }
      }].concat(diffIterations)
    },
    async scrollToLatest () {
      this.selectedIteration = this.iterations.at(-1)
      if (typeof this.$refs.iterationBar !== 'undefined') {
        await this.$nextTick()
        this.$refs.iterationBar.scrollTop = this.$refs.iterationBar.scrollHeight
      }
    }
  },
  mounted () {
    this.scrollToLatest()
    this.loadFlowEnvironments()
  }
}
</script>

<style lang="less" scoped>
.vue-diff-wrapper {
  @apply overflow-scroll;
}
</style>
