<template>
  <entity-editor
    id-column="name"
    entity-type-display="Flow Group"
    entity-type="flowGroups"
    editor-help-text="Ensure you add the new corresponding role 'flow-developer-{name}' to keycloak to enable access for users."
    :new-entity-schema="schemaObj"
    :disabled-actions="['disable']"
    :columns="flowGroupColumns"
    :perform-clear="reloadFlowGroups">
  </entity-editor>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'

export default {
  name: 'flow-group-settings',
  components: {
    EntityEditor
  },
  data () {
    return {
      flowGroupColumns: [
        { field: 'displayName', display: 'Display Name' },
        { field: 'description', display: 'Description' }
      ],
      schemaObj: {
        name: '',
        displayName: '',
        description: ''
      }
    }
  },
  methods: {
    reloadFlowGroups () {
      this.$store.dispatch('niFlow/loadFlowDetails').catch(error => {
        console.error('Error fetching flow group data', error)
      })
    }
  }
}
</script>
