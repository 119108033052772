<template>
  <div>
    <router-view></router-view>
    <div v-if="isRootRequests" class="flex flex-col text-theme-500 gap-6">
      <div class="flex flex-col px-2 mt-2">
        <strong>Status Filter</strong>
        <v-select :options="requestFilters" v-model="requestFilter" placeholder="select a status to filter" :append-to-body="true"></v-select>
      </div>
      <div v-for="group in groups" :key="group.name">
        <div class="flex justify-between m-1">
          <div class="text-xl font-bold">{{ group.displayName }}</div>
          <button class="btn btn-theme btn-sm" @click="openNewRequestModal(group)"><i class="fas fa-plus mr-1"></i>New Request</button>
        </div>
        <table class="w-full">
          <thead class="w-full text-white sticky top-0 bg-theme-400 z-999">
            <tr>
              <th class="px-1 border-r border-black">Name</th>
              <th class="px-1 border-r border-black">Flow</th>
              <th class="px-1 border-r border-black">Status</th>
              <th class="px-1 border-r border-black">Created</th>
              <th class="px-1">Last Updated</th>
            </tr>
          </thead>
          <tbody class="w-full overflow-y-auto">
            <tr v-if="sortedRequests(group.name).length === 0">
              <td class="text-center text-gray-700" colspan="6">no requests</td>
            </tr>
            <tr v-for="(request, idx) in sortedRequests(group.name)" :key="request.id" :title="request.name" :class="['py-1 h-16 w-full hover:bg-theme-200 max-w-full overflow-hidden', {'bg-theme-100': idx % 2 === 0}]">
              <td class="break-all px-1 border-r border-black">
                <router-link :to="{name: 'FlowRequest', params: {groupName: group.name, flowId: request.flowId, requestId: request.id}}" class="block">{{ request.name }}</router-link>
              </td>
              <td class="break-all px-1 border-r border-black">
                <router-link :to="{name: 'FlowRequest', params: {groupName: group.name, flowId: request.flowId, requestId: request.id}}" class="block">{{ findFlow(group.name, request.flowId).name }}</router-link>
              </td>
              <td class="break-all px-1 border-r border-black">
                <router-link :to="{name: 'FlowRequest', params: {groupName: group.name, flowId: request.flowId, requestId: request.id}}" class="block">{{ statusForRequest(request).displayName }}</router-link>
              </td>
              <td class="break-all px-1 border-r border-black">
                <router-link :to="{name: 'FlowRequest', params: {groupName: group.name, flowId: request.flowId, requestId: request.id}}" class="block"><relative-time-display :time-ms="request.createdDateMs"></relative-time-display></router-link>
              </td>
              <td class="break-all px-1">
                <router-link :to="{name: 'FlowRequest', params: {groupName: group.name, flowId: request.flowId, requestId: request.id}}" class="block"><relative-time-display :time-ms="request.lastUpdatedMs"></relative-time-display></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="groups.length === 0" class="w-full text-center mt-4 text-gray-500">no flows</div>

      <modal v-if="newRequest !== null" large-width-class="lg:w-4/5">
        <template v-slot:header>
          <h1 class="text-2xl">New Request for {{ newRequest.group.displayName }}</h1>
        </template>
        <template v-slot:body>
          <loading v-if="newRequest.isSaving" text="Saving Request..."></loading>
          <template v-else>
            <div class="flex flex-col w-full">
              <strong>Flow</strong>
              <v-select :options="flows[newRequest.group.name]" v-model="newRequest.flow" :get-option-label="flow => flow.name" placeholder="select the flow the request is for" :append-to-body="true"></v-select>
            </div>
            <div class="flex flex-col w-full">
              <strong>Request Name</strong>
              <input class="form-control" v-model="newRequest.name" />
            </div>
            <div class="flex flex-col w-full">
              <strong>Content</strong>
              <ni-flow-request-file-upload v-model="newRequest.content"></ni-flow-request-file-upload>
            </div>
          </template>
        </template>
        <template v-slot:footer>
          <template v-if="!newRequest.isSaving">
            <button class="btn btn-theme-muted" @click="newRequest = null"><i class="fas fa-ban mr-1" />Cancel</button>
            <button class="btn btn-theme" @click="createRequest" :disabled="!isNewRequestValid"><i class="fas fa-save mr-1" />Save</button>
          </template>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import NiFlowRequestFileUpload from '@/components/niflow/NiFlowRequestFileUpload'
import RelativeTimeDisplay from '@/components/RelativeTimeDisplay'
import { FlowRequestStatus } from '@/utils/FlowRequestStatus.js'

export default {
  name: 'ni-flow-requests',
  components: {
    Loading,
    Modal,
    NiFlowRequestFileUpload,
    RelativeTimeDisplay
  },
  data () {
    return {
      requestFilter: null,
      requestFilters: [
        {
          id: 'open',
          label: 'Open'
        },
        {
          id: 'closed',
          label: 'Closed'
        }
      ],
      newRequest: null
    }
  },
  computed: {
    isRootRequests () {
      return this.$route.name === 'NiFlowRequests'
    },
    groups () {
      return _.sortBy(this.$store.state.niFlow.groups, ['displayName'])
    },
    flows () {
      return this.$store.state.niFlow.flows
    },
    requests () {
      return this.$store.state.niFlow.requests
    },
    isNewRequestValid () {
      if (this.newRequest === null) return false
      if (this.newRequest.flow === null) return false
      if (this.newRequest.name === null || this.newRequest.name.length === 0) return false
      if (this.newRequest.content === null || this.newRequest.content.length === 0) return false
      return true
    }
  },
  methods: {
    sortedRequests (groupName) {
      let groupRequests = this.requests[groupName]
      if (this.requestFilter !== null) {
        if (this.requestFilter.id === 'open') {
          groupRequests = groupRequests.filter(request => !this.statusForRequest(request).isFinal)
        } else if (this.requestFilter.id === 'closed') {
          groupRequests = groupRequests.filter(request => this.statusForRequest(request).isFinal)
        } else {
          groupRequests = groupRequests.filter(request => this.statusForRequest(request).number === this.requestFilter.id)
        }
      }
      return _.sortBy(groupRequests, ['createdDateMs'])
    },
    findFlow (groupName, flowId) {
      return _.find(this.flows[groupName], flow => flow.id === flowId)
    },
    statusForRequest (request) {
      return FlowRequestStatus.fromStatusNumber(request.status)
    },
    openNewRequestModal (group) {
      this.newRequest = {
        group,
        flow: null,
        name: null,
        content: null,
        isSaving: false
      }
    },
    async createRequest () {
      if (!this.isNewRequestValid) return

      try {
        this.newRequest.isSaving = true
        const dispatchData = {
          groupName: this.newRequest.group.name,
          flowId: this.newRequest.flow.id,
          name: this.newRequest.name,
          content: this.newRequest.content
        }
        const response = await this.$store.dispatch('niFlow/createFlowRequest', dispatchData)
        response.data.flowId = dispatchData.flowId
        this.$store.commit('niFlow/concatRequests', { groupName: this.newRequest.group.name, requests: [response.data] })
        this.$router.push({ name: 'FlowRequest', params: { groupName: this.newRequest.group.name, flowId: this.newRequest.flow.id, requestId: response.data.id } })
        this.newRequest = null
      } catch (error) {
        console.error('Error creating request.', error)
        this.$swal({
          icon: 'error',
          title: 'Failed to Create Flow Request',
          text: 'An error occurred creating the flow request. Please try again.'
        })
        this.newRequest.isSaving = false
      }
    }
  },
  created () {
    const self = this
    Object.keys(FlowRequestStatus).forEach(statusKey => {
      const status = FlowRequestStatus[statusKey]
      if (status === FlowRequestStatus.UNKNOWN) return
      self.requestFilters.push({
        id: status.number,
        label: status.displayName
      })
    })
    this.requestFilters = _.sortBy(this.requestFilters, 'label')
    this.requestFilter = this.requestFilters.find(filter => filter.id === 'open')
  }
}
</script>
