<script>
import moment from 'moment'

export default {
  name: 'RelativeTimeMixin',
  data () {
    return {
      timestamp: null,
      momentNow: null,
      updaterToken: null
    }
  },
  computed: {
    relativeTimeStamp () {
      if (this.timestamp !== null) {
        let momentTimestamp = moment(this.timestamp)
        if (momentTimestamp > this.momentNow) {
          // if the timestamp is in the future, use it as our starting point
          //   otherwise we will see "Last Updated: in a few seconds"
          momentTimestamp = this.momentNow
        }
        if (momentTimestamp) {
          return momentTimestamp.from(this.momentNow)
        }
      }
      return null
    },
    readableAbsoluteTime () {
      return moment(this.timestamp).format('MM-DD-YYYY, h:mm:ss a')
    }
  },
  methods: {
    updateRelativeTime () {
      this.momentNow = moment()
    }
  },
  mounted () {
    this.updateRelativeTime()
    this.updaterToken = setInterval(this.updateRelativeTime, 10000)
  },
  beforeUnmount () {
    clearInterval(this.updaterToken)
  }
}
</script>
