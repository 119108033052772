<template>
  <div class="flex items-center w-full">
    <VMenu v-if="!hasAccess" :delay="{ show: 200, hide: 0 }">
      <i class="fas fa-warning text-amber-500 mr-1" />
      <template #popper>
        <div class="p-2 max-w-48">Your account no longer has access to this {{ type }}. This API Key can no longer be used for the {{ type }}, {{ resourceName }}.</div>
      </template>
    </VMenu> {{ resourceName }}
  </div>
</template>

<script>
export default {
  name: 'api-key-resource',
  props: {
    type: { type: String, required: true },
    resourceName: { type: String, required: true },
    hasAccess: { type: Boolean, required: true }
  }
}
</script>
