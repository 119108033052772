<template>
  <div class="max-w-4/5 max-h-4/5 rounded">
    <vue-scrolling-table v-if="parsedCsv !== null">
      <template v-slot:thead>
        <tr>
          <th v-for="header in headerRow" :key="header" class="text-white pl-1 text-left" :title="header">
            {{ header }}
          </th>
        </tr>
      </template>
      <template v-slot:tbody v-if="contentRows.length > 0">
        <tr v-for="(contentRow, resultIdx) in contentRows" :key="contentRow" :class="['text-black', { 'bg-white': resultIdx % 2 === 0, 'bg-theme-100': resultIdx % 2 !== 0 }]">
          <td v-for="value in contentRow" :key="value" class="px-1">
            <span>{{ value }}</span>
          </td>
        </tr>
      </template>
      <template v-slot:tbody v-else>
        <div>no data</div>
      </template>
    </vue-scrolling-table>
  </div>
</template>

<script>
import '@/../node_modules/vue-scrolling-table/dist/style.css'
import Papa from 'papaparse'
import VueScrollingTable from 'vue-scrolling-table'

export default {
  name: 'csv-renderer',
  components: {
    VueScrollingTable
  },
  props: {
    fileContent: { type: String, required: true }
  },
  emits: ['error-loading', 'finished-loading'],
  data () {
    return {
      parsedCsv: null
    }
  },
  computed: {
    headerRow () {
      if (this.parsedCsv === null || this.parsedCsv.length === 0) return []
      return this.parsedCsv[0]
    },
    contentRows () {
      if (this.parsedCsv === null || this.parsedCsv.length < 2) return []
      const lastRow = this.parsedCsv[this.parsedCsv.length - 1]

      let end = this.parsedCsv.length
      if (lastRow.length === 1 && lastRow[0] === '') {
        end -= 1
      }
      return this.parsedCsv.slice(1, end)
    }
  },
  mounted () {
    const papaParseOutput = Papa.parse(atob(this.fileContent), { })
    if (papaParseOutput.errors.length > 0) {
      this.$emit('error-loading')
    } else {
      this.parsedCsv = papaParseOutput.data
      this.$emit('finished-loading')
    }
  }
}
</script>
