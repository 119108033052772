<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'search-query-mixin',
  props: {
    searchId: { type: String, required: true }
  },
  data () {
    return {
      maxResults: 25
    }
  },
  computed: {
    search () {
      const result = _.find(this.$store.state.datasetSearches, ['id', this.searchId])
      if (typeof result === 'undefined') return null
      return result
    },
    searchPayload () {
      if (this.search.isSample) return null

      let paging = null
      if (this.search.datasetDescription.features.includes('PAGINATION')) {
        paging = {
          offset: this.search.currentOffset,
          max: this.maxResults
        }
      }

      let timeRange = null
      if (this.search.filters.timeRange !== null) {
        timeRange = {
          start: this.search.filters.timeRange.start,
          end: this.search.filters.timeRange.end
        }
      }

      let geospatial = null
      if (this.search.filters.geospatial !== null) {
        const searchPolygons = this.search.filters.geospatial.polygons.map(polygon => {
          const points = polygon.points.map(point => {
            return `${point.lng} ${point.lat}`
          })
          return `(${points.join(', ')})`
        })
        geospatial = {
          polygons: searchPolygons.join(', '),
          antimeridianPolygons: false
        }
      }

      return {
        paging,
        timeRange,
        geospatial,
        filters: this.search.filters.dynamic.filter(filter => !filter.preFilled).map(filter => {
          return {
            name: filter.name,
            value: filter.value,
            operator: filter.operator
          }
        })
      }
    },
    columns () {
      if (this.search === null) return []
      if (this.search.datasetDescription.schema === null) {
        if (this.search.results === null) return []

        // ensure all keys or all the results are represented
        const allKeys = new Set()
        this.search.results.forEach(item => {
          Object.keys(item).filter(key => key !== `x${this.search.id}`).forEach(key => allKeys.add(key))
        })

        return _.sortBy([...allKeys].map(key => {
          return {
            name: key,
            display: this.deSnakeCase(key),
            type: 'STRING',
            combined: null,
            description: null
          }
        }), 'display')
      } else {
        return _.sortBy(this.search.datasetDescription.schema.map(col => {
          return {
            ...col,
            display: this.deSnakeCase(col.name)
          }
        }), ['display'])
      }
    }
  },
  methods: {
    getValue (row, column, truncate = true) {
      let value = null
      if (column.combined === null) {
        value = row[column.name]
      } else {
        value = column.combined.fields.map(field => row[field]).join(column.combined.joinedBy)
      }

      if (typeof value === 'undefined' || value === null || value.toString().trim() === '') {
        return '-'
      }

      if (column.type === 'TIMESTAMP_SECONDS' || column.type === 'TIMESTAMP_MILLS') {
        if (column.type === 'TIMESTAMP_SECONDS') {
          // this field is in seconds, convert to ms
          value = value * 1000
        }
        return moment.utc(value).format('MM/DD/YYYY, hh:mm:ss A ZZ')
      }

      if (typeof value === 'object') {
        value = JSON.stringify(value, null, 4)
      }

      if (truncate && value.length > this.datasetCharacterLimit) {
        return value.substring(0, this.datasetCharacterLimit) + '...'
      }
      return value
    }
  }
}
</script>
