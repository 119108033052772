<template>
  <fullscreen-file-preview v-if="helpFilePreviewing !== null"
    :filename="helpFilePreviewing.name"
    :file-url="helpFilePreviewing.url"
    @close-clicked="helpFilePreviewing = null">
  </fullscreen-file-preview>

  <template v-for="route in routes" :key="route.name">
    <template v-if="!disabledLinks.includes(route.name) && route.shouldShow()">
      <router-link v-if="route.helpFile === null" :to="{name: route.name }" class="p-2 rounded flex items-center w-full hover:no-underline hover:text-white hover:bg-theme-300">
        <i :class="['fas mr-2', route.icon]"></i>
        <span class="text-sm">{{route.display}}</span>
      </router-link>
      <a v-else href="#" @click="setHelpFile(route.helpFile)" class="p-2 rounded flex items-center w-full hover:no-underline hover:text-white hover:bg-theme-300">
        <i :class="['fas mr-2', route.icon]"></i>
        {{route.display}}
      </a>
    </template>
  </template>
</template>

<script>
import UserRolesMixin from '@/mixins/UserRolesMixin'
import FullscreenFilePreview from '@/components/FullscreenFilePreview'

export default {
  name: 'docs-child-menu',
  mixins: [UserRolesMixin],
  components: {
    FullscreenFilePreview
  },
  data () {
    return {
      helpFilePreviewing: null,
      routes: [
        {
          name: 'TutorialVideos',
          icon: 'fa-video',
          display: 'Tutorial Videos',
          helpFile: null,
          shouldShow: () => { return this.hasTutorialVideos }
        },
        {
          name: 'FAQ',
          icon: 'fa-question',
          display: 'FAQ',
          helpFile: null,
          shouldShow: () => { return true }
        }
      ]
    }
  },
  computed: {
    externalDocsPages () {
      return this.$store.state.externalPages.filter(page => page.navBar === 'docs')
    },
    disabledLinks () {
      return this.$store.state.disabledLinks
    },
    hasTutorialVideos () {
      if (this.$store.state.presignableDocs === null) return false
      return this.$store.state.presignableDocs.filter(doc => {
        return doc.category === 'video/mp4'
      }).length > 0
    },
    supplementalHelpPdfDocs () {
      return this.$store.state.supplementalHelpPdfDocs
    }
  },
  methods: {
    setHelpFile (name) {
      this.helpFilePreviewing = {
        name,
        url: `/filesets/unacorn-help/download?file=${name}`
      }
    }
  },
  mounted () {
    const helpDocs = JSON.parse(JSON.stringify(this.supplementalHelpPdfDocs))
    for (const doc of helpDocs) {
      doc.icon = 'fa-file-pdf'
      doc.shouldShow = () => { return true }
    }

    this.routes = this.routes.concat(helpDocs)

    const externalDocPages = this.externalDocsPages.map(externalPage => {
      return {
        name: externalPage.name,
        icon: externalPage.faIcon,
        display: externalPage.displayName,
        helpFile: null,
        shouldShow: () => { return this.checkRole(externalPage.role) }
      }
    })
    this.routes = externalDocPages.concat(this.routes)
  }
}
</script>
