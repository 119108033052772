<template>
  <div class="flex flex-col items-center gap-4">
    <div class="flex text-2xl font-extrabold items-center justify-center gap-2 sticky top-0 bg-theme-500 text-white p-2 w-full">
      <i class="fas fa-lock"/>
      <div>Update Password</div>
    </div>

    <div class="flex flex-col items-center w-full">
      <update-password></update-password>
      <small v-if="isCacEnabled">Note: If you have a CAC linked to your account, it will become unlinked after updating your password.</small>
    </div>
  </div>
</template>

<script>
import CacMixin from '@/mixins/CacMixin'
import UpdatePassword from '@/components/UpdatePassword'

export default {
  name: 'change-password',
  mixins: [
    CacMixin
  ],
  components: {
    UpdatePassword
  }
}
</script>
