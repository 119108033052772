<template>
  <div>
    <loading v-if="iterations === null || typeof(flow) === 'undefined'" text="Loading Flow..."></loading>
    <div v-else-if="iterations.length === 0" class="flex flex-col w-full text-2xl h-screen items-center justify-center">
      <div>The <strong>{{ flow.name }}</strong> flow has no accepted content yet.</div>
      <div class="text-lg">Create a new <router-link :to="{name: 'NiFlowRequests'}" class="text-theme-300">Flow Request</router-link> for this flow and the accepted requests will show up here.</div>
    </div>
    <iterations-display v-else
      class="h-screen"
      :title="flow.name"
      :subtitle="subtitle"
      :iterations="iterations"
    >
    </iterations-display>
  </div>
</template>

<script>
import _ from 'lodash'
import IterationsDisplay from '@/components/niflow/IterationsDisplay'
import Loading from '@/components/Loading'
import RelativeTimeMixin from '@/mixins/RelativeTimeMixin'

export default {
  name: 'main-flow-history',
  components: {
    IterationsDisplay,
    Loading
  },
  mixins: [
    RelativeTimeMixin
  ],
  props: {
    groupName: { type: String, required: true },
    flowId: { type: Number, required: true }
  },
  data () {
    return {
      iterations: null
    }
  },
  computed: {
    flow () {
      return _.find(this.$store.state.niFlow.flows[this.groupName], flow => flow.id === this.flowId)
    },
    subtitle () {
      if (this.iterations === null || this.iterations.length === 0) return ''
      return `Last Updated ${this.relativeTimeStamp}`
    }
  },
  watch: {
    flowId () {
      this.getIterations()
    }
  },
  methods: {
    async getIterations () {
      this.iterations = null
      try {
        const response = await this.$store.dispatch('niFlow/fetchFlowMainIterations', { groupName: this.groupName, flowId: this.flowId })

        // override the iteration date for since that is when the iteration was created. We want to show when the iteration was made the main content
        response.data.forEach(mainIteration => {
          mainIteration.iteration.createdDateMs = mainIteration.timeMs
        })
        let iterations = response.data.map(mainIteration => mainIteration.iteration)
        iterations = _.sortBy(iterations, 'createdDateMs')
        iterations.forEach((iteration, idx) => {
          iteration.label = `Iteration ${idx + 1}`
        })
        this.iterations = iterations

        if (this.iterations.length > 0) {
          this.timestamp = this.iterations.at(-1).createdDateMs
        }
      } catch (error) {
        console.error('Error getting flow main iterations.', error)
        this.$swal({
          icon: 'error',
          title: 'Failed to Load Flow',
          text: 'An error occurred getting the Flow\'s content. Please try again.'
        })
      }
    }
  },
  mounted () {
    this.getIterations()
  }
}
</script>
