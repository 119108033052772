<template>
  <template v-for="route in routes" :key="route.name">
    <router-link v-if="!disabledLinks.includes(route.name) && route.isEnabled()" :to="{name: route.name }" class="p-2 rounded flex items-center w-full hover:no-underline hover:text-white hover:bg-theme-300">
      <i :class="['fas mr-2', route.icon]"></i>
      <span class="text-sm">{{route.display}}</span>
    </router-link>
  </template>
</template>

<script>
import CacMixin from '@/mixins/CacMixin'

export default {
  name: 'user-profile-child-menu',
  mixins: [
    CacMixin
  ],
  data () {
    return {
      routes: [
        {
          name: 'UserProfile',
          icon: 'fa-user',
          display: 'About',
          isEnabled: () => { return true }
        },
        {
          name: 'ApiKeys',
          icon: 'fa-key',
          display: 'API Keys',
          isEnabled: () => { return true }
        },
        {
          name: 'ManageCac',
          icon: 'fa-id-card',
          display: 'Manage CAC',
          isEnabled: () => { return this.isCacEnabled }
        },
        {
          name: 'Notices',
          icon: 'fa-bell',
          display: 'Notices',
          isEnabled: () => { return true }
        },
        {
          name: 'ChangePassword',
          icon: 'fa-lock',
          display: 'Update Password',
          isEnabled: () => { return true }
        }
      ]
    }
  },
  computed: {
    disabledLinks () {
      return this.$store.state.disabledLinks
    }
  }
}
</script>
