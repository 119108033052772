<template>
  <fileset-browser class="h-full"
    locked-fileset-id="jupyter_notebooks"
    :locked-fileset-path="null"
    :is-height-constrained="false"
    :is-using-browser-navigation="true"
    :hide-nav-bars="true"></fileset-browser>
</template>

<script>
import FilesetBrowser from '@/components/FilesetBrowser'

export default {
  name: 'jupyter-notebooks',
  components: {
    FilesetBrowser
  }
}
</script>
