<template>
  <div class="flex flex-col items-center">
    <div class="flex text-2xl font-extrabold items-center justify-center gap-2 sticky top-0 bg-theme-500 text-white p-2 w-full mb-10">
      <i class="fas fa-id-card"/>
      <div>Manage CAC</div>
    </div>

    <retryable-error v-if="error !== null" :text="error.text" @retry="error.retry"></retryable-error>
    <template v-else-if="cacStatus === null">
      <loading text="Loading CAC status..."></loading>
    </template>
    <template v-else-if="cacStatus.linked">
      <div class="flex gap-1 flex-col text-green-700 text-xl font-bold items-center"><i class="fas fa-circle-check text-5xl" />CAC Linked</div>
      <div class="text-base font-normal text-black">Your CAC has been linked to your account since {{ readableDate(cacStatus.linkedTimestamp) }}.</div>
      <button class="w-full btn btn-theme max-w-3/5" @click="unlinkCac"><i class="fas fa-link-slash mr-1" />Unlink CAC</button>
      <small>Note: Updating your password will unlink your CAC card.</small>
    </template>
    <template v-else>
      <div class="flex gap-1 flex-col text-red-700 text-xl font-bold items-center"><i class="fas fa-circle-xmark text-5xl" />CAC Not Linked</div>
      <div class="text-base font-normal text-black">If you wish to log in to {{ branding }} with your CAC card, you must first Link your CAC to your account here.</div>
      <div class="text-base font-normal text-black">Please insert your CAC and then click on the "Link CAC" button below.</div>
      <loading v-if="cacCode || readingCac" text="Linking CAC..."></loading>
      <template v-else>
        <a class=" w-full btn btn-theme max-w-3/5" :href="geoAxisCodeEndpoint" @click="readingCac = true"><i class="fas fa-link mr-1" />Link CAC</a>
      </template>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import CacMixin from '@/mixins/CacMixin'
import Loading from '@/components/Loading'
import RetryableError from '@/components/RetryableError'

export default {
  name: 'manage-cac',
  mixins: [
    CacMixin
  ],
  components: {
    Loading,
    RetryableError
  },
  data () {
    return {
      cacStatus: null,
      error: null,
      readingCac: false
    }
  },
  computed: {
    branding () {
      return this.$store.state.branding
    }
  },
  watch: {
    cacCode () {
      this.attemptCacLink()
    }
  },
  methods: {
    readableDate (timestamp) {
      return moment(timestamp).format('MM-DD-YYYY, h:mm:ss a')
    },
    async unlinkCac () {
      if (this.cacStatus === null || !this.cacStatus.linked) return
      try {
        this.cacStatus = null
        const response = await this.$store.dispatch('unlinkCac')
        this.cacStatus = response.data
      } catch (error) {
        console.error('Error unlinking CAC', error)
        this.$swal({
          icon: 'error',
          title: 'Unable to Unlink CAC',
          text: 'Unable to unlink your CAC to your account. Please try again.'
        })
        this.fetchCacStatus()
      }
    },
    async attemptCacLink () {
      if (typeof this.cacCode !== 'undefined' && this.cacCode !== null) {
        const self = this
        this.$swal.fire({
          title: 'Complete CAC Link',
          text: 'To complete linking your CAC to your account, please enter your password.',
          input: 'password',
          inputAttributes: {
            placeholder: 'password'
          },
          showCancelButton: true,
          confirmButtonText: 'Confirm Link',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          preConfirm: async (password) => {
            try {
              const payload = { password, code: self.cacCode }
              const response = await self.$store.dispatch('linkCac', payload)
              self.cacStatus = response.data

              self.$swal.fire({
                icon: 'success',
                title: 'CAC Linked',
                text: 'Your CAC is now linked with your account.'
              })
            } catch (error) {
              console.error('Error linking CAC', error)
              self.$swal({
                icon: 'error',
                title: 'Unable to Link CAC',
                text: 'Unable to link your CAC to your account.'
              })
            } finally {
              self.$router.replace({ name: 'ManageCac' })
            }
          }
        }).then((result) => {
          if (!result.isConfirmed) {
            // user cancelled the password
            self.$router.replace({ name: 'ManageCac' })
          }
        })
      }
    },
    async fetchCacStatus () {
      try {
        this.cacStatus = null
        this.error = null
        const response = await this.$store.dispatch('cacStatus')
        this.cacStatus = response.data
      } catch (error) {
        console.error('Error getting cac status')
        this.error = {
          text: 'Error loading CAC status.',
          retry: this.fetchCacStatus
        }
      }
    }
  },
  mounted () {
    this.fetchCacStatus()
    this.attemptCacLink()
  }
}
</script>
