<template>
  <retryable-error v-if="hasLoadingError" text="Error loading datasets." @retry="fetchDatasets"></retryable-error>
  <loading v-else-if="unifiedDatasets === null" text="Loading datasets..."></loading>
  <v-select v-else ref="datasetSelector" :options="unifiedDatasets" v-model="selectedDataset" @update:modelValue="filterCategories" :multiple="allowMultiple" :selectable="item => allowMultiple ? true : item.isHeader === false" placeholder="type or select a dataset" :filter="filterDatasets" :append-to-body="true">
    <template #option="item">
      <h5 v-if="item.isHeader" :class="['font-bold -ml-2 w-full', { 'text-black': !allowMultiple }]" @click="onSelectHeader(item)">{{ item.label }}</h5>
      <div v-else class="flex gap-2 items-center">
        <i :class="['cursor-pointer fa-star', {'fa-regular': !isDatasetFavorited(item.model)}, {'fas text-yellow-500': isDatasetFavorited(item.model)}]" @click.stop.prevent="toggleDatasetFavorite(item.model)"/>
        {{ item.label }}
        <i v-if="item.icon" :class="`fas ${item.icon} w-full text-right`" />
      </div>
    </template>
  </v-select>
</template>

<script>
import _ from 'lodash'
import RetryableError from '@/components/RetryableError'
import Loading from '@/components/Loading'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'unified-dataset-selector',
  emits: ['update:modelValue', 'datasets-loaded'],
  props: {
    modelValue: { type: Object, required: false, default: null },
    allowMultiple: { type: Boolean, required: false, default: false }
  },
  components: {
    RetryableError,
    Loading
  },
  data () {
    return {
      selectedDataset: this.modelValue,
      isLoading: false,
      hasLoadingError: false
    }
  },
  watch: {
    modelValue (newValue, oldValue) {
      if (oldValue === null && newValue !== null) {
        this.selectedDataset = newValue
      }
    },
    selectedDataset () {
      this.$emit('update:modelValue', this.selectedDataset)
    }
  },
  computed: {
    favoriteDatasets () {
      return this.$store.state.favoriteDatasets
    },
    unifiedDatasets () {
      if (this.$store.state.unifiedDatasetCategories === null) return null

      const favorites = []
      if (this.favoriteDatasets.length > 0) {
        favorites.push({
          id: uuidv4(),
          isHeader: true,
          filterText: `favorites ${this.favoriteDatasets.map(fd => `${fd.datasetCategory} ${fd.datasetId}`).join(' ')}`,
          label: 'Favorites'
        })

        for (const fd of this.favoriteDatasets) {
          const model = this.getDatasetModel(fd.datasetCategory, fd.datasetId)
          if (model === null) continue
          favorites.push({
            id: uuidv4(),
            isHeader: false,
            filterText: `favorites ${fd.datasetCategory} ${fd.datasetId}`,
            label: fd.datasetId,
            icon: model.dataset.supportsMapResults ? 'fa-globe' : null,
            model: {
              category: model.category,
              dataset: model.dataset
            }
          })
        }
      }

      return favorites.concat(this.$store.state.unifiedDatasetCategories)
    }
  },
  methods: {
    filterCategories () {
      if (this.allowMultiple) {
        this.selectedDataset = this.selectedDataset.filter(item => !item.isHeader)
      }
    },
    onSelectHeader (header) {
      if (this.allowMultiple === false) return

      // add everything under the header the selected
      let foundHeader = false
      for (const dataset of this.unifiedDatasets) {
        if (foundHeader) {
          // we hit the next header, we're done
          if (dataset.isHeader) break

          // if this dataset isn't seleected already, select it
          if (this.selectedDataset.filter(item => item.model.dataset.id === dataset.model.dataset.id && item.model.category.id === dataset.model.category.id).length === 0) {
            this.selectedDataset.push(dataset)
          }
        }

        if (header.id === dataset.id) {
          foundHeader = true
        }
      }

      this.$refs.datasetSelector.closeSearchOptions()
    },
    isDatasetFavorited (model) {
      return this.favoriteDatasets.filter(fd => fd.datasetCategory === model.category.id && fd.datasetId === model.dataset.id).length > 0
    },
    async toggleDatasetFavorite (model) {
      try {
        const favorite = { datasetCategory: model.category.id, datasetId: model.dataset.id }
        if (this.isDatasetFavorited(model)) {
          // immediately remove from the list before the network call
          this.$store.commit('setFavoriteDatasets', this.favoriteDatasets.filter(fd => fd.datasetCategory !== model.category.id || fd.datasetId !== model.dataset.id))
          await this.$store.dispatch('removeFavoriteDataset', favorite)
        } else {
          // immediately add it as a favorite before the network call
          this.$store.commit('setFavoriteDatasets', this.favoriteDatasets.concat([favorite]))
          await this.$store.dispatch('favoriteDataset', favorite)
        }
      } catch (error) {
        console.error('Error toggling a favorite dataset', error)
      } finally {
        this.fetchFavoriteDatasets()
      }
    },
    getDatasetModel (categoryId, datasetId) {
      if (this.$store.state.unifiedDatasetCategories === null) return null
      const matches = this.$store.state.unifiedDatasetCategories.filter(cat => cat.model && cat.model.category.id === categoryId && cat.model.dataset.id === datasetId)
      if (matches.length === 0) return null
      return matches[0].model
    },
    filterDatasets (options, search) {
      return options.filter(option => {
        return this.$refs.datasetSelector.filterBy(option, option.filterText, search)
      })
    },
    generateUnifiedDatasets (categories) {
      if (categories === null) return null

      const unifiedDatasets = []
      categories = _.sortBy(categories, [category => category.id.toLowerCase()])
      for (const category of categories) {
        // headers don't need a model; they are no selectable
        unifiedDatasets.push({
          id: uuidv4(),
          isHeader: true,
          filterText: `${category.id} ${category.datasets.map(d => d.id).join(' ')}`,
          label: category.id
        })

        const datasets = _.sortBy(category.datasets, [dataset => dataset.id.toLowerCase()])
        delete category.datasets
        for (const dataset of datasets) {
          unifiedDatasets.push({
            id: uuidv4(),
            isHeader: false,
            filterText: `${category.id} ${dataset.id}`,
            label: dataset.id,
            icon: dataset.supportsMapResults ? 'fa-globe' : null,
            model: {
              category,
              dataset
            }
          })
        }
      }

      return unifiedDatasets
    },
    fetchFavoriteDatasets () {
      const self = this
      this.$store.dispatch('fetchFavoriteDatasets').then(response => {
        self.$store.commit('setFavoriteDatasets', response.data)
      }).catch(error => {
        console.error('Error loading favorite datasets', error)
      })
    },
    async fetchDatasets () {
      this.$store.commit('setUnifiedDatasetCategories', null)
      this.isLoading = true
      this.hasLoadingError = false

      this.fetchFavoriteDatasets()
      try {
        const datasetsResponse = await this.$store.dispatch('fetchUnifiedDatasets')
        this.$store.commit('setUnifiedDatasetCategories', this.generateUnifiedDatasets(datasetsResponse.data))

        await this.$nextTick()
        this.$emit('datasets-loaded', this.unifiedDatasets)
      } catch (error) {
        console.error(error)
        this.hasLoadingError = true
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted () {
    if (this.unifiedDatasets === null) {
      this.fetchDatasets()
    } else {
      this.$emit('datasets-loaded', this.unifiedDatasets)
    }
  }
}
</script>
