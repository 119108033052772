<template>
  <div :class="['w-10 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out cursor-pointer', {'bg-emerald-500': isActive}, {'bg-gray-300': !isActive}]" @click="isActive = !isActive">
    <div :class="['bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ease-in-out', {'translate-x-3': isActive}]"></div>
  </div>
</template>

<script>
export default {
  name: 'toggle-switch',
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: Boolean, required: true }
  },
  data () {
    return {
      isActive: this.modelValue
    }
  },
  watch: {
    isActive () {
      this.$emit('update:modelValue', this.isActive)
    },
    modelValue () {
      this.isActive = this.modelValue
    }
  }
}
</script>
