<template>
  <div id="fullscreen-map-target" class="z-9999 absolute top-0"></div>

  <!-- feedback modal !-->
  <modal v-if="showFeedbackModal">
    <template v-slot:header>
      <h1 class="text-2xl">Submit Feedback</h1>
    </template>
    <template v-slot:body>
      <feedback ref="feedback" @feedback-status="onFeedbackStatusChanged"></feedback>
    </template>
    <template v-slot:footer>
      <template v-if="feedbackStatus === 'success'">
        <button class="btn btn-theme" href="#" @click="closeFeedbackModal">Close</button>
      </template>
      <template v-else-if="feedbackStatus === 'pending' || feedbackStatus === 'error'">
        <button class="btn btn-theme-muted" href="#" @click="closeFeedbackModal">Cancel</button>
        <button class="btn btn-theme" href="#" @click="$refs.feedback.submitFeedback()">Submit</button>
      </template>
      <template v-else>
        <!-- show nothing while submitting -->
        <span></span>
      </template>
    </template>
  </modal>

  <div class="flex">
    <div v-if="currentUser" class="h-screen bg-theme-500 z-50 flex flex-col py-2 text-white fixed w-20 max-w-20 overflow-auto">

      <template v-for="navItem in navItems" :key="navItem.name">
        <router-link v-if="shouldShowTopRoute(navItem)" :to="{ name: getAllowedChildRoutes(navItem)[0] }" :class="['cursor-pointer flex flex-col items-center rounded p-2 m-2 hover:bg-theme-300 hover:text-white hover:no-underline', {'text-white font-bold bg-theme-300': currentRootNavItem == navItem}]">
          <i class="fas" :class="navItem.icon"></i>
          <span class="text-sm">{{navItem.name}}</span>
        </router-link>
      </template>

      <div v-if="enableFeedback" class="cursor-pointer flex flex-col items-center rounded p-2 m-2 mt-auto hover:bg-theme-300 hover:no-underline" @click="showFeedbackModal = true">
        <i class="fas fa-pen-to-square"></i>
        <span class="text-sm text-center">Submit Feedback</span>
      </div>

      <router-link :to="{name: 'UserProfile' }" :class="['cursor-pointer flex flex-col items-center rounded p-2 m-2 hover:bg-theme-300 hover:no-underline hover:text-white', { 'mt-auto': !enableFeedback }, {'text-white font-bold bg-theme-300': currentRootNavItem && currentRootNavItem.name == 'Profile'}]">
        <i class="fas fa-user" />
        <span class="text-sm text-center break-words whitespace-break-spaces">{{currentUser.name}}</span>
      </router-link>
      <button class="btn btn-primary btn-sm m-2 hover:bg-theme-300 hover:text-gray-300 hover:border-gray-300" @click="logoutPressed">Logout</button>
      <div v-if="isSubMenuCollapsed" class="flex flex-col text-white m-2 self-center text-2xl cursor-pointer" title="Expand submenu" @click="isSubMenuCollapsed=false">
        <i class="fas fa-arrow-right p-2 border-2 border-white rounded-xl"></i>
      </div>
    </div>
    <div v-if="currentUser && currentRootNavItem"  :class="['h-screen bg-theme-400 z-50 flex flex-col pt-4 pb-2 fixed ml-20 transition-all ease-in-out duration-1000 overflow-auto', { 'w-0': isSubMenuCollapsed }, { 'w-48 max-w-48': !isSubMenuCollapsed }]">
      <div class="flex flex-col mx-2 gap-4 text-white">
        <component :is="currentRootNavItem.childNavComponent"></component>
      </div>
      <div class="flex flex-col text-white mt-auto self-end p-2 mr-2 text-2xl cursor-pointer" title="Collapse submenu" @click="isSubMenuCollapsed=true">
        <i class="fas fa-arrow-left p-2 border-2 border-white rounded-xl"></i>
      </div>
    </div>

    <div :class="['flex flex-col mx-auto w-full transition-all ease-in-out duration-1000', { 'mt-2': isALogoRoute }, { 'pl-20': currentUser && isSubMenuCollapsed }, { 'pl-68': currentUser && !isSubMenuCollapsed }]">
      <span v-if="isALogoRoute" class="w-full m-auto flex items-center justify-center">
        <img class="w-24 mr-2" src="/resources/logo.png" />
        <div class="text-5xl font-bold text-center mt-3 text-theme-500">{{branding}}</div>
      </span>
      <router-view :class="{ 'mt-2': isALogoRoute }" />
    </div>
  </div>

  <information-notice v-if="notices.length > 0" :notices="notices" @close-notice="closeNoticeModal"></information-notice>
</template>

<script>
import _ from 'lodash'
import Feedback from '@/components/Feedback'
import DocsChildMenu from '@/components/childNavMenus/DocsChildMenu'
import SdkChildMenu from '@/components/childNavMenus/SdkChildMenu'
import SearchChildMenu from '@/components/childNavMenus/SearchChildMenu'
import NiFlowChildMenu from '@/components/childNavMenus/NiFlowChildMenu'
import AdminChildMenu from '@/components/childNavMenus/AdminChildMenu'
import UserProfileChildMenu from '@/components/childNavMenus/UserProfileChildMenu'
import UserRolesMixin from '@/mixins/UserRolesMixin'
import Modal from '@/components/Modal'
import InformationNotice from '@/components/InformationNotice'

export default {
  name: 'App',
  components: {
    Feedback,
    DocsChildMenu,
    SdkChildMenu,
    SearchChildMenu,
    NiFlowChildMenu,
    AdminChildMenu,
    UserProfileChildMenu,
    Modal,
    InformationNotice
  },
  mixins: [UserRolesMixin],
  data () {
    return {
      isSubMenuCollapsed: false,
      routesWithLogo: [
        'UnifiedDatasets',
        'TutorialVideos',
        'JavaSDK',
        'PythonSDK',
        'InternalDatasetSettings',
        'ExternalDatasetSettings',
        'FilesetSettings',
        'FlowEnvironmentSettings',
        'FlowGroupSettings',
        'ConfigurationSettings',
        'DocSettings',
        'NiFlowFlows',
        'NiFlowRequests',
        'JoltSpecificationSettings',
        'About',
        'UserSettings',
        'Auditing',
        'NoticeSettings'
      ],
      navItems: [
        {
          icon: 'fa-search',
          name: 'Search',
          childNavComponent: 'search-child-menu',
          passesRoleCheck: () => { return true },
          childRoutes: [
            'UnifiedDatasets',
            'Filesets',
            'JupyterNotebooks'
          ]
        },
        {
          icon: 'fa-book',
          name: 'Docs',
          childNavComponent: 'docs-child-menu',
          passesRoleCheck: () => { return true },
          childRoutes: [
            'TutorialVideos',
            'FAQ'
          ]
        },
        {
          icon: 'fa-code',
          name: 'SDKs',
          childNavComponent: 'sdk-child-menu',
          passesRoleCheck: () => { return true },
          childRoutes: [
            'JavaSDK',
            'PythonSDK'
          ]
        },
        {
          icon: 'fa-angles-right',
          name: 'NiFlow',
          childNavComponent: 'ni-flow-child-menu',
          passesRoleCheck: () => { return this.niFlowGroups !== null && this.niFlowGroups.length > 0 },
          childRoutes: [
            'NiFlowFlows',
            'NiFlowRequests'
          ]
        },
        {
          icon: 'fa-gear',
          name: 'Power',
          childNavComponent: 'admin-child-menu',
          passesRoleCheck: () => { return this.userRoles.includes('admin') },
          childRoutes: [
            'About',
            'Auditing',
            'ConfigurationSettings',
            'DocSettings',
            'ExternalDatasetSettings',
            'FilesetSettings',
            'FlowEnvironmentSettings',
            'FlowGroupSettings',
            'NoticeSettings',
            'InternalDatasetSettings',
            'JoltSpecificationSettings',
            'UserSettings'
          ]
        },
        {
          icon: 'fa-user',
          name: 'Profile',
          childNavComponent: 'user-profile-child-menu',
          passesRoleCheck: () => { return false }, // hide this since it is on the button of the main nav
          childRoutes: [
            'UserProfile',
            'ApiKeys',
            'ChangePassword',
            'ManageCac',
            'Notices'
          ]
        }
      ],
      showFeedbackModal: false,
      feedbackStatus: null,
      notices: []
    }
  },
  computed: {
    isALogoRoute () {
      // match this on the exact route
      return this.routesWithLogo.includes(this.$route.name)
    },
    currentRootNavItem () {
      const currentRouteName = this.currentRouteName
      return this.navItems.find(item => {
        return item.childRoutes.includes(currentRouteName)
      })
    },
    currentRouteName () {
      // use the first matched route
      return this.$route.matched[0]?.name ?? ''
    },
    branding () {
      return this.$store.state.branding
    },
    enableFeedback () {
      return this.$store.state.enableFeedback
    },
    disabledLinks () {
      return this.$store.state.disabledLinks
    },
    niFlowGroups () {
      return this.$store.state.niFlow.groups
    }
  },
  watch: {
    branding () {
      this.updateDocumentTitle()
    },
    currentUser (newUser, oldUser) {
      if (newUser === null) return

      // load data if the new user is not null but the old one was OR
      // if the they are different users
      if ((newUser !== null && oldUser === null) ||
          (newUser.preferred_username !== oldUser.preferred_username)) {
        this.fetchBasicConfigurations()
        this.fetchSavedQueries()
        this.fetchNewNotices()
      }
    }
  },
  methods: {
    shouldShowTopRoute (navItem) {
      return !this.disabledLinks.includes(navItem.name) && navItem.passesRoleCheck() && this.getAllowedChildRoutes(navItem).length > 0
    },
    getAllowedChildRoutes (navItem) {
      return navItem.childRoutes.filter(route => !this.disabledLinks.includes(route))
    },
    logoutPressed () {
      const self = this
      this.$swal({
        icon: 'question',
        title: 'Logout?',
        text: 'Are you sure you want to logout? Any open searches will be lost.',
        showCancelButton: true,
        confirmButtonText: 'Logout'
      }).then(result => {
        if (result.isConfirmed) {
          self.$store.dispatch('performLogout')
        }
      })
    },
    fetchBasicConfigurations () {
      const self = this
      this.$store.dispatch('fetchPresignableDocs').then(response => {
        self.$store.commit('setPresignableDocs', response.data.docs)
      }).catch(error => {
        console.error('Error getting doc files', error)
      })

      this.$store.dispatch('niFlow/loadFlowDetails').catch(error => {
        console.error('Error fetching flow group data', error)
      })
    },
    fetchSavedQueries () {
      const self = this
      this.$store.dispatch('fetchSavedQueries').then(response => {
        self.$store.commit('setSavedSearches', response.data)
      }).catch(error => {
        console.error('Error loading saved searches', error)
      })
    },
    fetchNewNotices () {
      const self = this
      this.$store.dispatch('fetchNewNotices').then(response => {
        self.notices = response.data
      }).catch(error => {
        console.error('Error loading saved searches', error)
      })
    },
    closeNoticeModal () {
      const noticeIds = this.notices.map(notice => notice.id)
      this.$store.dispatch('markNoticesViewed', noticeIds).catch(error => {
        console.error('Error marking notices as viewed', error)
      })
      this.notices = []
    },
    updateDocumentTitle () {
      if (this.branding !== null) {
        document.title = this.branding
      }
    },
    closeFeedbackModal () {
      this.showFeedbackModal = false
      this.feedbackStatus = null
    },
    onFeedbackStatusChanged (status) {
      this.feedbackStatus = status
    }
  },
  mounted () {
    const self = this
    this.$store.dispatch('fetchUIConfiguration').then(response => {
      const settings = response.data
      self.$store.commit('setBranding', settings.appTitle)
      self.$store.commit('setEnableFeedback', settings.feedbackEnabled)
    }).catch(error => {
      console.error('Error getting ui configuration', error)
    })

    if (this.currentUser !== null) {
      this.fetchBasicConfigurations()
      this.fetchSavedQueries()
      this.fetchNewNotices()
    }

    // add dynamic child routes to search and docs
    const searchNavItem = _.find(this.navItems, ['name', 'Search'])
    searchNavItem.childRoutes = searchNavItem.childRoutes.concat(this.$store.state.externalPages.filter(page => page.navBar === 'search').map(page => page.name))

    const docsNavItem = _.find(this.navItems, ['name', 'Docs'])
    docsNavItem.childRoutes = this.$store.state.externalPages.filter(page => page.navBar === 'docs').map(page => page.name).concat(docsNavItem.childRoutes)
  }
}
</script>

<style lang="less">
body.modal-open {
  @apply overflow-hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.input::placeholder {
  color: gray;
}

ul.vs__dropdown-menu {
  @apply z-9999;
}

a {
  &.router-link-active {
    @apply bg-theme-300;
    @apply text-white;
    @apply font-bold;
  }
}

.input-group {
  @apply flex;
  @apply flex-wrap;
  @apply w-full;
  @apply items-stretch;
  @apply relative;
  @apply justify-center;
}

.input-group-text {
  @apply items-center;
  @apply px-2 py-1 mb-0;
  @apply whitespace-nowrap;
  @apply text-gray-600;
  @apply bg-gray-200;
  @apply border-l border-t border-b border-gray-300 rounded-l;
  @apply text-center;
}

.form-check {
  @apply relative pl-5;
  .form-check-label {
    @apply mb-0;
  }
  .form-check-input {
    @apply absolute mt-1 -ml-5 p-0 box-border;
    accent-color: #4f92c9;
  }
}

.btn {
  @apply cursor-pointer;
    @apply hover:no-underline;
    @apply inline-block;
    @apply text-center;
    @apply border;
    @apply rounded;
    @apply px-2 py-1;

  &.btn-sm {
    @apply text-sm;
  }
  &.btn-theme {
    background-color: #306b9c;
    color: white;
    border-color: gray;
    &:hover:not([disabled]), &:focus {
      background-color: #4f92c9;
      color: white;
    }
  }

  &.btn-destructive {
    @apply bg-red-500;
    color: white;
    border-color: gray;
    &:hover:not([disabled]), &:focus {
      @apply bg-red-300;
      color: white;
    }
  }

  &.btn-theme-muted {
    background-color: white;
    color: #12283b;;
    border-color: #12283b;
    &:hover:not([disabled]), &:focus {
      @apply bg-theme-200;
      color: #12283b;
    }
  }

  &:disabled {
    @apply opacity-70;
    color: black;
    cursor: not-allowed;
  }
}

table.scrolling {
  th {
    @apply bg-theme-400;
  }
  td {
    background-color: inherit;
  }
  tr {
    line-height: 1.3em;
    min-height: 2.6em;
    height: 2.6em;
  }
}

.render-table {
  table {
    table-layout: auto !important;
    width: 100% !important;
  }
}

.render-list {
  ol, ul {
    margin: 0;
    padding-left: 40px;
    display: block;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
}

.swal2-container {
  z-index: 999999 !important;
  button.swal2-confirm {
    @apply bg-theme-400;
    box-shadow: none;
    &:focus {
      box-shadow: none;
    }
  }

}
</style>
