<template>
  <div class="w-full flex flex-col text-white text-xs">
    <div class="p-2 flex justify-between items-center bg-theme-500 gap-12">
      <relative-time-display :time-ms="value.timestamp"></relative-time-display>
      <div class="font-bold text-sm">{{ generateTitle(value) }}</div>
      <div>{{ generateSubtitle(value) }}</div>
    </div>
    <div v-if="!value.isSample" class="bg-theme-700 flex flex-col items-center justify-center">
      <div :class="['flex w-full items-center justify-center gap-2 p-1', { 'border-b border-b-gray-300': filtersUsed.length > 0 }]">
        <i class="fas fa-filter" />{{ filtersUsed.length }} Filter{{ filtersUsed.length === 1 ? '' : 's' }} Applied
      </div>
      <div v-for="filter in filtersUsed" :key="filter.name" class="flex justify-between items-center py-1 border-b border-b-gray-300 w-full last:border-b-0">
        <div class="flex items-center gap-2 mx-2">
          <i :class="`fas ${filter.icon}`" />
          <div class="flex flex-col">
            <div class="font-bold">{{ filter.name }}</div>
            <div><span v-if="filter.operator" class="mr-1">{{ filter.operator }} </span>{{ filter.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RelativeTimeDisplay from '@/components/RelativeTimeDisplay'
import SearchDataMixin from '@/mixins/SearchDataMixin'

export default {
  name: 'search-preview',
  mixins: [
    SearchDataMixin
  ],
  components: {
    RelativeTimeDisplay
  },
  props: {
    value: { type: Object, required: true }
  },
  computed: {
    filtersUsed () {
      return this.generateFiltersUsed(this.value)
    }
  }
}
</script>
