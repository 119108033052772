<template>
  <loading v-if="htmlContent === null"  text="Loading..." class="text-white"></loading>
  <div v-else v-html="htmlContent"></div>
</template>

<script>
import { createApp } from 'vue'
import Loading from '@/components/Loading'
import diffWorker from '@/utils/diff-worker'
import _ from 'lodash'

export default {
  name: 'background-diff-renderer',
  emits: ['rendering', 'rendered'],
  components: {
    Loading
  },
  props: {
    properties: { type: Object, required: false, default: () => { } }
  },
  data () {
    return {
      htmlContent: null
    }
  },
  watch: {
    htmlContent () {
      if (this.htmlContent !== null) this.$emit('rendered')
    },
    component () {
      this.renderComponent()
    },
    properties () {
      this.renderComponent()
    }
  },
  methods: {
    renderComponent: _.debounce(async function () {
      this.$emit('rendering')
      this.htmlContent = null
      if (typeof (Worker) !== 'undefined') {
        const self = this
        diffWorker.worker.onmessage = event => {
          self.htmlContent = event.data
        }
        diffWorker.send(this.properties)
      } else {
        this.htmlContent = await this._render()
      }
    }, 500),
    async _render () {
      const content = document.createElement('div')
      const app = createApp(this.$.root.appContext.components.Diff, this.properties).mount(content)
      await app.$nextTick()
      return content.innerHTML
    }
  },
  mounted () {
    this.renderComponent()
  }
}
</script>
