<template>
  <div class="w-full block text-theme-500">
    <loading v-if="isLoading" text="Loading metadata..."></loading>
    <retryable-error v-else-if="hasError" text="Failed to load app metadata" @retry="fetchMetadata"></retryable-error>
    <div v-else class="flex flex-col gap-4 mb-4">
      <div v-for="component in components" :key="component" class="flex flex-col items-start mx-2">
        <div class="font-bold text-xl">{{ component }}</div>
        <div class="bg-theme-100 rounded flex flex-col w-full gap-1 border border-theme-400">
          <div v-for="property in propertyKeys(component)" :key="property" class="border-b border-b-theme-400 last:border-0">
            <div class="flex justify-between gap-16 px-2">
              <div class="font-bold">{{ property }}</div>
              <div class="break-all">{{ metadata[component][property] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import RetryableError from '@/components/RetryableError'
import _ from 'lodash'

export default {
  name: 'about',
  components: {
    Loading,
    RetryableError
  },
  data () {
    return {
      isLoading: false,
      hasError: false,
      metadata: null
    }
  },
  computed: {
    appVersion () {
      return this.$store.state.appVersion
    },
    components () {
      if (this.metadata === null) return []
      return _.sortBy(Object.keys(this.metadata))
    }
  },
  methods: {
    propertyKeys (component) {
      return _.sortBy(Object.keys(this.metadata[component]))
    },
    async fetchMetadata () {
      try {
        this.isLoading = true
        this.hasError = false
        const response = await this.$store.dispatch('fetchAdminMetadata')
        this.metadata = response.data
        this.metadata.UI = {
          Version: this.appVersion
        }
      } catch (error) {
        this.hasError = true
        console.error('Ettor fetching metadata', error)
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted () {
    this.fetchMetadata()
  }
}
</script>
