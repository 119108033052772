<template>
  <div class="mt-4 flex flex-col gap-2 max-w-3/5 w-3/5">
    <div class="flex flex-col w-full">
      <div class="text-lg font-bold">Password</div>
      <div class="input-group">
        <div class="input-group-text w-42px"><i class="fas fa-lock" /></div>
        <input class="form-control" :type="showPassword ? 'text' : 'password'" v-model="password" />
        <i :class="['absolute cursor-pointer self-center text-theme-500 hover:text-theme-300 right-2 fas', {'fa-eye': !showPassword}, {'fa-eye-slash': showPassword}]" @click="showPassword = !showPassword" />
      </div>
    </div>
    <div class="flex flex-col w-full">
      <div class="text-lg font-bold">Confirm Password</div>
      <div class="input-group">
        <div class="input-group-text w-42px"><i class="fas fa-lock" /></div>
        <input class="form-control" :type="showConfirmPassword ? 'text' : 'password'" v-model="confirmPassword" @keypress.enter="onUpdatePasswordClicked" />
        <i :class="['absolute cursor-pointer self-center text-theme-500 hover:text-theme-300 right-2 fas', {'fa-eye': !showConfirmPassword}, {'fa-eye-slash': showConfirmPassword}]" @click="showConfirmPassword = !showConfirmPassword" />
      </div>
    </div>
    <button class=" w-full btn btn-theme" :disabled="!isPasswordValid" @click="onUpdatePasswordClicked">Update Password</button>
  </div>
</template>

<script>
import FullScreenLoadingMixin from '@/mixins/FullScreenLoadingMixin'

export default {
  name: 'update-password',
  mixins: [
    FullScreenLoadingMixin
  ],
  emits: [
    'successful-update'
  ],
  props: {
    resetToken: { type: String, required: false, default: null }
  },
  data () {
    return {
      showPassword: false,
      showConfirmPassword: false,
      password: '',
      confirmPassword: ''
    }
  },
  computed: {
    isPasswordValid () {
      if (this.password === null || this.confirmPassword === null) return false
      if (this.password === '' || this.confirmPassword === '') return false
      if (this.password.length < 3 || this.confirmPassword.length < 3) return false

      return this.password === this.confirmPassword
    }
  },
  methods: {
    async onUpdatePasswordClicked () {
      if (!this.isPasswordValid) return

      try {
        const self = this
        this.showLoading('Updating Password', null)
        const payload = { password: this.password, resetToken: this.resetToken }
        await this.$store.dispatch('resetPassword', payload)
        this.password = ''
        this.confirmPassword = ''
        this.$swal.fire({
          icon: 'success',
          title: 'Password Updated',
          text: 'Your password was successfully updated.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didClose: () => {
            self.$emit('successful-update')
          }
        })
      } catch (error) {
        console.error('Error updating password', error)
        this.hideLoading()
        this.$swal.fire({
          icon: 'error',
          title: 'Password Update Failed',
          text: 'An error occurred while updating your password and your password was not updated.',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
    }
  }
}
</script>
