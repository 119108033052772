<template>
  <span>
    <div class="flex justify-between w-full">
      <div :class="[{'cursor-pointer': !disabled}, {'cursor-not-allowed': disabled}, {'btn btn-theme btn-sm': dropdownStyle === 'button'}, {'hover:text-theme-200': dropdownStyle === 'text' && !disabled}]" @click="toggleDropDown">
        {{ title }}
        <i :class="['ml-1 fas', {'fa-chevron-up': showDropDown}, {'fa-chevron-down': !showDropDown}]" />
      </div>
    </div>
    <div :class="['absolute rounded z-999 flex flex-col bg-white cursor-pointer transition-all ease-in-out duration-500', {'max-h-0 overflow-hidden': !showDropDown}, {'h-auto max-h-screen border border-black': showDropDown}]">
      <div v-for="option in options" :key="option.id" @click="selectOption(option)" :class="['transition-all ease-in-out duration-500 last:border-0 flex border-b border-black items-center p-1', {'opacity-0': !showDropDown}, {'opacity-100': showDropDown}, {'hover:bg-theme-400 text-black hover:text-white': !isOptionDisabled(option)}, {'text-gray-400 cursor-not-allowed hover:text-gray-400': isOptionDisabled(option)} ]" :disabled="isOptionDisabled(option)">
        <i v-if="option.icon" :class="`mr-1 fas ${option.icon}`"></i> {{option.name}}
      </div>
    </div>
  </span>
</template>

<script>

export default {
  name: 'inline-dropdown',
  props: {
    options: { type: Array, required: true },
    buttonTitle: { type: String, required: true },
    dropdownStyle: { type: String, required: false, default: 'button' },
    updateButtonTitle: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      showDropDown: false,
      title: this.buttonTitle
    }
  },
  methods: {
    isOptionDisabled (option) {
      if (typeof option.disabled === 'undefined') return false
      return option.disabled === true
    },
    toggleDropDown () {
      if (this.disabled) {
        this.showDropDown = false
        return
      }
      this.showDropDown = !this.showDropDown
    },
    selectOption (option) {
      if (this.disabled) return
      if (this.isOptionDisabled(option)) return
      option.performAction()
      this.showDropDown = false
      if (this.updateButtonTitle === true) {
        this.title = option.name
      }
    }
  }
}
</script>
