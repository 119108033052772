<template>
  <div class="max-w-4/5 max-h-4/5 overflow-auto bg-white text-black p-3 rounded">
    <JsonViewer v-if="jsonContent" :json-string="jsonContent"></JsonViewer>
  </div>
</template>

<script>
import JsonViewer from '@/components/JsonViewer'

export default {
  name: 'json-renderer',
  components: {
    JsonViewer
  },
  props: {
    fileContent: { type: String, required: true }
  },
  emits: ['error-loading', 'finished-loading'],
  data () {
    return {
      jsonContent: null
    }
  },
  mounted () {
    try {
      this.jsonContent = JSON.stringify(JSON.parse(atob(this.fileContent)), null, 4)
      this.$emit('finished-loading')
    } catch (error) {
      console.log(error)
      this.$emit('error-loading')
    }
  }
}
</script>
