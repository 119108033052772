<template>
  <div class="flex flex-col items-center">
    <template v-if="readmeSource">
      <a :href="downloadFileUrl" :title="downloadFilename" :download="downloadFilename" class="flex mr-3 w-full justify-end cursor-pointer hover:text-theme-200 focus:text-theme-400 text-theme-400 font-bold">
        <div>Download SDK<i class="fas fa-download ml-1" title="download"></i></div>
      </a>
      <span class="render-list render-table max-w-full">
        <Markdown :source="readmeSource" />
      </span>
    </template>
    <retryable-error v-else-if="hasError" text="Error loading SDK" @retry="fetchReadme"></retryable-error>
    <loading v-else text="Loading..." class="mt-3"></loading>
  </div>
</template>

<script>
import 'highlight.js/styles/monokai.css'
import Loading from '@/components/Loading'
import Markdown from 'vue3-markdown-it'
import RetryableError from '@/components/RetryableError'

export default {
  name: 'sdk',
  components: {
    Loading,
    Markdown,
    RetryableError
  },
  props: {
    readmeFile: { type: String, required: true },
    downloadFile: { type: String, required: true }
  },
  data () {
    return {
      readmeSource: null,
      hasError: false
    }
  },
  computed: {
    downloadFileUrl () {
      const gatewayApiLocation = this.$store.state.gatewayApiLocation
      return `${gatewayApiLocation}/filesets/unacorn-help/download?file=${this.downloadFile}`
    },
    downloadFilename () {
      return this.downloadFile.split('\\').pop().split('/').pop()
    }
  },
  watch: {
    readmeFile () {
      this.fetchReadme()
    }
  },
  methods: {
    async fetchReadme () {
      this.readmeSource = null
      this.hasError = false
      try {
        const response = await this.$store.dispatch('fetchHelpFile', this.readmeFile)
        const decoder = new TextDecoder('utf-8')
        this.readmeSource = decoder.decode(response.data)
      } catch (error) {
        console.error('Error fetching sdk readme', error)
        this.hasError = true
      }
    }
  },
  mounted () {
    this.fetchReadme()
  }
}
</script>

<!-- Tailwind blows away h1, h2, etc styles, so add some back here for markdown -->
<style lang="less" scoped>
:deep(h1) {
  @apply text-2xl;
  @apply py-2;
}

:deep(h2) {
  @apply text-xl;
  @apply py-1;
}
</style>
