<template>
  <div>
    <retryable-error v-if="errorLoadingIterations" text="Error loading flow request." @retry="loadIterations"></retryable-error>
    <loading v-else-if="iterations === null" text="Loading flow request details..."></loading>
    <div v-else class="flex flex-col w-full h-screen">
      <iterations-display
        ref="iterationsDisplay"
        class="h-3/4"
        :title="request.name"
        :subtitle="requestStatus.displayName"
        :iterations="iterations"
        :main-iteration="mainIteration"
        :additional-actions="requestActions"
      >
      </iterations-display>

      <div class="flex flex-col h-1/4 overflow-auto">
        <div class="text-xl font-bold text-theme-500">Comments ({{ comments?.length ?? '-' }})</div>
        <retryable-error v-if="errorLoadingComments" text="Error loading comments." @retry="loadComments"></retryable-error>
        <loading v-else-if="comments === null" text="Loading comments..."></loading>
        <template v-else>
          <div v-if="comments.length === 0" class="text-gray-500 text-center">no comments</div>
          <div ref="commentContainer" class="overflow-y-auto">
            <div v-for="(comment, idx) in comments" :key="comment.id" :class="['p-1 flex flex-col w-full max-w-full', {'bg-theme-100': idx % 2 === 0}]">
              <div class="flex justify-between">
                <div class="font-bold">{{ comment.commenterUsername }}</div>
                <small><relative-time-display :time-ms="comment.createdDateMs"></relative-time-display></small>
              </div>
              <div class="mx-1 whitespace-pre-wrap">{{ comment.text }}</div>
            </div>
          </div>
          <div class="flex border-t border-theme-500 mt-auto">
            <textarea class="form-control" v-model="commentText" rows="2" placeholder="new comment"></textarea>
            <button class="btn btn-theme btn-sm h-full" :disabled="commentText === null || commentText.length === 0" @click="addComment"><i class="fas fa-plus mr-1"></i>Add</button>
          </div>
        </template>
      </div>
    </div>

    <modal v-if="newIteration !== null" large-width-class="lg:w-4/5">
      <template v-slot:header>
        <h1 class="text-2xl">New Iteration</h1>
      </template>
      <template v-slot:body>
        <loading v-if="newIteration.isSaving" text="Saving Iteration..."></loading>
        <div v-else class="flex flex-col">
          <strong>Content</strong>
          <ni-flow-request-file-upload v-model="newIteration.content"></ni-flow-request-file-upload>
        </div>
      </template>
      <template v-slot:footer>
        <template v-if="!newIteration.isSaving">
          <button class="btn btn-theme-muted" @click="newIteration = null"><i class="fas fa-ban mr-1" />Cancel</button>
          <button class="btn btn-theme" @click="createIteration" :disabled="newIteration.content === null || newIteration.content.length === 0"><i class="fas fa-save mr-1" />Save</button>
        </template>
      </template>
    </modal>

    <modal v-if="processingText !== null">
      <template v-slot:body>
        <loading :text="processingText"></loading>
      </template>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash'
import IterationsDisplay from '@/components/niflow/IterationsDisplay'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import NiFlowRequestFileUpload from '@/components/niflow/NiFlowRequestFileUpload'
import RelativeTimeDisplay from '@/components/RelativeTimeDisplay'
import RetryableError from '@/components/RetryableError'
import UserRolesMixin from '@/mixins/UserRolesMixin'
import { FlowRequestStatus } from '@/utils/FlowRequestStatus.js'

export default {
  name: 'flow-requeset',
  components: {
    IterationsDisplay,
    Loading,
    Modal,
    NiFlowRequestFileUpload,
    RelativeTimeDisplay,
    RetryableError
  },
  mixins: [UserRolesMixin],
  props: {
    groupName: { type: String, required: true },
    flowId: { type: Number, required: true },
    requestId: { type: Number, required: true }
  },
  data () {
    return {
      iterations: null,
      comments: null,
      errorLoadingIterations: false,
      errorLoadingComments: false,
      commentText: null,
      newIteration: null,
      processingText: null,
      mainIteration: null
    }
  },
  computed: {
    flow () {
      return this.$store.state.niFlow.flows[this.groupName].find(flow => flow.id === this.flowId) ?? null
    },
    group () {
      return this.$store.state.niFlow.groups.find(group => group.name === this.groupName) ?? null
    },
    request () {
      return this.$store.state.niFlow.requests[this.groupName]?.find(request => request.id === this.requestId) ?? null
    },
    isUserAdmin () {
      return this.userRoles.includes('admin')
    },
    requestStatus () {
      if (this.request === null) return null
      return FlowRequestStatus.fromStatusNumber(this.request.status)
    },
    requestActions () {
      if (this.requestStatus === null || this.requestStatus.isFinal) return []

      let actions = []
      if (this.isUserAdmin) {
        actions = actions.concat([
          {
            id: 'accept',
            name: 'Accept Request',
            icon: 'fa-check',
            performAction: () => {
              this.adminRequestStatus(true)
            }
          },
          {
            id: 'reject',
            name: 'Reject Request',
            icon: 'fa-minus',
            performAction: () => {
              this.adminRequestStatus(false)
            }
          }
        ])
      }

      // always allow a user to abandon if it isn't finalzied
      actions.push({
        id: 'abandon',
        name: 'Abandon Request',
        icon: 'fa-trash',
        performAction: this.abandonRequest
      })

      // only allow submittal or new iteration if in progress
      if (this.requestStatus === FlowRequestStatus.IN_PROGRESS) {
        actions = actions.concat([
          {
            id: 'newIteration',
            name: 'Create New Iteration',
            icon: 'fa-pencil',
            performAction: () => {
              this.newIteration = {
                content: null,
                isSaving: false
              }
            }
          },
          {
            id: 'submit',
            name: 'Submit for Review',
            icon: 'fa-floppy-disk',
            performAction: this.submitRequest
          }
        ])
      }

      return actions
    }
  },
  watch: {
    request () {
      this.loadIterations()
      this.loadComments()
    }
  },
  methods: {
    async loadIterations () {
      if (this.group === null || this.flow === null || this.request === null) return

      this.iterations = null
      this.mainIteration = null
      this.errorLoadingIterations = false

      try {
        const data = {
          groupName: this.group.name,
          flowId: this.flow.id,
          requestId: this.request.id
        }
        this.loadMainIteration()
        const response = await this.$store.dispatch('niFlow/fetchFlowRequestIterations', data)
        const iterations = _.sortBy(response.data, 'createdDateMs')
        iterations.forEach((iteration, idx) => {
          iteration.label = `Iteration ${idx + 1}`
        })
        this.iterations = iterations
      } catch (error) {
        console.error('Error loading iterations', error)
        this.errorLoadingIterations = true
      }
    },
    async loadMainIteration () {
      const response = await this.$store.dispatch('niFlow/fetchFlowMainIterations', { groupName: this.groupName, flowId: this.flowId })
      if (response.data.length === 0) return
      const mainIterations = _.sortBy(response.data, 'timeMs')
      this.mainIteration = mainIterations.at(-1).iteration
    },
    async loadComments () {
      if (this.group === null || this.flow === null || this.request === null) return

      this.comments = null
      this.errorLoadingComments = false

      try {
        const data = {
          groupName: this.group.name,
          flowId: this.flow.id,
          requestId: this.request.id
        }
        const response = await this.$store.dispatch('niFlow/fetchFlowRequestComments', data)
        this.comments = _.sortBy(response.data, 'createdDateMs')
      } catch (error) {
        console.error('Error loading comments', error)
        this.errorLoadingComments = true
      }
    },
    async addComment () {
      if (this.commentText === null || this.commentText.length === 0) return

      const data = {
        groupName: this.group.name,
        flowId: this.flow.id,
        requestId: this.request.id,
        commentText: this.commentText
      }
      this.commentText = null
      try {
        const response = await this.$store.dispatch('niFlow/addReviewComment', data)
        this.comments.push(response.data)
        await this.$nextTick()
        this.$refs.commentContainer.scrollTop = this.$refs.commentContainer.scrollHeight
      } catch (error) {
        console.error('Error adding rewview comment', error)
        this.$swal({
          icon: 'error',
          title: 'Failed to Add Comment',
          text: 'An error occurred adding your comment. Please try again.'
        })
        // set the comment text back so they can try again
        this.commentText = data.commentText
      }
    },
    async createIteration () {
      if (this.newIteration.content === null || this.newIteration.content.length === 0) return

      try {
        this.newIteration.isSaving = true
        const data = {
          groupName: this.group.name,
          flowId: this.flow.id,
          requestId: this.request.id,
          content: this.newIteration.content
        }
        const response = await this.$store.dispatch('niFlow/addRequestIteration', data)
        response.data.label = `Iteration ${this.iterations.length + 1}`
        this.iterations.push(response.data)
        this.newIteration = null
        this.$refs.iterationsDisplay.scrollToLatest()
      } catch (error) {
        console.error('Error adding new iteration', error)
        this.$swal({
          icon: 'error',
          title: 'Failed to Save New Iteration',
          text: 'An error occurred adding the new iteration. Please try again.'
        })
        this.newIteration.isSaving = false
      }
    },
    async abandonRequest () {
      this.processingText = 'Abandoning Request...'
      try {
        const data = {
          groupName: this.group.name,
          flowId: this.flow.id,
          requestId: this.request.id
        }
        const response = await this.$store.dispatch('niFlow/abandonRequest', data)
        response.data.flowId = this.flow.id
        this.$store.commit('niFlow/updateRequest', { groupName: this.groupName, request: response.data })
      } catch (error) {
        console.error('Error abandoning request', error)
        this.$swal({
          icon: 'error',
          title: 'Failed to Abandon Request',
          text: 'An error occurred abandoning the request. Please try again.'
        })
      } finally {
        this.processingText = null
      }
    },
    async submitRequest () {
      this.processingText = 'Submitting Request...'
      try {
        const data = {
          groupName: this.group.name,
          flowId: this.flow.id,
          requestId: this.request.id
        }
        const response = await this.$store.dispatch('niFlow/submitRequest', data)
        response.data.flowId = this.flow.id
        this.$store.commit('niFlow/updateRequest', { groupName: this.groupName, request: response.data })
      } catch (error) {
        console.error('Error submitting request', error)
        this.$swal({
          icon: 'error',
          title: 'Failed to Submit Request',
          text: 'An error occurred submitting the request. Please try again.'
        })
      } finally {
        this.processingText = null
      }
    },
    async adminRequestStatus (isAccepted) {
      this.processingText = `${isAccepted ? 'Accepting' : 'Rejecting'} Request...`
      try {
        const action = isAccepted ? 'niFlow/acceptRequest' : 'niFlow/rejectRequest'
        const response = await this.$store.dispatch(action, this.request.id)
        response.data.flowId = this.flow.id
        this.$store.commit('niFlow/updateRequest', { groupName: this.groupName, request: response.data })
      } catch (error) {
        console.error(`Error ${isAccepted ? 'accepting' : 'rejecting'} request`, error)
        this.$swal({
          icon: 'error',
          title: 'Failed to Set Request Status',
          text: `An error occurred ${isAccepted ? 'accepting' : 'rejecting'} the request. Please try again.`
        })
      } finally {
        this.processingText = null
      }
    }
  },
  mounted () {
    this.loadIterations()
    this.loadComments()
  }
}
</script>
