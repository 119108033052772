<template>
  <entity-editor
    id-column="name"
    entity-type-display="External Dataset"
    entity-type="datasets/external"
    editor-help-text="Use the following definitions below.<br />API Method: 0=GET, 1=PUT, 2=POST.<br />Filter Type: 0=Numeric, 1=String.<br />Filter Placement: 0=URL, 1=Query, 2=Body.<br />If the API does not use keystore and/or api credentials, set the entire object to null."
    :columns="datasetColumns"
    :new-entity-schema="schemaObj"
    :perform-clear="clearDatasets">
  </entity-editor>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'

export default {
  name: 'external-dataset-settings',
  components: {
    EntityEditor
  },
  data () {
    return {
      datasetColumns: [
        { field: 'description', display: 'Description' },
        { field: 'baseRoute', display: 'Base Route' }
      ],
      schemaObj: {
        name: '',
        description: '',
        enabled: true,
        baseRoute: '',
        keystoreCredential: {
          path: '',
          type: '',
          password: ''
        },
        apiKeyCredential: {
          headerName: '',
          key: ''
        },
        apis: [
          {
            name: '',
            description: '',
            path: '',
            method: 0,
            filters: [
              {
                name: '',
                description: '',
                displayName: '',
                type: 0,
                placement: 0,
                required: true
              }
            ]
          }
        ],
        roles: [
          {
            roleName: ''
          }
        ]
      }
    }
  },
  methods: {
    clearDatasets () {
      // clear all dataset data and search data
      this.$store.commit('setSelectedDataset', null)
      this.$store.commit('setUnifiedDatasetCategories', null)
      this.$store.commit('clearSourceDescriptions')
      this.$store.commit('clearDatasetSearches')
    }
  }
}
</script>
