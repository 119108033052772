<template>
  <div>
    <div v-if="showOverlay" class="fixed top-0 right-0 p-2 bg-theme-500 text-white flex flex-col">
      <a :href="viewSettings.url" class="flex justify-center w-full hover:text-theme-300" target="_blank" title="open in new tab">
        Click to open in new tab.<i class="fas fa-arrow-up-right-from-square pl-1" />
      </a>
      <div class="text-center flex items-center self-end cursor-pointer hover:text-theme-300 text-sm" @click="showOverlay = false">
        <div>dismiss</div>
        <i class="fas fa-xmark pl-1" />
      </div>
    </div>
    <div class="flex items-center h-screen w-full justify-center">
      <iframe v-if="showView" :src="viewSettings.url" class="w-full h-full"></iframe>
      <div v-else>Page does not exist</div>
    </div>
  </div>
</template>

<script>
import UserRolesMixin from '@/mixins/UserRolesMixin'

export default {
  name: 'external-view',
  mixins: [UserRolesMixin],
  props: {
    viewSettings: { type: Object, required: true }
  },
  data () {
    return {
      showOverlay: true
    }
  },
  computed: {
    showView () {
      if (this.viewSettings === null) return false
      if (this.currentUser === null) return false
      if (this.viewSettings.role === '*') return true
      return this.userRoles.includes(this.viewSettings.role)
    }
  },
  watch: {
    viewSettings () {
      this.showOverlay = true
    }
  }
}
</script>
