<template>
  <div class="flex flex-col w-full min-w-72 max-w-72 max-h-72 overflow-auto rounded-xl">
    <div :class="`bg-${headerColor} w-full flex flex-col sticky top-0 pb-1 px-1 text-white`">
      <div class="font-bold text-lg">
        {{ title }}
      </div>
      <div class="flex w-full justify-between text-xs">
        <div>{{ location }}</div>
        <div v-if="subtitle">{{ subtitle }}</div>
      </div>
    </div>
    <div class="flex flex-col">
      <loading v-if="isLoading" text="Loading data..." class="p-2"></loading>
      <template v-else-if="keys.length > 0">
        <div v-for="(key, idx) in keys" :key="key" :class="['flex flex-col px-2 text-sm', { 'bg-white': idx % 2 === 0 }, { 'bg-theme-100': idx % 2 !== 0 }]">
          <div class="font-bold">{{ key }}</div>
          <div class="ml-2">{{ result[key] }}</div>
        </div>
      </template>
      <div v-else class="text-sm text-gray-500 w-full text-center">
        no data
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Loading from '@/components/Loading'

export default {
  name: 'map-item-popup',
  components: {
    Loading
  },
  props: {
    title: { type: String, required: true },
    location: { type: String, required: true },
    headerColor: { type: String, required: true },
    subtitle: { type: String, required: false },
    result: { type: Object, required: false }
  },
  computed: {
    isLoading () {
      return this.result === null
    },
    keys () {
      if (this.result === null) return {}
      return _.sortBy(Object.keys(this.result))
    }
  }
}
</script>
