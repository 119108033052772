<template>
  <entity-editor
    entity-type-display="Doc"
    entity-type="docs"
    :disabled-actions="['disable']"
    :columns="docColumns"
    :perform-clear="clearsDocs">
  </entity-editor>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'

export default {
  name: 'doc-settings',
  components: {
    EntityEditor
  },
  data () {
    return {
      docColumns: [
        { field: 'name', display: 'Name' },
        { field: 'category', display: 'Category' },
        { field: 'description', display: 'Description' }
      ]
    }
  },
  methods: {
    clearsDocs () {
      const self = this
      this.$store.commit('setPresignableDocs', null)
      this.$store.dispatch('fetchPresignableDocs').then(response => {
        self.$store.commit('setPresignableDocs', response.data.docs)
      }).catch(error => {
        console.error('Error getting doc files', error)
      })
    }
  }
}
</script>
