<template>
  <entity-editor
    entity-type-display="Dataset"
    entity-type="datasets/internal"
    :columns="datasetColumns"
    :perform-clear="clearDatasets">
  </entity-editor>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'

export default {
  name: 'internal-dataset-settings',
  components: {
    EntityEditor
  },
  data () {
    return {
      datasetColumns: [
        { field: 'category', display: 'Category' },
        { field: 'schema', display: 'Schema' }
      ]
    }
  },
  computed: {
    sourceDescriptions () {
      return this.$store.state.sourceDescriptions
    },
    datasetSearches () {
      return this.$store.state.datasetSearches
    },
    selectedDataset () {
      return this.$store.state.selectedDataset
    }
  },
  methods: {
    clearDatasets () {
      // clear all dataset data and search data
      this.$store.commit('setSelectedDataset', null)
      this.$store.commit('setUnifiedDatasetCategories', null)
      this.$store.commit('clearSourceDescriptions')
      this.$store.commit('clearDatasetSearches')
    }
  }
}
</script>
