<template>
  <div class="flex w-full max-w-full bg-theme-500 h-full justify-between items-center text-white px-2 gap-4">
    <div class="min-w-24 text-sm">{{ friendlyTime(startTimeMs) }}</div>
    <div ref="scrubberTrack" :class="['h-2 bg-theme-100 w-full max-w-full rounded-full relative border border-black', {'grayscale': disabled}]">
      <div ref="scrubberFill" class="h-2 bg-theme-400 rounded-l-full" :style="{width: `${currentScrubbedPercent}%`}"></div>
      <div ref="scrubberHandle" :class="['h-4 w-4 rounded-full bg-gray-300 absolute -top-1 border-black border', {'cursor-not-allowed': disabled}, {'cursor-pointer': !disabled}]" @mousedown="onMouseDown" :style="{left: `${currentScrubbedPercent}%`}" :title="friendlyTime(scrubberTimeMs)">
        <div v-if="isScrubbing" class="absolute -top-10 bg-black p-2 text-white text-xs min-w-40 text-center">{{ friendlyTime(scrubberTimeMs) }}</div>
      </div>
    </div>
    <div class="text-right min-w-24 text-sm">{{ friendlyTime(endTimeMs) }}</div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'playback-scrubber',
  emits: ['update:modelValue', 'scrub'],
  props: {
    modelValue: { type: Number, required: true },
    startTimeMs: { type: Number, required: true },
    endTimeMs: { type: Number, required: true },
    disabled: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      isScrubbing: false,
      currentScrubbedPercent: 100
    }
  },
  computed: {
    durationMs () {
      return this.endTimeMs - this.startTimeMs
    },
    scrubberTimeMs () {
      return this.startTimeMs + (this.durationMs * (this.currentScrubbedPercent / 100))
    },
    timeProps () {
      return `${this.modelValue}${this.startTimeMs}${this.endTimeMs}`
    }
  },
  watch: {
    timeProps () {
      // reset everything
      this.reset()
    }
  },
  methods: {
    reset () {
      this.isScrubbing = false
      this.currentScrubbedPercent = ((this.modelValue - this.startTimeMs) / this.durationMs) * 100
    },
    friendlyTime (timeMs) {
      return moment(timeMs).utc().format('MM/DD/YYYY, hh:mm:ss A')
    },
    onMouseDown () {
      if (this.disabled) { return }
      this.isScrubbing = true
    },
    onMouseMove (event) {
      if (!this.isScrubbing) return

      const trackWidth = this.$refs.scrubberTrack.clientWidth

      // the scrubber handle has a width of w-4 (16px), so you can't really scrub if the entire length is <= 16
      if (typeof trackWidth === 'undefined' || trackWidth <= 16) return

      const percentMovementX = (event.movementX / trackWidth) * 100
      this.currentScrubbedPercent += percentMovementX
      if (this.currentScrubbedPercent < 0) {
        this.currentScrubbedPercent = 0
      }
      if (this.currentScrubbedPercent > 100) {
        this.currentScrubbedPercent = 100
      }

      this.$emit('scrub', this.scrubberTimeMs)
    },
    onMouseUp () {
      if (this.isScrubbing) {
        this.$emit('update:modelValue', this.scrubberTimeMs)
      }
      this.isScrubbing = false
    }
  },
  mounted () {
    this.reset()
    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('mouseup', this.onMouseUp)
  },
  unmounted () {
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
  }
}
</script>
