<template>
  <div class="w-full h-full">
    <div class="flex flex-col max-h-4/5 scroll-auto gap-1">
      <div v-for="layer in availableLayers" :key="layer.name" class="flex w-full justify-between items-center bg-gray-800 p-2 rounded">
        <div class="flex gap-1 items-center">
          <input
            type="color"
            class="w-6 h-7 mr-2 bg-transparent border-0 rounded p-0 cursor-pointer"
            :value="layer.color"
            @input="onColorChanged(layer, $event)"
          />
          <div class="flex flex-col">
            <div class="flex">{{ layer.name }}</div>
            <div v-if="layer.isDefault" class="text-xs italic">default path</div>
          </div>
        </div>
        <div class="flex-1"></div> <!-- spacer view !-->
        <div>
          <div v-if="layer.highlightCondition" class="flex items-center gap-2">
            <input
              type="color"
              class="w-8 h-7 bg-transparent border-0 rounded p-0 cursor-pointer"
              :value="layer.highlightCondition.color"
              @input="onConditionColorChanged(layer, $event)"
            />
            <div class="flex flex-col w-full text-black">
              <v-select :options="columns" :model-value="layer.highlightCondition.column" @update:modelValue="onColumnChanged(layer, $event)" placeholder="select a column" :get-option-label="option => option.display" :append-to-body="true" class="w-full bg-white rounded"></v-select>
              <input class="form-control mt-2" :type="layer.highlightCondition.column?.type.toLowerCase() === 'number' ? 'number' : 'text'" :value="layer.highlightCondition.value" placeholder="value" @input="onColumnValueChanged(layer, $event.target.value)" />
            </div>
            <i class="fas fa-xmark text-red-600 cursor-pointer hover:text-red-400 text-lg" @click="onHighlightConditionRemoved(layer)" />
          </div>
          <div v-else class="cursor-pointer underline text-sm" @click="newHighlightCondition(layer)">
            add highlight condition...
          </div>
        </div>
        <div class="flex-1"></div> <!-- spacer view !-->
        <toggle-switch v-model="layer.isActive"></toggle-switch>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ToggleSwitch from '@/components/ToggleSwitch'

export default {
  name: 'path-layer-configurator',
  emits: ['update:modelValue'],
  components: {
    ToggleSwitch
  },
  props: {
    modelValue: { type: Object, required: true },
    columns: { type: Array, required: true }
  },
  data () {
    return {
      layerModel: this.modelValue
    }
  },
  computed: {
    availableLayers () {
      return _.sortBy(this.layerModel, 'name')
    }
  },
  watch: {
    modelValue () {
      this.layerModel = this.modelValue
    }
  },
  methods: {
    onColorChanged: _.debounce(async function (layer, event) {
      layer.color = event.target.value
      this.$emit('update:modelValue', this.layerModel)
    }, 500),
    onConditionColorChanged: _.debounce(async function (layer, event) {
      if (layer.highlightCondition === null) return
      layer.highlightCondition.color = event.target.value
      this.$emit('update:modelValue', this.layerModel)
    }, 500),
    onColumnChanged: _.debounce(async function (layer, column) {
      if (layer.highlightCondition === null) return
      layer.highlightCondition.column = column
      this.$emit('update:modelValue', this.layerModel)
    }, 500),
    onColumnValueChanged: _.debounce(async function (layer, value) {
      if (layer.highlightCondition === null) return
      layer.highlightCondition.value = value
      this.$emit('update:modelValue', this.layerModel)
    }, 500),
    newHighlightCondition (layer) {
      layer.highlightCondition = {
        color: '#FFFFFF',
        column: null,
        value: ''
      }
      this.$emit('update:modelValue', this.layerModel)
    },
    onHighlightConditionRemoved (layer) {
      layer.highlightCondition = null
      this.$emit('update:modelValue', this.layerModel)
    }
  }
}
</script>
