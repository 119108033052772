<template>
  <div>
    <router-view></router-view>
    <div v-if="isRootFlows" class="flex flex-col text-theme-500 gap-6">
      <div v-for="group in groups" :key="group.id">
        <div class="flex justify-between m-1">
          <div class="text-xl font-bold">{{ group.displayName }}</div>
          <button class="btn btn-theme btn-sm" @click="openNewFlowModal(group)"><i class="fas fa-plus mr-1"></i>New Flow</button>
        </div>
        <table class="w-full">
          <thead class="w-full text-white sticky top-0 bg-theme-400 z-999">
            <tr>
              <th class="px-1 border-r border-black">Name</th>
              <th class="px-1 border-r border-black"># Open Requests</th>
            </tr>
          </thead>
          <tbody class="w-full overflow-y-auto">
            <tr v-if="sortedFlows(group.name).length === 0">
              <td class="text-center text-gray-700" colspan="2">no flows</td>
            </tr>
            <tr v-for="(flow, idx) in sortedFlows(group.name)" :key="flow.id" :title="flow.name" :class="['py-1 h-16 w-full cursor-pointer hover:bg-theme-200 max-w-full overflow-hidden', {'bg-theme-100': idx % 2 === 0}]">
              <td class="break-all px-1 border-r border-black"><router-link :to="{name: 'MainFlowHistory', params: {groupName: group.name, flowId: flow.id}}" class="block">{{ flow.name }}</router-link></td>
              <td class="break-all px-1 border-r border-black"><router-link :to="{name: 'MainFlowHistory', params: {groupName: group.name, flowId: flow.id}}" class="block">{{ numberOfOpenRequests(group.name, flow.id) }}</router-link></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="groups.length === 0" class="w-full text-center mt-4 text-gray-500">no flows</div>

      <modal v-if="newFlow !== null">
        <template v-slot:header>
          <h1 class="text-2xl">New Flow for {{ newFlow.group.displayName }}</h1>
        </template>
        <template v-slot:body>
          <loading v-if="newFlow.isSaving" text="Saving Flow..."></loading>
          <div v-else class="flex flex-col w-full">
            <strong>Flow Name</strong>
            <input class="form-control" v-model="newFlow.flowName" />
          </div>
        </template>
        <template v-slot:footer>
          <template v-if="!newFlow.isSaving">
            <button class="btn btn-theme-muted" @click="newFlow = null"><i class="fas fa-ban mr-1" />Cancel</button>
            <button class="btn btn-theme" @click="createFlow" :disabled="newFlow.flowName === null || newFlow.flowName.trim().length === 0"><i class="fas fa-save mr-1" />Save</button>
          </template>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import { FlowRequestStatus } from '@/utils/FlowRequestStatus.js'

export default {
  name: 'ni-flow-groups',
  components: {
    Loading,
    Modal
  },
  data () {
    return {
      newFlow: null
    }
  },
  computed: {
    groups () {
      return _.sortBy(this.$store.state.niFlow.groups, ['displayName'])
    },
    flows () {
      return this.$store.state.niFlow.flows
    },
    requests () {
      return this.$store.state.niFlow.requests
    },
    isRootFlows () {
      return this.$route.name === 'NiFlowFlows'
    }
  },
  methods: {
    sortedFlows (groupName) {
      return _.sortBy(this.flows[groupName], ['name'])
    },
    numberOfOpenRequests (groupName, flowId) {
      return this.requests[groupName].filter(request => {
        return request.flowId === flowId && !FlowRequestStatus.fromStatusNumber(request.status).isFinal
      }).length
    },
    openNewFlowModal (group) {
      this.newFlow = {
        group,
        flowName: null,
        isSaving: false
      }
    },
    async createFlow () {
      if (this.newFlow.flowName === null || this.newFlow.flowName.length === 0) return

      try {
        this.newFlow.isSaving = true
        const response = await this.$store.dispatch('niFlow/createFlow', { groupName: this.newFlow.group.name, flowName: this.newFlow.flowName })
        this.$store.commit('niFlow/addFlow', { groupName: this.newFlow.group.name, flow: response.data })
        this.newFlow = null
      } catch (error) {
        console.error('Error creating flow.', error)
        this.$swal({
          icon: 'error',
          title: 'Failed to Create Flow',
          text: `An error occurred creating the flow. Ensure a flow with the name ${this.newFlow.flowName} does not exist and try again.`
        })
        this.newFlow.isSaving = false
      }
    }
  }
}
</script>
