import { createApp } from 'vue'
import router from '@/router/router.js'
import store from '@/store/store.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSelect from 'vue-select'
import '@/assets/tailwind.css'
import App from '@/App.vue'
import VueDiff from 'vue-diff'
import VueSweetalert2 from 'vue-sweetalert2'
import FloatingVue from 'floating-vue'
import ExternalView from '@/views/ExternalView'
import Cookies from '@/utils/cookies'
import L from 'leaflet'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-select/dist/vue-select.css'
import 'v-calendar/dist/style.css'
import 'vue-diff/dist/index.css'
import '@/assets/components-custom.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'floating-vue/dist/style.css'

import hljs from 'highlight.js/lib/core'
import xml from 'highlight.js/lib/languages/xml'
import yaml from 'highlight.js/lib/languages/yaml'
import python from 'highlight.js/lib/languages/python'

(async () => {
  // Update locations to default markers based on how yarn packages
  delete L.Icon.Default.prototype._getIconUrl
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  })

  VueDiff.hljs.registerLanguage('xml', xml)
  VueDiff.hljs.registerLanguage('yaml', yaml)
  VueDiff.hljs.registerLanguage('python', python)
  hljs.registerLanguage('xml', xml)
  hljs.registerLanguage('yaml', yaml)
  hljs.registerLanguage('python', python)

  let app = createApp(App).use(store).use(VueAxios, axios).use(VueDiff).use(VueSweetalert2).use(FloatingVue).component('v-select', VueSelect)

  // expires, path, domain, secure, sameSite, partitioned
  const cookieSetting = store.state.authCookie
  Cookies.config(cookieSetting.expires, cookieSetting.path, cookieSetting.domain, cookieSetting.secure, cookieSetting.sameSite, cookieSetting.partitioned)
  if (typeof (Storage) !== 'undefined') {
    const tokenWrapper = sessionStorage.tokenWrapper ? JSON.parse(sessionStorage.tokenWrapper) : null
    if (tokenWrapper !== null) {
      // attempt to refresh the token to log user in
      try {
        const response = await store.dispatch('tokenCheck', tokenWrapper.refresh_token)
        store.commit('setTokenWrapper', response.data)
      } catch (error) {
        console.info('Invalid cookie, cannot refresh. User must log in again', error)
        Cookies.remove('JWT')
        sessionStorage.removeItem('tokenWrapper')
      }
    }
  }

  // auth interceptor
  router.beforeEach((to, from, next) => {
    if (store.state.currentUser === null && to.name !== 'Login') {
      // user isn't logged in, log them in
      store.commit('setPreAuthIntendedRoute', to)
      next({ name: 'Login' })
    } else {
      if (store.state.disabledLinks.includes(to.name)) {
        next({ name: 'UnifiedDatasets' })
      } else {
        next()
      }
    }
  })

  // add external page routes
  store.state.externalPages.forEach(externalPage => {
    router.addRoute({
      path: externalPage.unacornRoute,
      name: externalPage.name,
      component: ExternalView,
      props () {
        return {
          viewSettings: externalPage
        }
      }
    })
  })

  app = app.use(router)
  app.mount('#app')
})()
