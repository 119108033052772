<template>
  <entity-editor
    id-column="name"
    entity-type-display="Jolt Specification"
    entity-type="joltSpecifications"
    :new-entity-schema="schemaObj"
    :disabled-actions="['disable']"
    :columns="joltSpecColumns">
  </entity-editor>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'

export default {
  name: 'jolt-specification-settings',
  components: {
    EntityEditor
  },
  data () {
    return {
      joltSpecColumns: [
        { field: 'sourceType', display: 'Source Type' },
        { field: 'targetType', display: 'Target Type' }
      ],
      schemaObj: {
        name: '',
        sourceType: '',
        targetType: '',
        joltSpec: ''
      }
    }
  }
}
</script>
