<template>
  <div class="w-full h-full text-center flex flex-col items-center">
    <button class="btn btn-theme btn-sm mb-2" @click="resetRange" :disabled="!hasFullRange"><i class="fas fa-times mx-1" />Clear Filter</button>
    <DatePicker v-model.range="range" :columns="2" mode="datetime" timezone="utc"></DatePicker>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar'
import moment from 'moment'

export default {
  name: 'date-range',
  components: {
    DatePicker
  },
  props: {
    modelValue: { type: Object, required: false, default: null }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      range: {
        start: null,
        end: null
      }
    }
  },
  computed: {
    hasFullRange () {
      if (this.range === null) return false
      return this.range.start !== null && this.range.end !== null
    }
  },
  watch: {
    range: {
      handler () {
        if (this.hasFullRange) {
          const start = moment.utc(this.range.start).milliseconds(0)
          const end = moment.utc(this.range.end).milliseconds(0)
          const momentRange = {
            start,
            end,
            textual: `${start.format('MM/DD/YYYY, hh:mm:ss A')} - ${end.format('MM/DD/YYYY, hh:mm:ss A')}`
          }
          this.$emit('update:modelValue', momentRange)
        } else {
          this.$emit('update:modelValue', null)
        }
      },
      deep: true
    }
  },
  methods: {
    resetRange () {
      this.range = {
        start: null,
        end: null
      }
    }
  },
  created () {
    if (this.modelValue !== null) {
      const range = {
        // the model value are moment dates, but our calendar plugin wants javascript dates
        start: moment(this.modelValue.start).toDate(),
        end: moment(this.modelValue.end).toDate()
      }
      this.range = range
    }
  }
}
</script>
