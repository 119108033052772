import _ from 'lodash'

export class FlowRequestStatus {
  static UNKNOWN = new FlowRequestStatus(-1, false, 'UNKNOWN')
  static IN_PROGRESS = new FlowRequestStatus(0, false, 'In Progress')
  static READY_FOR_REVIEW = new FlowRequestStatus(1, false, 'Ready for Review')
  static ABANDONED = new FlowRequestStatus(2, true, 'Abandoned')
  static ACCEPTED = new FlowRequestStatus(3, true, 'Accepted')

  constructor (number, isFinal, displayName) {
    this.number = number
    this.isFinal = isFinal
    this.displayName = displayName
  }

  static fromStatusNumber (number) {
    const result = _.find(Object.keys(FlowRequestStatus), status => FlowRequestStatus[status].number === number)
    if (typeof result !== 'undefined') return FlowRequestStatus[result]
    return FlowRequestStatus.UNKNOWN
  }
}
