<template>
  <template v-for="route in routes" :key="route.name">
    <router-link v-if="showRoute(route)" :to="{name: route.name}" class="p-2 rounded flex items-center w-full hover:no-underline hover:text-white hover:bg-theme-300">
      <i :class="['fas mr-2', route.icon]"></i>
      <span class="text-sm">{{route.display}}</span>
    </router-link>
  </template>
</template>

<script>
export default {
  name: 'ni-flow-child-menu',
  data () {
    return {
      routes: [
        {
          name: 'NiFlowFlows',
          icon: 'fa-certificate',
          display: 'Flows'
        },
        {
          name: 'NiFlowRequests',
          icon: 'fa-code-pull-request',
          display: 'Flow Requests'
        }
      ]
    }
  },
  computed: {
    disabledLinks () {
      return this.$store.state.disabledLinks
    }
  },
  methods: {
    showRoute (route) {
      return !this.disabledLinks.includes(route.name)
    }
  }
}
</script>
