<script>
export default {
  name: 'display-format-mixin',
  methods: {
    deSnakeCase (snakeCasedItem) {
      const words = snakeCasedItem.split('_')
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1)
      }
      return words.join(' ')
    }
  }
}
</script>
