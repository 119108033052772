<template>
  <div class="flex flex-col w-full text-black bg-white rounded-md">
    <div :class="['flex justify-between items-center gap-4  hover:bg-theme-100 hover:text-theme-500 cursor-pointer p-2 font-bold sticky top-0', {'bg-theme-100 text-theme-500 rounded-t-md': isExpanded},  {'rounded-md': !isExpanded}]" @click="isExpanded = !isExpanded">
      <div class="text-wrap">{{ title }}</div>
      <div class="w-6 h-6 bg-gray-200 rounded-full flex items-center justify-center"><i :class="['fas', {'fa-plus': !isExpanded}, {'fa-minus': isExpanded}]"></i></div>
    </div>
    <div :class="['whitespace-pre-wrap transition-all ease-in-out max-h-0 overflow-hidden px-2 duration-500', {'max-h-screen overflow-auto': isExpanded}]">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'collapsible-text',
  props: {
    title: { type: String, required: true },
    content: { type: String, required: true }
  },
  data () {
    return {
      isExpanded: false
    }
  }
}
</script>
