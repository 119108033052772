<template>
  <div class="flex flex-col items-center">
    <div class="flex items-center justify-between gap-2 sticky top-0 bg-theme-500 text-white p-2 w-full">
      <div class="btn btn-sm btn-theme" @click="showHelp = true"><i class="fas fa-question mr-1" />Help</div>
      <div class="flex gap-2 items-center text-2xl font-extrabold">
        <i class="fas fa-key"/>
        <div>API Keys</div>
      </div>
      <div class="btn btn-sm btn-theme" @click="startCreating"><i class="fas fa-plus mr-1" />Create Key</div>
    </div>

    <div class="flex flex-col items-center w-full">
      <retryable-error v-if="hasError" text="Failed to load API Keys" @retry="fetchApiKeys"></retryable-error>
      <loading v-else-if="apiKeys === null" text="Loading API Keys..."></loading>
      <div v-if="!hasError && apiKeys !== null && apiKeys.length === 0" class="w-full h-screen flex flex-col items-center justify-center font-bold text-gray-400 mx-2 -my-10">
        <i class="fas fa-key text-3xl mb-1" />
        <h1 class="text-3xl">No API Keys</h1>
        api keys will show here for you to mange
      </div>
      <table v-if="!hasError && apiKeys !== null && apiKeys.length > 0" class="w-full">
        <thead class="w-full text-white sticky top-0 bg-theme-400 z-999">
          <tr>
            <th class="px-1 border-r border-black">Name</th>
            <th class="px-1 border-r border-black">Date Created</th>
            <th class="px-1 border-r border-black">Last Used</th>
            <th class="px-1 border-r border-black">Key</th>
            <th v-for="resource in resources" :key="resource.type" class="px-1 border-r border-black">{{ resource.type }}</th>
            <th class="px-1"></th>
          </tr>
       </thead>
       <tbody class="w-full overflow-y-auto">
        <tr v-for="(apiKey, idx) in apiKeys" :key="apiKey.id" :class="['py-1 h-10 w-full hover:bg-theme-200 max-w-full overflow-hidden', {'bg-theme-100': idx % 2 === 0}]">
          <td class="break-all px-1 border-r border-black" :title="apiKey.name" >{{ apiKey.name }}</td>
          <td class="break-all px-1 border-r border-black"><relative-time-display :time-ms="apiKey.createdDateMs"></relative-time-display></td>
          <td class="break-all px-1 border-r border-black">
            <relative-time-display v-if="apiKey.lastUsedMs" :time-ms="apiKey.lastUsedMs"></relative-time-display>
            <span v-else>never</span>
          </td>
          <td class="break-all px-1 border-r border-black">
            <div class="flex gap-2 justify-between items-center">
              <div>*******</div>
              <i class="ml-1 fas fa-copy cursor-pointer text-theme-500 hover:text-theme-100" title="Copy Key" @click="copyKey(apiKey)" />
            </div>
          </td>
          <td v-for="resource in resources" :key="resource.type" class="break-all px-1 border-r border-black">
            <div v-if="resource.getItems(apiKey).length === 0">
              none
            </div>
            <div v-else-if="resource.getItems(apiKey).length === 1">
              <api-key-resource :type="resource.type" :resource-name="resource.getResourceName(resource.getItems(apiKey)[0])" :hasAccess="resource.hasAccess(resource.getItems(apiKey)[0])"></api-key-resource>
            </div>
            <VMenu v-else :delay="{ show: 200, hide: 0 }">
              <span class="text-theme-500 underline cursor-pointer">{{ resource.getItems(apiKey).length }} {{ resource.type }}s</span>

              <template #popper>
                <div v-for="(item, idx) in resource.getItems(apiKey)" :key="item.id" :class="['p-2 h-10 w-full max-w-full overflow-hidden', {'bg-gray-200': idx % 2 === 0}]">
                  <api-key-resource :type="resource.type" :resource-name="resource.getResourceName(item)" :hasAccess="resource.hasAccess(item)"></api-key-resource>
                </div>
              </template>
            </VMenu>
          </td>
          <td class="break-all px-1">
            <div class="flex gap-2 justify-end items-center">
              <i class="fas fa-trash cursor-pointer text-red-600 hover:text-red-400" @click="deleteKey(apiKey)" />
              <i class="fas fa-pencil cursor-pointer text-theme-500 hover:text-theme-100" @click="keyEditing = JSON.parse(JSON.stringify(apiKey))" />
            </div>
          </td>
        </tr>
       </tbody>
      </table>
    </div>

    <modal v-if="keyEditing">
      <template v-slot:header>
        <div class="flex justify-between w-full">
          <h1 class="text-2xl">{{ keyEditing.id === null ? 'New API Key' : 'Edit API Key' }}</h1>
        </div>
        <div class="text-sm">To use, add a HTTP Header of <em>x-api-key</em> with the value of the key.</div>
        <div class="text-sm">At least 1 Dataset or 1 Fileset must be selected.</div>
      </template>
      <template v-slot:body>
        <div class="w-full flex flex-col gap-2">
          <div class="flex flex-col w-full">
            <strong>Key Name</strong>
            <input class="form-control" v-model="keyEditing.name" :disabled="keyEditing.id !== null" />
          </div>
          <div class="flex flex-col w-full">
            <strong>Datasets</strong>
            <api-key-dataset-selector v-model="keyEditing.datasets"></api-key-dataset-selector>
          </div>
          <div class="flex flex-col w-full">
            <strong>Filesets</strong>
            <v-select :options="filesetAccess" v-model="keyEditing.filesets" :multiple="true" :append-to-body="true" placeholder="type or select a fileset"></v-select>
          </div>
          <template v-if="keyEditing.id">
            <div class="flex flex-col w-full">
              <strong>Created</strong>
              <relative-time-display :time-ms="keyEditing.createdDateMs"></relative-time-display>
            </div>
            <div class="flex flex-col w-full">
              <strong>Last Used</strong>
              <relative-time-display v-if="keyEditing.lastUsedMs" :time-ms="keyEditing.lastUsedMs"></relative-time-display>
              <div v-else>never</div>
            </div>
            <div class="flex flex-col w-full break-all">
              <strong>Key <i class="ml-1 fas fa-copy cursor-pointer text-theme-500 hover:text-theme-300" title="Copy Key" @click="copyKey(keyEditing)" /></strong>
              <div>{{ keyEditing.key }}</div>
            </div>
          </template>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme-muted" @click="keyEditing = null"><i class="fas fa-xmark mr-1" />Close</button>
        <button class="btn btn-theme" @click="saveKeyEditing" :disabled="!isEditingKeyValid"><i class="fas fa-save mr-1" />Save</button>
      </template>
    </modal>

    <modal v-if="showHelp">
      <template v-slot:header>
        <div class="flex justify-between w-full">
          <h1 class="text-2xl">API Key Help</h1>
        </div>
      </template>
      <template v-slot:body>
        <div class="w-full flex flex-col">
          <div class="text-lg font-bold">What are API Keys</div>
          <div>Api Keys allow a user to create unique keys that only have access to a subset of resources that the user selects. When using API Keys, it is not necessary to use <em>Basic Authorization</em> or <em>JWT Cookies</em> on your service calls.</div>
          <div class="text-lg font-bold mt-2">How to Use</div>
          <div class="render-list">
            <ol>
              <li>Create an API Key</li>
              <li>Copy the key by clicking on <i class="fas fa-copy text-theme-500" /></li>
              <li>Add a HTTP Header of <em>x-api-key</em> with the value of the key you copied above</li>
            </ol>
          </div>
          <div class="text-lg font-bold mt-2">Note</div>
          <div>API Keys are associated with your account and will no longer function after your account has been terminated.</div>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme" @click="showHelp = false">Close</button>
      </template>
    </modal>
  </div>
</template>

<script>
import ApiKeyResource from '@/components/ApiKeyResource'
import ApiKeyDatasetSelector from '@/components/ApiKeyDatasetSelector'
import FullScreenLoadingMixin from '@/mixins/FullScreenLoadingMixin'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import RelativeTimeDisplay from '@/components/RelativeTimeDisplay'
import RetryableError from '@/components/RetryableError'
import _ from 'lodash'

export default {
  name: 'api-keys',
  mixins: [
    FullScreenLoadingMixin
  ],
  components: {
    ApiKeyResource,
    ApiKeyDatasetSelector,
    Loading,
    Modal,
    RelativeTimeDisplay,
    RetryableError
  },
  data () {
    return {
      apiKeys: null,
      datasetAccess: null,
      filesetAccess: null,
      hasError: false,
      showHelp: false,
      keyEditing: null,
      resources: [
        {
          type: 'Dataset',
          hasAccess: (dataset) => this.hasDatasetAccess(dataset),
          getResourceName: (dataset) => dataset.datasetId,
          getItems: (apiKey) => this.sortDatasets(apiKey.datasets)
        }, {
          type: 'Fileset',
          hasAccess: (filesetId) => this.hasFilesetAccess(filesetId),
          getResourceName: (filesetId) => filesetId,
          getItems: (apiKey) => _.sortBy(apiKey.filesets)
        }
      ]
    }
  },
  computed: {
    isEditingKeyValid () {
      if (this.keyEditing === null) return false
      if (this.keyEditing.name.trim().length === 0) return false
      if (this.keyEditing.datasets.length === 0 && this.keyEditing.filesets.length === 0) return false

      if (this.keyEditing.id === null) {
        // ensure the name is unique
        if (this.apiKeys.filter(apiKey => apiKey.name.trim().toLowerCase() === this.keyEditing.name.trim().toLowerCase()).length > 0) return false
      }

      return true
    }
  },
  methods: {
    sortDatasets (datasets) {
      return _.sortBy(datasets, 'datasetId')
    },
    async saveKeyEditing () {
      if (this.keyEditing === null) return
      if (this.isEditingKeyValid === false) return

      const payload = {
        name: this.keyEditing.name,
        datasets: this.keyEditing.datasets,
        filesetIds: this.keyEditing.filesets
      }

      try {
        const dispatchMethod = this.keyEditing.id === null ? 'saveApiKey' : 'updateApiKey'

        this.showLoading('Saving API Key', null)
        await this.$store.dispatch(dispatchMethod, payload)
        this.fetchApiKeys()

        this.keyEditing = null
        this.$swal.fire({
          icon: 'success',
          title: 'API Key Saved',
          text: 'The API Key was successfully saved.',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      } catch (error) {
        console.error('Error saving api key', error)
        this.hideLoading()
        this.$swal.fire({
          icon: 'error',
          title: 'API Key Save Error',
          text: 'An error occurred while saving the API Key.',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
    },
    startCreating () {
      this.keyEditing = {
        name: '',
        datasets: [],
        filesets: [],
        key: null,
        createdDateMs: null,
        lastUsedMs: null,
        id: null
      }
    },
    hasDatasetAccess (apiKeyDataset) {
      for (const category of this.datasetAccess) {
        if (category.id === apiKeyDataset.datasetCategory) {
          return _.some(category.datasets, ['id', apiKeyDataset.datasetId])
        }
      }

      return false
    },
    hasFilesetAccess (filesetId) {
      return this.filesetAccess.includes(filesetId)
    },
    async fetchApiKeys () {
      this.apiKeys = null
      this.datasetAccess = null
      this.filesetAccess = null
      this.hasError = false

      try {
        const datasetsPromise = this.$store.dispatch('fetchUnifiedDatasets')
        const filesetsPromise = this.$store.dispatch('fetchFilesets')
        const responses = await Promise.all([datasetsPromise, filesetsPromise])

        this.datasetAccess = responses[0].data
        this.filesetAccess = responses[1].data.filesets.map(f => f.id)

        const response = await this.$store.dispatch('fetchApiKeys')
        const keys = _.sortBy(response.data, 'createdDateMs')
        keys.forEach(key => {
          key.filesets = key.filesets.map(f => f.filesetId)
        })
        this.apiKeys = keys
      } catch (error) {
        console.error('Error fetching API Keys', error)
        this.hasError = true
      }
    },
    copyKey (apiKey) {
      navigator.clipboard.writeText(apiKey.key).then(() => {
        this.$swal({
          icon: 'success',
          title: 'API Key Copied!',
          timer: 2000,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false
        })
      }).catch(error => {
        this.$swal({
          icon: 'error',
          title: 'Copy Failed',
          html: `<div>Unable to copy the API Key command, manually copy the key below:<br /><br /></div><div class="p-2 rounded bg-black text-white text-left">${apiKey.key}</div>`,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        console.error('Error copying curl command', error)
      })
    },
    async deleteKey (apiKey) {
      const result = await this.$swal({
        icon: 'question',
        title: 'Delete API Key?',
        text: `Are you sure you want to delete the API Key "${apiKey.name}"? This action cannot be undone.`,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Do not Delete',
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      if (!result.isConfirmed) return

      try {
        await this.$store.dispatch('deleteApiKey', apiKey.name)

        this.apiKeys = this.apiKeys.filter(ak => ak.name !== apiKey.name)
        this.$swal({
          icon: 'success',
          title: 'API Key Deleted!',
          text: `${apiKey.name} was succcessfully deleted.`,
          timer: 2000,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false
        })
      } catch (error) {
        console.error('Error deleting API Key', error)
        this.$swal({
          icon: 'error',
          title: 'Delete Failed',
          text: `"${apiKey.name}" was not deleted. Please try again.`
        })
      }
    }
  },
  mounted () {
    this.fetchApiKeys()
  }
}
</script>
