<template>
  <div :title="readableAbsoluteTime">
    {{ relativeTimeStamp }}
  </div>
</template>

<script>
import RelativeTimeMixin from '@/mixins/RelativeTimeMixin'

export default {
  name: 'relative-time-display',
  mixins: [
    RelativeTimeMixin
  ],
  props: {
    timeMs: { type: Number, required: true }
  },
  watch: {
    timeMs () {
      this.timestamp = this.timeMs
    }
  },
  mounted () {
    this.timestamp = this.timeMs
  }
}
</script>
