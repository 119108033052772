<script>
export default {
  name: 'cac-mixin',
  computed: {
    isCacEnabled () {
      return typeof this.$store.state.geoAxis !== 'undefined' && this.$store.state.geoAxis !== null
    },
    cacCode () {
      return this.$route.query.code
    },
    geoAxisCodeEndpoint () {
      if (!this.isCacEnabled) return null

      const params = {
        client_id: this.$store.state.geoAxis.clientId,
        redirect_uri: window.location.href,
        response_type: 'code',
        domain: 'DefaultDomain',
        scope: 'UserProfile.me'
      }
      return `${this.$store.state.geoAxis.authUrl}?${this.encodeQueryData(params)}`
    }
  },
  methods: {
    encodeQueryData (data) {
      const ret = []
      for (const key in data) {
        ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      }
      return ret.join('&')
    }
  }
}
</script>
