<template>
  <div :class="['flex flex-col items-center', { 'h-screen': !isHeightConstrained }]">
    <retryable-error v-if="hasFilesetError" text="Error fetching filesets." @retry="fetchFileSets"></retryable-error>
    <loading v-else-if="filesets === null" text="Loading..." class="mt-3"></loading>
    <div v-else-if="filesets.length === 0" class="flex justify-center h-full text-gray-600 items-center">
      <i class="fas fa-folder mr-2"></i>
      No Filesets Available
    </div>
    <template v-else>

      <!-- title bar -->
      <div v-if="!hideNavBars" class="flex justify-between w-full bg-gray-500 text-white items-center max-h-10 h-10 sticky top-0">
        <div class="flex ml-1 text-xs">
          <!-- view options -->
          <span class="mx-2 text-xl">
            <i :class="['fas fa-th cursor-pointer mr-2 hover:text-theme-200', {'text-theme-200': viewMode === 'grid'}]" @click="setViewMode('grid')" />
            <i :class="['fas fa-list cursor-pointer hover:text-theme-200', {'text-theme-200': viewMode === 'list'}]" @click="setViewMode('list')" />
          </span>
        </div>
        <h1 v-if="currentPathHierarchy.length > 0" class="font-bold text-2xl">{{currentPathHierarchy[currentPathHierarchy.length - 1]}}</h1>
        <div>
          <!-- filter bar -->
          <i v-if="filterText === null" class="fas fa-search mr-1 cursor-pointer hover:text-theme-200" @click="filterText = ''" />
          <input v-if="filterText !== null" type="text" class="px-1 rounded text-black border-2 focus:outline-none focus:border-theme-200" placeholder="filter directory" v-model="filterText" />
          <i v-if="filterText !== null" class="fas fa-times mx-1 cursor-pointer hover:text-theme-200" @click="clearFilter(false)" />
        </div>
      </div>

      <!-- main content -->
      <div class="flex grow w-full border-r-2 border-l-2 border-theme-400 overflow-hidden">
        <!-- sidebar -->
        <div v-if="!hideNavBars" class="p-2 max-w-1/4 bg-gray-300 flex flex-col font-bold overflow-y-auto h-full">
          <div>
            <div v-for="fileset in filesets" :key="fileset.id" :class="['my-1 rounded p-1', {'bg-gray-100': selectedFileset.id === fileset.id}, {'cursor-not-allowed': isLoadingContent}, {'cursor-pointer': !isLoadingContent}]" @click="selectFileset(fileset)">
              <div class="flex justify-between">
                <span class="mx-1"><i class="fas fa-folder mr-1" />{{fileset.id}}</span>
                <span v-if="fileset.allowsUploads"><i class="fas fa-upload text-theme-400 hover:text-theme-200" @click.stop="setFilesetUpload(fileset)"></i></span>
              </div>
            </div>
          </div>
        </div>

        <!-- folder content -->
        <div class="flex justify-center max-w-3/4 overflow-y-auto shrink grow">
          <retryable-error v-if="hasContentError" class="self-center" text="Error loading fileset contents." @retry="fetchFilesetContents"></retryable-error>
          <loading v-else-if="contentResults === null" text="Loading..." class="self-center"></loading>
          <div v-else-if="filteredItems.length === 0" class="self-center">no items</div>
          <template v-else-if="viewMode === 'grid'">
            <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-2 cursor-pointer justify-between w-full">
              <div class="flex flex-col items-center text-sm p-2 rounded hover:bg-theme-200 h-16 w-32 max-w-32" v-for="(item, idx) in filteredItems" :key="item.name" :title="item.name">
                <span v-if="item.isDirectory" class="flex flex-col items-center" @click="fetchFilesetContents(item.path)">
                  <i class="fas fa-folder text-2xl" />
                  <span class="truncate w-32 max-w-32 text-center self-center">{{item.name}}</span>
                </span>
                <span v-else>
                  <!-- previewable file -->
                  <span class="flex flex-col items-center" @click="indexOfFilePreviewing = idx">
                    <i class="fas fa-file text-2xl" />
                    <span class="truncate w-32 max-w-32 text-center self-center">{{item.name}}</span>
                  </span>
                </span>
              </div>
            </div>
          </template>
          <div v-else class="flex flex-col w-full">
            <table class="w-full">
              <thead class="w-full text-white sticky top-0 bg-theme-400">
                <tr>
                  <th class="w-1/2 px-1 border-r border-black">Name</th>
                  <th class="w-1/6 px-1 border-r border-black">File Size</th>
                  <th class="w-1/4 px-1 border-r border-black">Last Updated</th>
                  <th class="w-1/12 px-1"></th>
                </tr>
              </thead>
              <tbody class="w-full overflow-y-auto">
                <tr v-for="(item, idx) in filteredItems" :key="item.name" :title="item.name" :class="['py-1 h-16 cursor-pointer w-full hover:bg-theme-200 max-w-full overflow-hidden', {'bg-theme-100': idx % 2 === 0}]">
                  <template v-if="item.isDirectory">
                    <td class="w-1/2 max-w-1/2 break-all px-1 border-r border-black" @click="fetchFilesetContents(item.path)"><i class="fas fa-folder text-xl mr-1" />{{item.name}}</td>
                    <td class="w-1/6 max-w-1/6 break-all px-1 border-r border-black" @click="fetchFilesetContents(item.path)"></td>
                    <td class="w-1/4 max-w-1/4 break-all px-1 border-r border-black" @click="fetchFilesetContents(item.path)"></td>
                    <td class="w-1/12 max-w-1/12 break-all px-1"></td>
                  </template>
                  <template v-else>
                    <td class="w-1/2 max-w-1/2 break-all px-1 border-r border-black" @click="indexOfFilePreviewing = idx"><i class="fas fa-file text-xl mr-1" />{{item.name}}</td>
                    <td class="w-1/6 max-w-1/6 break-all px-1 border-r border-black" @click="indexOfFilePreviewing = idx">{{readableFileSize(item.size)}}</td>
                    <td class="w-1/4 max-w-1/4 break-all px-1 border-r border-black" @click="indexOfFilePreviewing = idx">{{readableDate(item.lastModified)}}</td>
                    <td class="w-1/12 max-w-1/12 break-all px-1 text-center"><a class="text-theme-400 hover:text-white" :href="getFilesetFileDownloadUrl(item)" :download="item.name"><i class="fas fa-download" title="download"></i></a></td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="!hideNavBars" class="flex flex-col sticky bottom-0 w-full">
          <!-- path bar -->
        <template v-if="visiblePathHierarchy.length > 1">
          <div class="flex w-full bg-gray-400 text-white items-center text-sm py-2 h-10 border-r-2 border-l-2 border-theme-400 px-1 overflow-x-auto whitespace-nowrap">
            <div v-for="(path, idx) in visiblePathHierarchy" :key="path">
              <span :class="['underline', {'cursor-not-allowed': isLoadingContent}, {'cursor-pointer hover:text-theme-200': !isLoadingContent}]" @click="loadMidHierarchy(idx)">{{path}}</span><span v-if="idx < (visiblePathHierarchy.length-1)" class="mx-1">></span>
            </div>
          </div>
        </template>

        <!-- status bar -->
        <div class="flex w-full bg-gray-500 text-white items-center justify-center text-sm py-2 h-10 border-b-2 border-r-2 border-l-2 border-theme-400">
          <template v-if="contentResults">
            {{filteredItems.length.toLocaleString()}} item{{ filteredItems.length !== 1 ? 's' : '' }}
            <span v-if="filteredItems.length < (sortedDirectories.length + sortedFiles.length)" class="ml-1">
              ({{ ((sortedDirectories.length + sortedFiles.length) - filteredItems.length).toLocaleString() }} filtered)
            </span>
          </template>
        </div>
      </div>
    </template>

    <fullscreen-file-preview v-if="filePreviewing !== null"
      :filename="filePreviewing.name"
      :file-url="getFilesetFileRelativeUrl(filePreviewing)"
      :file-size="filePreviewing.size"
      :is-traversible="true"
      :has-previous="previousPreviewableIndex !== null"
      :has-next="nextPreviewableIndex !== null"
      :fetch-additional-metadata="getAdditionalMetadata"
      @close-clicked="indexOfFilePreviewing = null"
      @previous-clicked="onPreviousClicked"
      @next-clicked="onNextClicked">
    </fullscreen-file-preview>

<!--    check content results null-->
    <modal v-if="uploadHolder !== null">
      <template v-slot:header>
        <h1 class="text-2xl">Upload to {{ uploadHolder.fileset.id }}</h1>
      </template>
      <template v-slot:body>
        <form v-if="!isUploading">
          <div class="border-dashed border-2 border-theme-500 hover:bg-theme-200 cursor-pointer p-2" @click="$refs.filesetFileInput.click()">
            <input
              type="file"
              class="hidden"
              accept="*"
              id="filesetFileInput"
              name="filesetFileInput"
              ref="filesetFileInput"
              :multiple="true"
              @click="preUpload($event.target)"
              @change="uploadFiles($event.target.files)"
            />
            <div class="flex flex-col cursor-pointer items-center justify-center text-theme-500">
              <i class="fas fa-upload block text-xl mb-1"></i>
              <span class="text-sm">Click to Upload Files</span>
            </div>
          </div>
        </form>
        <div v-if="uploadHolder.files.length > 0" class="flex flex-col gap-1 mt-2">
          <div class="text-theme-500 font-bold"><i class="fas fa-file mr-1"></i>File Upload Status</div>
          <div v-for="f in uploadHolder.files" :key="f.file" class="flex gap-1">
            <div class="w-1/4 max-w-1/4 text-ellipsis overflow-hidden whitespace-nowrap" :title="f.file.name">{{ f.file.name }}</div>
            <div class="w-3/4 max-w-3/4 border rounded-lg">
              <div v-if="f.isFinished === false && f.progress > -1" class="bg-theme-400 h-full rounded-lg text-white text-center" :style="{ width: `${f.progress}%`}">{{ f.progress }}%</div>
              <div v-else-if="f.isFinished === false && f.progress < 0" class="w-full text-center text-theme-500">Preparing...</div>
              <div v-else-if="f.isFinished === true && f.progress !== 100" class="w-full text-center bg-red-700 text-white">Error Uploading</div>
              <div v-else-if="f.isFinished === true && f.progress === 100" class="w-full text-center bg-green-700 text-white">Completed</div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme" href="#" @click="uploadHolder = null" :disabled="isUploading"><i class="fas fa-check" /> Done</button>
      </template>
    </modal>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import { formattedFileSize } from '@/utils/Files'
import Loading from '@/components/Loading'
import RetryableError from '@/components/RetryableError'
import FullscreenFilePreview from '@/components/FullscreenFilePreview'
import Modal from '@/components/Modal'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'fileset-browser',
  components: {
    Loading,
    RetryableError,
    FullscreenFilePreview,
    Modal
  },
  data () {
    return {
      filterText: null,
      filesets: null,
      selectedFileset: null,
      currentPathHierarchy: [],
      contentResults: null,
      hasFilesetError: false,
      hasContentError: false,
      indexOfFilePreviewing: null,
      ignoreFilterUpdate: false,
      uploadHolder: null
    }
  },
  props: {
    lockedFilesetId: { type: String, required: false, default: null },
    lockedFilesetPath: { type: Array, required: false, default: null },
    isHeightConstrained: { type: Boolean, required: false, default: false },
    isUsingBrowserNavigation: { type: Boolean, required: false, default: false },
    hideNavBars: { type: Boolean, required: false, default: false }
  },
  computed: {
    isUploading () {
      if (this.uploadHolder === null) return false
      if (this.uploadHolder.files.length === 0) return false
      return !_.every(this.uploadHolder.files, { isFinished: true })
    },
    isLoadingContent () {
      // if content is null AND we have no error, then we are loading content
      return this.contentResults === null && !this.hasContentError
    },
    previousPreviewableIndex () {
      if (this.indexOfFilePreviewing === null) return null
      if (this.indexOfFilePreviewing === 0) return null

      for (let i = this.indexOfFilePreviewing - 1; i > -1; i--) {
        const item = this.filteredItems[i]
        if (!item.isDirectory) {
          return i
        }
      }
      return null
    },
    nextPreviewableIndex () {
      if (this.indexOfFilePreviewing === null) return null
      if (this.indexOfFilePreviewing === this.filteredItems.length - 1) return null

      for (let i = this.indexOfFilePreviewing + 1; i < this.filteredItems.length; i++) {
        const item = this.filteredItems[i]
        if (!item.isDirectory) {
          return i
        }
      }
      return null
    },
    filePreviewing () {
      if (this.indexOfFilePreviewing === null) return null
      return this.filteredItems[this.indexOfFilePreviewing]
    },
    visiblePathHierarchy () {
      if (this.lockedFilesetId === null || this.lockedFilesetPath === null) { return this.currentPathHierarchy }
      const visiblePathHierarchy = JSON.parse(JSON.stringify(this.currentPathHierarchy))
      for (let i = 0; i < this.lockedFilesetPath.length; i++) { visiblePathHierarchy.shift() }
      return visiblePathHierarchy
    },
    sortedDirectories () {
      if (this.contentResults === null) return null
      return _.sortBy(this.contentResults.commonPrefixes)
    },
    sortedFiles () {
      if (this.contentResults === null) return null
      const filteredFiles = this.contentResults.files.filter(file => {
        return file.filename !== '' && !file.filename.endsWith('/')
      })
      return _.sortBy(filteredFiles, 'filename')
    },
    filteredItems () {
      const directories = this.sortedDirectories.map(directory => {
        let name = directory
        if (name.endsWith('/')) name = name.slice(0, -1)
        const parts = name.split('/')
        return { name: parts[parts.length - 1], isDirectory: true, path: parts, size: 0, lastModified: 0 }
      })
      const files = this.sortedFiles.map(file => {
        const name = file.filename
        const parts = name.split('/')
        return { name: parts[parts.length - 1], isDirectory: false, path: parts, size: file.size, lastModified: file.lastModified }
      })

      const filterText = this.filterText
      return (directories.concat(files)).filter(item => {
        if (filterText === null || filterText.length === 0) return true
        return item.name.toLowerCase().includes(filterText.toLowerCase())
      })
    },
    viewMode () {
      return this.$store.state.filesetViewMode
    }
  },
  watch: {
    uploadHolder (current, previous) {
      if (previous !== null && previous.files.length > 0) {
        // refresh the fileset to show newly uploaded files
        this.fetchFilesetContents()
      }
    },
    filterText () {
      if (this.isUsingBrowserNavigation && !this.ignoreFilterUpdate) {
        const queryParams = JSON.parse(JSON.stringify(this.$route.query))
        if (this.filterText === null) {
          delete queryParams.filter
        } else {
          queryParams.filter = this.filterText
        }
        this.$router.replace({ query: queryParams })
      }
    },
    indexOfFilePreviewing () {
      if (this.isUsingBrowserNavigation) {
        const queryParams = JSON.parse(JSON.stringify(this.$route.query))
        if (this.indexOfFilePreviewing === null) {
          delete queryParams.file
        } else {
          queryParams.file = this.filePreviewing.name
        }
        this.$router.push({ query: queryParams })
      }
    }
  },
  methods: {
    setFilesetUpload (fileset) {
      this.uploadHolder = {
        fileset,
        files: []
      }
    },
    preUpload (input) {
      // handle edge case where user uploads same file path with different contents
      input.value = null
    },
    uploadFiles (files) {
      for (const file of files) {
        this.uploadHolder.files.push({
          file,
          progress: -1,
          isFinished: false
        })
        this.$store.dispatch('filesetPresignedUrlUpload', { fileset: this.uploadHolder.fileset, file: file.name }).then(response => {
          this.$store.dispatch('uploadFile', {
            url: response.data.uploadUrl,
            file,
            progressDelegate: (percentComplete) => {
              const fileContainer = this.uploadHolder.files.find(f => f.file === file)
              fileContainer.progress = percentComplete
              if (percentComplete === 100) {
                fileContainer.isFinished = true
              }
            }
          }).catch(error => {
            console.error('Error uploadling file.', file, error)
            const fileContainer = this.uploadHolder.files.find(f => f.file === file)
            fileContainer.isFinished = true
          })
        }).catch(error => {
          console.error('Error getting an upload url.', file, error)
          const fileContainer = this.uploadHolder.files.find(f => f.file === file)
          fileContainer.isFinished = true
        })
      }
    },
    setViewMode (viewMode) {
      this.$store.commit('setFilesetViewMode', viewMode)
    },
    onPreviousClicked () {
      this.indexOfFilePreviewing = this.previousPreviewableIndex
    },
    onNextClicked () {
      this.indexOfFilePreviewing = this.nextPreviewableIndex
    },
    async getAdditionalMetadata () {
      if (this.filePreviewing === null) return
      const file = this.filePreviewing

      const metadata = [
        {
          title: 'Last Updated',
          icon: 'fa-clock',
          value: this.readableDate(file.lastModified)
        }
      ]

      const response = await this.$store.dispatch('fetchTagsForFile', { filesetId: this.selectedFileset.id, filename: file.path.join('/') })
      const tags = response.data.tags
      for (const tagKey of Object.keys(tags)) {
        metadata.push({
          title: tagKey,
          icon: 'fa-tag',
          value: tags[tagKey]
        })
      }

      return metadata
    },
    getFilesetFileRelativeUrl (file) {
      if (!this.selectedFileset) return ''
      return `/filesets/${encodeURIComponent(this.selectedFileset.id)}/download?file=${encodeURIComponent(file.path.join('/'))}`
    },
    getFilesetFileDownloadUrl (file) {
      if (!this.selectedFileset) return ''
      const gatewayApiLocation = this.$store.state.gatewayApiLocation
      return gatewayApiLocation + this.getFilesetFileRelativeUrl(file)
    },
    async selectFileset (fileset, path = null) {
      // only stop switching once we have something selected
      if (this.selectedFileset !== null && this.isLoadingContent) return
      if (this.lockedFilesetId !== null && this.lockedFilesetPath !== null) {
        path = this.lockedFilesetPath
      }
      this.selectedFileset = fileset
      await this.fetchFilesetContents(path)
    },
    readableDate (timestamp) {
      return moment(timestamp).format('MM-DD-YYYY, h:mm:ss a')
    },
    readableFileSize (bytes) {
      return formattedFileSize(bytes)
    },
    clearFilter (isSilent) {
      if (!isSilent) {
        this.filterText = null
        return
      }

      this.ignoreFilterUpdate = isSilent
      const self = this
      nextTick(() => {
        self.filterText = null
        nextTick(() => {
          self.ignoreFilterUpdate = false
        })
      })
    },
    loadMidHierarchy (index) {
      // only stop switching once we have something selected
      if (this.selectedFileset !== null && this.contentResults === null) return
      let actualIndex = index
      if (this.lockedFilesetId && this.lockedFilesetPath) {
        // we need to add to the index based on what was locked
        actualIndex += this.lockedFilesetPath.length
      }

      if (actualIndex === 0) {
        this.fetchFilesetContents()
      } else {
        this.fetchFilesetContents(this.currentPathHierarchy.slice(1, actualIndex + 1))
      }
    },
    fetchFileSets: async function () {
      this.hasFilesetError = false
      this.hasContentError = false

      this.filesets = null
      this.contentResults = null

      try {
        const self = this
        const response = await this.$store.dispatch('fetchFilesets')
        this.filesets = _.sortBy(response.data.filesets, 'id').filter(fileset => {
          if (!self.lockedFilesetId) { return fileset.id !== 'unacorn-help' && fileset.id !== 'jupyter_notebooks' }
          return fileset.id === self.lockedFilesetId
        })

        if (this.filesets.length === 0) {
          return
        }

        if (this.isUsingBrowserNavigation) {
          await this.selectFilesetFromQueryParams()
        } else {
          await this.selectFileset(this.filesets[0])
        }
      } catch (error) {
        console.error('Error fetching filesets', error)
        this.hasFilesetError = true
      }
    },
    fetchFilesetContents: async function (path = null) {
      this.hasFilesetError = false
      this.hasContentError = false

      this.indexOfFilePreviewing = null
      this.contentResults = null
      this.clearFilter(true)
      if (this.selectedFileset === null) return

      if (path === null) {
        this.currentPathHierarchy = [this.selectedFileset.id]
      } else {
        this.currentPathHierarchy = [this.selectedFileset.id].concat(JSON.parse(JSON.stringify(path)))
      }

      try {
        const combinedResponse = { files: [], commonPrefixes: [] }
        const fileset = this.selectedFileset
        let listPrefix = null
        if (path !== null) {
          listPrefix = path.join('/') + '/'
        }
        let continuationToken = null

        do {
          const response = await this.$store.dispatch('fetchFilesetContents', { fileset, continuationToken, listPrefix })
          combinedResponse.files = combinedResponse.files.concat(response.data.files)
          combinedResponse.commonPrefixes = combinedResponse.commonPrefixes.concat(response.data.commonPrefixes)
          continuationToken = response.data.nextContinuationToken
        } while (continuationToken !== null)

        this.contentResults = combinedResponse

        if (this.isUsingBrowserNavigation) {
          const queryParams = { fileset: this.selectedFileset.id }
          if (path !== null && path.length > 0) {
            queryParams.path = path.join('/')
          }

          if (Object.keys(this.$route.query).length === 0) {
            // first load, do a replace so we don't add extra back stack context
            this.$router.replace({ query: queryParams })
          } else {
            this.$router.push({ query: queryParams })
          }
        }
      } catch (error) {
        console.error('Error fetching fileset contents', error)
        this.hasContentError = true
      }
    },
    async selectFilesetFromQueryParams () {
      const params = JSON.parse(JSON.stringify(this.$route.query))
      const fileset = this.filesets.find(f => f.id === params.fileset)
      if (typeof (fileset) === 'undefined') {
        // can't find desired fileset, select the first one
        await this.selectFileset(this.filesets[0])
        return
      }

      let path = null
      if (typeof (params.path) !== 'undefined' && params.path !== null) {
        path = params.path.split('/')
      }
      await this.selectFileset(fileset, path)

      if (typeof (params.filter) !== 'undefined') {
        this.filterText = params.filter
      }

      if (typeof (params.file) !== 'undefined' && params.file !== null) {
        const filePreviewIndex = this.filteredItems.findIndex(i => i.name === params.file)
        if (filePreviewIndex !== -1) {
          this.indexOfFilePreviewing = filePreviewIndex
        }
      }
    }
  },
  mounted () {
    this.fetchFileSets()

    if (this.isUsingBrowserNavigation) {
      window.addEventListener('popstate', this.selectFilesetFromQueryParams)
    }
  },
  unmounted () {
    if (this.isUsingBrowserNavigation) {
      window.removeEventListener('popstate', this.selectFilesetFromQueryParams)
    }
  }
}
</script>
