<template>
  <div>
    <entity-editor
      ref="noticeEntityEditor"
      id-column="id"
      entity-type-display="Information Notice"
      entity-type="notices"
      :filter-columns="['title']"
      :disabled-actions="['disable']"
      :columns="noticeColumns"
      :custom-editor="true"
      :export-normalizer="normalizeNotices"
      @new-entity="onNewEntity"
      @copy-entity="onCopyEntity"
      @edit-entity="onEditEntity">
    </entity-editor>

    <modal v-if="noticeEditing" large-width-class="lg:w-4/5">
      <template v-slot:header>
        <h1 class="text-2xl">{{ noticeEditing.id === null ? 'Create' : 'Edit' }} Notice</h1>
      </template>
      <template v-slot:body>
        <notice-editor v-model="noticeEditing"></notice-editor>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme-muted" href="#" @click="noticeEditing = null"><i class="fas fa-ban" /> Cancel</button>
        <button class="btn btn-theme" :disabled="!isNoticeValid" @click="saveNotice"><i class="fas fa-save" /> Save</button>
      </template>
    </modal>
  </div>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'
import FullScreenLoadingMixin from '@/mixins/FullScreenLoadingMixin'
import Modal from '@/components/Modal'
import NoticeEditor from '@/components/admin/NoticeEditor'

export default {
  name: 'notice-settings',
  mixins: [
    FullScreenLoadingMixin
  ],
  components: {
    EntityEditor,
    Modal,
    NoticeEditor
  },
  data () {
    return {
      noticeEditing: null,
      noticeColumns: [
        { field: 'title', display: 'Title' },
        { field: 'message', display: 'Message' }
      ]
    }
  },
  computed: {
    isNoticeValid () {
      if (this.noticeEditing === null) return false
      if (!this.hasStringValue(this.noticeEditing.faIcon)) return false
      if (!this.hasStringValue(this.noticeEditing.title)) return false
      if (!this.hasStringValue(this.noticeEditing.message)) return false

      return true
    }
  },
  methods: {
    normalizeNotices (notices) {
      const clonedNotices = JSON.parse(JSON.stringify(notices))
      return clonedNotices.map(notice => {
        notice.id = null
        delete notice.createdDateMs
        notice.roles = notice.roles.map(role => role.roleName)
        return notice
      })
    },
    hasStringValue (str) {
      if (str === null) return false
      if (str.trim().length === 0) return false
      return true
    },
    async saveNotice () {
      try {
        this.showLoading('Saving Notice', null)
        const saveAction = this.noticeEditing.id === null ? 'saveAdminEntityNew' : 'saveAdminEntityEdit'
        await this.$store.dispatch(saveAction, { type: 'notices', entity: this.noticeEditing })

        this.$swal.fire({
          icon: 'success',
          title: 'Notice Saved',
          text: 'The notice was successfully saved.',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        this.noticeEditing = null
        this.$refs.noticeEntityEditor.loadEntities()
      } catch (error) {
        console.error('Error saving notice', error)
        const serverErrors = error?.response?.data?._embedded?.errors?.map(e => e.message) ?? []
        this.$swal.fire({
          icon: 'error',
          title: 'Notice Save Failed',
          text: `Error saving the notice.\n\n${serverErrors.join('/n/n')}\n\nCheck the browser's console for errors.`,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
    },
    onNewEntity () {
      this.noticeEditing = {
        id: null,
        faIcon: '',
        title: '',
        message: '',
        roles: []
      }
    },
    onCopyEntity (noticeToCopy) {
      this.noticeEditing = JSON.parse(JSON.stringify(noticeToCopy))
      this.noticeEditing.roles = this.noticeEditing.roles.map(role => role.roleName)
      this.noticeEditing.id = null
    },
    onEditEntity (noticeToEdit) {
      this.noticeEditing = JSON.parse(JSON.stringify(noticeToEdit))
      this.noticeEditing.roles = this.noticeEditing.roles.map(role => role.roleName)
    }
  }
}
</script>
