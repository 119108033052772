<template>
  <div class="flex flex-col">
    <div v-if="logs !== null" class="overflow-auto h-full">
      <div class="flex justify-between items-end m-2 sticky left-2">
        <span class="text-2xl text-theme-500 font-bold">Auditing Events ({{ this.totalLogs.toLocaleString() }})</span>
        <div class="flex flex-col lg:flex-row gap-1">
          <v-select v-if="usernames.length !== 0" class="min-w-48" :options="usernames" v-model="selectedUsername" placeholder="filter by username" :append-to-body="true"></v-select>
          <v-select v-if="responseCodes.length !== 0" class="min-w-48" :options="responseCodes" v-model="selectedResponseCode" placeholder="filter by response code" :append-to-body="true"></v-select>
        </div>
      </div>
      <table class="w-full break-words">
        <thead>
          <tr class="sticky top-0 bg-theme-400">
            <template v-for="col in columns" :key="col.name">
              <th class="text-white px-4 max-w-md" :title="col.description">
                {{ col.display }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, resultIdx) in logs" :key="log.id" :class="[{ 'bg-white': resultIdx % 2 === 0, 'bg-theme-100': resultIdx % 2 !== 0 }]">
            <template v-for="col in columns" :key="col.name">
              <td class="px-4 max-w-md text-center" @click="() => col.onClick ? col.onClick(log) : undefined">
                <span v-if="col.htmlRenderer" v-html="col.htmlRenderer(log)"></span>
                <span v-else>
                  {{ col.renderer ? col.renderer(log) : (log[col.name] ?? '-') }}
                </span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <template v-if="!isLoading">
        <div v-if="logs.length === 0" class="w-full text-center text-gray-700">no audit logs</div>
        <template v-else>
          <button v-if="hasMorePages && !hasError" class="btn btn-theme-muted w-full border-dashed mt-3 bg-transparent sticky left-0" @click="fetchLogs">Load More...</button>
          <div v-if="!hasMorePages" class="text-sm mt-3 w-full text-center sticky left-0">no more audit logs to load</div>
        </template>
      </template>
    </div>
    <loading v-if="isLoading" class="sticky left-0" text="Loading logs..."></loading>
    <retryable-error v-else-if="hasError" text="Failed to load audit logs" @retry="fetchLogs"></retryable-error>

    <modal v-if="headersLog !== null" large-width-class="lg:w-3/4">
      <template v-slot:header>
        <h1 class="text-2xl">Details</h1>
        <div class="flex flex-col">
          <div class="flex justify-between items-center gap-4 text-sm">
            <div class="flex flex-col">
              <span>{{ headersLog.method }}</span>
              <span>{{ headersLog.path }}</span>
            </div>
            <div class="flex flex-col items-end">
              <span v-if="headersLog.username">{{ headersLog.username }}</span>
              <span>{{ formatTime(headersLog.requestTimeMs) }}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div>{{ headersLog.details }}</div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme" @click="headersLog = null">Close</button>
      </template>
    </modal>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import RetryableError from '@/components/RetryableError'

export default {
  name: 'auditing',
  components: {
    RetryableError,
    Loading,
    Modal
  },
  data () {
    return {
      isLoading: false,
      logs: null,
      currentPage: -1,
      hasMorePages: false,
      totalLogs: 0,
      hasError: false,
      columns: [
        { name: 'responseCode', display: 'Response Code', htmlRenderer: (log) => { return `<div class="flex flex-col items-center"><i class="fas fa-circle ${log.responseCode >= 200 && log.responseCode < 300 ? 'text-emerald-600' : 'text-rose-600'}"></i><span>${log.responseCode}</span>` } },
        { name: 'path', display: 'Endpoint', htmlRenderer: (log) => { return `<div class="flex w-full text-left items-center gap-2"><span>${log.method}</span><span>${log.path}</span></div>` } },
        { name: 'username', display: 'Username' },
        { name: 'requestTimeMs', display: 'Time', renderer: (log) => { return this.formatTime(log.requestTimeMs) } },
        { name: 'ipAddress', display: 'IP Address' },
        { name: 'browser', display: 'Browser', renderer: (log) => { return log.client?.browser ?? '-' } },
        { name: 'os', display: 'OS', renderer: (log) => { return log.client?.operatingSystem ?? '-' } },
        { name: 'device', display: 'Device', renderer: (log) => { return log.client?.device ?? '-' } },
        { name: 'details', display: 'Details', htmlRenderer: (log) => { return '<div class="text-theme-500 cursor-pointer hover:text-theme-300"><i class="fas fa-circle-info"></i></div>' }, onClick: (log) => { this.headersLog = log } }
      ],
      headersLog: null,
      usernames: [],
      selectedUsername: null,
      responseCodes: [],
      selectedResponseCode: null
    }
  },
  computed: {
    eventParams () {
      return {
        pageNumber: this.currentPage + 1,
        username: this.selectedUsername?.label ?? null,
        responseCode: this.selectedResponseCode?.label ?? null
      }
    }
  },
  watch: {
    selectedUsername () {
      console.log('username updated')
      this.filtersUpdated()
    },
    selectedResponseCode () {
      this.filtersUpdated()
    }
  },
  methods: {
    filtersUpdated () {
      this.totalLogs = 0
      this.logs = []
      this.currentPage = -1
      this.fetchLogs()
    },
    async fetchLogs () {
      if (this.isLoading) return

      this.isLoading = true
      this.hasError = false
      try {
        const response = await this.$store.dispatch('fetchAuditEvents', this.eventParams)
        const logData = response.data

        if (this.logs === null) this.logs = []
        this.logs = this.logs.concat(logData.content)
        this.currentPage = logData.pageable.pageNumber
        this.hasMorePages = !logData.last
        this.totalLogs = logData.totalElements
      } catch (error) {
        console.error('Error fetching auditing logs', error)
        this.hasError = true
      } finally {
        this.isLoading = false
      }
    },
    async fetchUsernames () {
      try {
        const response = await this.$store.dispatch('fetchAuditUsernames')
        this.usernames = response.data.filter(username => username !== null).map(username => {
          return {
            id: uuidv4(),
            label: username
          }
        })
      } catch (error) {
        console.error('Error loading audit usernames', error)
      }
    },
    async fetchResponseCodes () {
      try {
        const response = await this.$store.dispatch('fetchAuditResponseCodes')
        this.responseCodes = response.data.map(code => {
          return {
            id: uuidv4(),
            label: code
          }
        })
      } catch (error) {
        console.error('Error loading audit response codes', error)
      }
    },
    formatTime (timeMs) {
      return moment(timeMs).format('MM-DD-yyyy h:mm:ss A')
    }
  },
  mounted () {
    this.fetchLogs()
    this.fetchUsernames()
    this.fetchResponseCodes()
  }
}
</script>
