<template>
  <modal large-width-class="lg:w-4/5">
    <template v-slot:header>
      <h1 class="text-5xl font-bold">{{ currentNotice?.title }}</h1>
      <h3 class="text-lg">{{ formatTime(currentNotice?.createdDateMs ?? null) }}</h3>
    </template>
    <template v-slot:body>
      <div class="flex flex-col items-center gap-2">
        <i :class="['text-7xl fa', currentNotice?.faIcon]" />
        <div class="whitespace-pre-wrap">{{ currentNotice?.message }}</div>
      </div>
    </template>
    <template v-slot:footer>
      <div v-if="notices.length > 1" class="flex flex-col justify-center items-center w-full">
        <div class="flex items-center justify-center gap-3">
          <button class="btn btn-theme btn-sm" :disabled="!hasPrevious" @click="index -= 1"><i class="fas fa-chevron-left" /></button>
          <button class="btn btn-theme btn-sm" :disabled="!hasNext" @click="index += 1"><i class="fas fa-chevron-right" /></button>
        </div>
        <div class="text-sm">{{ (index + 1).toLocaleString() }} of {{ notices.length.toLocaleString() }}</div>
      </div>
      <button v-if="canClose" class="btn btn-theme w-full" @click="onClose">Close</button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import moment from 'moment'

export default {
  name: 'information-notice',
  components: {
    Modal
  },
  emits: ['close-notice'],
  props: {
    notices: { type: Array, required: true }
  },
  data () {
    return {
      index: 0,
      canClose: false
    }
  },
  watch: {
    index () {
      if (this.canClose) return
      if (!this.hasNext) this.canClose = true
    },
    notices () {
      if (this.notices.length === 1) this.canClose = true
      this.canClose = false
    }
  },
  computed: {
    currentNotice () {
      if (this.notices.length === 0) return null
      if (this.index >= this.notices.length) return null
      return this.notices[this.index]
    },
    hasPrevious () {
      return this.index > 0
    },
    hasNext () {
      return this.index + 1 < this.notices.length
    }
  },
  methods: {
    formatTime (timeMs) {
      if (timeMs === null) return ''
      return moment(timeMs).format('MM-DD-YYYY, h:mm:ss a')
    },
    onClose () {
      this.$emit('close-notice')
    }
  },
  mounted () {
    if (this.notices.length === 1) this.canClose = true
  }
}
</script>
