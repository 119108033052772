<template>
  <div class="flex flex-col items-center bg-gray-300 min-h-screen pb-10">
    <div class="flex text-2xl font-extrabold items-center justify-center gap-2 sticky top-0 bg-theme-500 text-white p-2 w-full mb-10">
      <i class="fas fa-bell"/>
      <div>Notices</div>
    </div>

    <loading v-if="isLoading" text="Loading notices..."></loading>
    <retryable-error v-else-if="hasError" text="An error occurred while loading notices." @retry="fetchNotices"></retryable-error>
    <div v-else class="flex flex-wrap justify-center items-center gap-2 px-4">
      <div v-for="notice in notices" :key="notice.id" class="border border-black rounded-md flex flex-col bg-white items-center text-3xl max-w-44 max-h-28 w-44 h-28 overflow-hidden cursor-pointer hover:bg-theme-200" @click="noticeViewing = notice">
        <relative-time-display class="text-xs self-start px-2" :time-ms="notice.createdDateMs"></relative-time-display>
        <i :class="['my-2 fa', notice.faIcon]" />
        <div class="text-xl font-bold w-full px-1 text-center">{{ notice.title }}</div>
        <div class="text-sm px-1 w-full max-w-44 h-full truncate">{{ notice.message }}</div>
      </div>
    </div>
    <div v-if="notices !== null && notices.length === 0">no notices exist</div>
    <information-notice v-if="noticeViewing" :notices="[noticeViewing]" @close-notice="noticeViewing = null"></information-notice>
  </div>
</template>

<script>
import InformationNotice from '@/components/InformationNotice'
import Loading from '@/components/Loading'
import RelativeTimeDisplay from '@/components/RelativeTimeDisplay'
import RetryableError from '@/components/RetryableError'

export default {
  name: 'notices',
  components: {
    InformationNotice,
    Loading,
    RelativeTimeDisplay,
    RetryableError
  },
  data () {
    return {
      notices: null,
      isLoading: false,
      hasError: false,
      noticeViewing: null
    }
  },
  methods: {
    async fetchNotices () {
      if (this.isLoading) return

      this.notices = null
      this.isLoading = true
      this.hasError = false

      try {
        const response = await this.$store.dispatch('fetchAllNotices')
        this.notices = response.data
      } catch (error) {
        this.hasError = true
        console.error('Error loading notices', error)
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted () {
    this.fetchNotices()
  }
}
</script>
