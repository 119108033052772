<template>
  <div>
    <div class="flex flex-wrap px-4 justify-center">
      <div v-if="tutorialVideos === null">
        <loading text="Loading videos..." class="mt-3"></loading>
      </div>
      <div v-else-if="tutorialVideos.length === 0">
        no tutorial videos available
      </div>
      <div v-for="video in tutorialVideos" :key="video.id" class="max-w-68 w-68 m-4 flex flex-col cursor-pointer border-2 rounded border-theme-500 items-center" @click="videoSelected(video)">
        <img :src="video.previewUrl" class="w-68" />
        <div class="flex pt-2 items-center bg-theme-300 w-full h-full px-2">
          <i class="fas fa-play text-2xl mr-2"></i>
          <div class="flex flex-col items-start w-full h-full justify-center">
            <div class="text-xl font-bold">{{video.name}}</div>
            <div class="text-sm">{{video.description}}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedVideo !== null" class="flex flex-col items-center justify-center h-screen w-screen bg-black/80 fixed text-white top-0 left-0 z-100">
      <div class="flex justify-between items-center fixed top-0 bg-black text-center w-full h-10 text-xl">
        <span></span>
        <span>{{selectedVideo.name}}</span>
        <span class="flex items-center mr-3">
          <i class="fas fa-times ml-3 cursor-pointer hover:text-theme-200" title="close" @click="selectedVideo = null"></i>
        </span>
      </div>
      <video v-if="selectedVideo.url !== null" class="w-5/6" controls>
        <source :src="selectedVideo.url" type="video/mp4">
        Videos are not supported on your browser.
      </video>
      <loading v-else text="Loading..." class="mt-3"></loading>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import _ from 'lodash'

export default {
  name: 'tutorial-videos',
  components: {
    Loading
  },
  data () {
    return {
      selectedVideo: null,
      tutorialVideos: null
    }
  },
  computed: {
    presignableDocs () {
      return this.$store.state.presignableDocs
    }
  },
  watch: {
    presignableDocs () {
      this.setTutorialVideos()
    }
  },
  methods: {
    videoSelected: async function (video) {
      const v = JSON.parse(JSON.stringify(video))
      v.url = null
      this.selectedVideo = v

      const payload = {
        docId: video.id,
        type: 'object'
      }
      const url = await this.$store.dispatch('getPresignedUrl', payload)
      this.selectedVideo.url = url.data
    },
    async loadPreviewUrl (videoId) {
      const payload = {
        docId: videoId,
        type: 'preview'
      }
      const response = await this.$store.dispatch('getPresignedUrl', payload)
      return response.data
    },
    async setTutorialVideos () {
      if (this.presignableDocs === null) {
        this.tutorialVideos = null
        return
      }

      const videoDocs = JSON.parse(JSON.stringify(_.sortBy(this.presignableDocs.filter(doc => {
        return doc.category === 'video/mp4'
      }), 'name')))

      for (const video of videoDocs) {
        video.previewUrl = await this.loadPreviewUrl(video.id)
      }

      this.tutorialVideos = videoDocs
    }
  },
  mounted () {
    this.setTutorialVideos()
  }
}
</script>
