<template>
  <div class="max-w-4/5 max-h-4/5 overflow-auto bg-white text-black p-1 rounded">
    <span v-if="txtContent" class="whitespace-pre-wrap">{{txtContent}}</span>
  </div>
</template>

<script>

export default {
  name: 'txt-renderer',
  components: {
  },
  props: {
    fileContent: { type: String, required: true }
  },
  emits: ['error-loading', 'finished-loading'],
  data () {
    return {
      txtContent: null
    }
  },
  computed: {
  },
  mounted () {
    this.txtContent = atob(this.fileContent)
    this.$emit('finished-loading')
  }
}
</script>
