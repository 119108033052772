<template>
  <div class="w-full h-screen flex flex-col items-center justify-center gap-2">
    <div class="flex max-sm:flex-col sm:gap-2 text-5xl font-bold items-center justify-center text-theme-500">
      <img class="w-24" src="/resources/logo.png" />
      <div class="sm:mt-3">{{ branding }}</div>
    </div>

    <template v-if="cacCode || readingCac">
      <Loading text="logging in..."></Loading>
    </template>
    <template v-else-if="token === null">
      <span v-if="hasLoginError" class="flex items-center justify-between gap-2 bg-red-300 p-2 rounded">
        <i class="fas fa-warning" />
        <div>Invalid username or password. Please try again.</div>
      </span>

      <div class="input-group max-w-3/5">
        <div class="input-group-text w-42px"><i class="fas fa-user" /></div>
        <input type="text" class="form-control group-text" placeholder="username" v-model="username" @keypress.enter="login">
      </div>

      <div class="input-group max-w-3/5">
        <div class="input-group-text w-42px"><i class="fas fa-lock" /></div>
        <input type="password" class="form-control group-text" placeholder="password" v-model="password" @keypress.enter="login">
      </div>

      <loading v-if="isLoggingIn" text="Logging in..."></loading>
      <template v-else>
        <button class="btn btn-theme max-w-3/5 w-3/5" :disabled="!hasValidCredentials" @click="login"><i class="fas fa-right-to-bracket mr-1"></i>Login</button>
        <a v-if="isCacEnabled" class="btn btn-theme max-w-3/5 w-3/5" :href="geoAxisCodeEndpoint" @click="readingCac = true"><i class="fas fa-id-card mr-1"></i>CAC Login</a>
      </template>
    </template>
    <div v-else class="flex flex-col gap-2 items-center w-full">
      <div class="text-xl font-bold">Welcome, {{ username }}</div>
      <div>Before continuing, you need to update your password.</div>
      <update-password :reset-token="`${token.token_type} ${token.access_token}`" @successful-update="onSuccessfulUpdate"></update-password>
    </div>
  </div>
</template>

<script>
import CacMixin from '@/mixins/CacMixin'
import Loading from '@/components/Loading'
import UpdatePassword from '@/components/UpdatePassword'

export default {
  name: 'login',
  mixins: [
    CacMixin
  ],
  components: {
    Loading,
    UpdatePassword
  },
  data () {
    return {
      hasLoginError: false,
      username: '',
      password: '',
      token: null,
      readingCac: false,
      isLoggingIn: false
    }
  },
  computed: {
    hasValidCredentials () {
      return this.username.length > 0 && this.password.length > 0
    },
    preAuthIntendedRoute () {
      return this.$store.state.preAuthIntendedRoute
    },
    currentUser () {
      return this.$store.state.currentUser
    },
    branding () {
      return this.$store.state.branding
    }
  },
  watch: {
    cacCode () {
      this.attemptCacLogin()
    }
  },
  methods: {
    async attemptCacLogin () {
      if (typeof this.cacCode !== 'undefined' && this.cacCode !== null) {
        try {
          const response = await this.$store.dispatch('cacLogin', this.cacCode)
          this.postLogin(response.data)
        } catch (error) {
          console.error('Error logging in with CAC', error)
          this.$swal({
            icon: 'error',
            title: 'Unable to Login',
            text: 'Unable to login with your CAC. Ensure your CAC is linked to your account or use your password.'
          })
          this.$router.replace({ name: 'Login' })
        }
      }
    },
    postLogin (token) {
      // allow user to move on
      this.$store.commit('setTokenWrapper', token)

      if (this.preAuthIntendedRoute === null) {
        this.$router.replace({ name: 'UnifiedDatasets' })
      } else {
        this.$router.replace(this.preAuthIntendedRoute)
        this.$store.commit('setPreAuthIntendedRoute', null)
      }
    },
    onSuccessfulUpdate () {
      this.postLogin(this.token)
    },
    async login () {
      if (!this.hasValidCredentials) return
      if (this.isLoggingIn) return

      this.isLoggingIn = true
      this.hasLoginError = false
      const payload = {
        username: this.username,
        password: this.password
      }

      try {
        const response = await this.$store.dispatch('login', payload)
        if (response.data.requiresPasswordChange) {
          // require the user to change their password before continuing
          this.token = response.data.token
          return
        }
        this.postLogin(response.data.token)
      } catch (error) {
        this.hasLoginError = true
        console.error('Error logging in', error)
      } finally {
        this.isLoggingIn = false
      }
    }
  },
  mounted () {
    if (this.currentUser !== null) {
      this.$router.replace({ name: 'UnifiedDatasets' })
    } else {
      this.attemptCacLogin()
    }
  }
}
</script>
