<template>
  <entity-editor
    entity-type-display="Fileset"
    entity-type="filesets"
    :new-entity-schema="schemaObj"
    :columns="filesetColumns">
  </entity-editor>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'

export default {
  name: 'fileset-settings',
  components: {
    EntityEditor
  },
  data () {
    return {
      filesetColumns: [
        { field: 'description', display: 'Description' },
        { field: 's3Prefix', display: 'S3 Prefix' },
        { field: 's3Bucket', display: 'S3 Bucket' }
      ],
      schemaObj: {
        id: '',
        description: '',
        s3Bucket: '',
        s3Prefix: '',
        allowedRoles: [{
          roleName: ''
        }],
        enabled: false,
        allowsUploads: false
      }
    }
  }
}
</script>
