<template>
  <div class="max-w-4/5 max-h-4/5 overflow-auto bg-white text-black p-3 rounded">
    <div class="flex flex-col">
      <div v-for="cell in cells" :key="cell.id">
        <Markdown v-if="cell.cell_type === 'markdown'" :source="cell.source.join('')" :html="true" />
        <highlightjs v-else-if="cell.cell_type === 'code'" autodetect :code="cell.source.join('')" class="mb-3"></highlightjs>
        <div v-else>{{ cell.source.join('') }}</div>
        <template v-if="typeof (cell.outputs) !== 'undefined' && cell.outputs !== null && cell.outputs.length > 0">
          <template v-for="(output, idx) in cell.outputs" :key="idx">
            <template v-if="output.data">
              <div v-if="output.data['text/html']" v-html="output.data['text/html'].join('')"></div>
              <div v-else-if="output.data['text/plain'].join('')" class="whitespace-pre-wrap">
                {{ output.data['text/plain'] }}
              </div>
              <div v-else>
                {{ JSON.stringify(output.data, null, 4) }}
              </div>
            </template>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import hljsVuePlugin from '@highlightjs/vue-plugin'
// import hljs from 'highlight.js/lib/core'
import Markdown from 'vue3-markdown-it'

export default {
  name: 'ipynb-renderer',
  props: {
    fileContent: { type: String, required: true }
  },
  emits: ['error-loading', 'finished-loading'],
  components: {
    Markdown,
    highlightjs: hljsVuePlugin.component
  },
  data () {
    return {
      cells: [],
      metadata: null
    }
  },
  watch: {
    fileContent () {
      this.parseNtebook()
    }
  },
  methods: {
    parseNtebook () {
      try {
        const notebookContent = JSON.parse(atob(this.fileContent))
        if (typeof notebookContent.cells === 'undefined' || notebookContent.cells === null) {
          throw new Error('Invalid jupyter notebook format, missing cells')
        }
        if (typeof notebookContent.metadata === 'undefined' || notebookContent.metadata === null) {
          throw new Error('Invalid jupyter notebook format, missing metadata')
        }
        this.cells = notebookContent.cells
        this.metadata = notebookContent.metadata

        const self = this
        this.$nextTick(() => {
          self.$emit('finished-loading')
        })
      } catch (error) {
        console.log(error)
        this.$emit('error-loading')
      }
    }
  },
  mounted () {
    this.parseNtebook()
  }
}
</script>
