export default {
  namespaced: true,
  state: {
    groups: [],
    flows: {},
    requests: {}
  },
  mutations: {
    setGroups (state, groups) {
      state.groups = groups
      for (const group of groups) {
        state.flows[group.name] = []
        state.requests[group.name] = []
      }
    },
    setFlows (state, { groupName, flows }) {
      state.flows[groupName] = flows
    },
    addFlow (state, { groupName, flow }) {
      state.flows[groupName].push(flow)
    },
    updateRequest (state, { groupName, request }) {
      const index = state.requests[groupName].findIndex(r => r.id === request.id)
      if (index !== -1) {
        state.requests[groupName][index] = request
      }
    },
    concatRequests (state, { groupName, requests }) {
      state.requests[groupName] = state.requests[groupName].concat(requests)
    },
    resetFlowData (state) {
      state.groups = []
      state.flows = {}
      state.requests = {}
    }
  },
  actions: {
    loadFlowDetails (context) {
      context.commit('resetFlowData')

      return context.dispatch('fetchGroups').then(response => {
        context.commit('setGroups', response.data)

        for (const flowGroup of response.data) {
          context.dispatch('fetchFlows', flowGroup.name).then(flowResp => {
            const flowData = { groupName: flowGroup.name, flows: flowResp.data }
            context.commit('setFlows', flowData)

            for (const flow of flowData.flows) {
              const requestData = { groupName: flowGroup.name, flowId: flow.id }
              context.dispatch('fetchFlowRequests', requestData).then(reqResp => {
                for (const request of reqResp.data) {
                  request.flowId = flow.id
                }
                const requestData = { groupName: flowGroup.name, requests: reqResp.data }
                context.commit('concatRequests', requestData)
              })
            }
          })
        }
      })
    },
    fetchGroups (context) {
      const getParams = { url: '/flowGroups' }
      return context.dispatch('apiGet', getParams, { root: true })
    },
    fetchFlows (context, groupName) {
      const getParams = { url: `/flowGroups/${groupName}/flows` }
      return context.dispatch('apiGet', getParams, { root: true })
    },
    fetchFlowRequests (context, { groupName, flowId }) {
      const getParams = { url: `/flowGroups/${groupName}/flows/${flowId}/requests` }
      return context.dispatch('apiGet', getParams, { root: true })
    },
    fetchFlowRequestIterations (context, { groupName, flowId, requestId }) {
      const getParams = { url: `/flowGroups/${groupName}/flows/${flowId}/requests/${requestId}/iterations` }
      return context.dispatch('apiGet', getParams, { root: true })
    },
    fetchFlowRequestComments (context, { groupName, flowId, requestId }) {
      const getParams = { url: `/flowGroups/${groupName}/flows/${flowId}/requests/${requestId}/comments` }
      return context.dispatch('apiGet', getParams, { root: true })
    },
    createFlow (context, { groupName, flowName }) {
      const postData = {
        name: flowName
      }
      const postParams = { url: `/flowGroups/${groupName}/flows`, postData }
      return context.dispatch('apiPost', postParams, { root: true })
    },
    createFlowRequest (context, { groupName, flowId, name, content }) {
      const postData = {
        name,
        iterations: [
          { content }
        ]
      }
      const postParams = { url: `/flowGroups/${groupName}/flows/${flowId}/requests`, postData }
      return context.dispatch('apiPost', postParams, { root: true })
    },
    addReviewComment (context, { groupName, flowId, requestId, commentText }) {
      const postData = {
        text: commentText
      }
      const postParams = { url: `/flowGroups/${groupName}/flows/${flowId}/requests/${requestId}/comments`, postData }
      return context.dispatch('apiPost', postParams, { root: true })
    },
    addRequestIteration (context, { groupName, flowId, requestId, content }) {
      const postData = {
        content
      }
      const postParams = { url: `/flowGroups/${groupName}/flows/${flowId}/requests/${requestId}/iterations`, postData }
      return context.dispatch('apiPost', postParams, { root: true })
    },
    abandonRequest (context, { groupName, flowId, requestId }) {
      const putParams = { url: `/flowGroups/${groupName}/flows/${flowId}/requests/${requestId}/abandon` }
      return context.dispatch('apiPut', putParams, { root: true })
    },
    submitRequest (context, { groupName, flowId, requestId }) {
      const putParams = { url: `/flowGroups/${groupName}/flows/${flowId}/requests/${requestId}/submit` }
      return context.dispatch('apiPut', putParams, { root: true })
    },
    acceptRequest (context, requestId) {
      const putParams = { url: `/admin/flowRequest/${requestId}/accept` }
      return context.dispatch('apiPut', putParams, { root: true })
    },
    rejectRequest (context, requestId) {
      const putParams = { url: `/admin/flowRequest/${requestId}/reject` }
      return context.dispatch('apiPut', putParams, { root: true })
    },
    deployIteration (context, { iteration, environment, username, password, authToken }) {
      const putData = {
        passwordCredentials: {
          username,
          password
        },
        tokenCredentials: {
          authToken
        }
      }

      const putParams = { url: `/admin/flowIteration/${iteration.id}/deploy/${environment.id}`, putData }
      return context.dispatch('apiPut', putParams, { root: true })
    },
    fetchDeployments (context, iterationId) {
      const getParams = { url: `/admin/flowIteration/${iterationId}/deployments` }
      return context.dispatch('apiGet', getParams, { root: true })
    },
    fetchFlowMainIterations (context, { groupName, flowId }) {
      const getParams = { url: `/flowGroups/${groupName}/flows/${flowId}/mainIterations` }
      return context.dispatch('apiGet', getParams, { root: true })
    }
  }
}
