<template>
  <entity-editor
    id-column="name"
    entity-type-display="Flow Environment"
    entity-type="flowEnvironments"
    :disabled-actions="['disable']"
    :columns="flowEnvironmentColumns">
  </entity-editor>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'

export default {
  name: 'flow-environment-settings',
  components: {
    EntityEditor
  },
  data () {
    return {
      flowEnvironmentColumns: [
        { field: 'displayName', display: 'Display Name' },
        { field: 'baseUrl', display: 'Base URL' },
        { field: 'readTimeoutMs', display: 'Read Timeout Millis' },
        { field: 'connectTimeoutMs', display: 'Connect Timeout Millis' }
      ]
    }
  }
}
</script>
