<template>
  <div class="max-w-4/5 max-h-4/5 overflow-auto">
    <img class="object-contain w-full h-full" :src="`data:image/png;base64, ${fileContent}`" />
  </div>
</template>

<script>

export default {
  name: 'png-renderer',
  components: {
  },
  props: {
    fileContent: { type: String, required: true }
  },
  emits: ['error-loading', 'finished-loading'],
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
    this.$emit('finished-loading')
  }
}
</script>
