<template>
  <div class="w-4/5 h-4/5 overflow-auto">
    <div id="viewport-container"><div role="main" id="viewport"></div></div>
  </div>
</template>

<script>
import * as pdfjs from 'pdfjs-dist'
const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.min.js')
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

export default {
  name: 'pdf-renderer',
  components: {
  },
  props: {
    fileContent: { type: String, required: true }
  },
  emits: ['error-loading', 'finished-loading'],
  data () {
    return {
      cursorIndex: 0,
      viewport: null
    }
  },
  computed: {
  },
  methods: {
    render (pdfInstance) {
      const totalPages = pdfInstance.numPages

      const renderPagesPromises = []
      for (let i = 0; i < totalPages; i++) {
        renderPagesPromises.push(pdfInstance.getPage(i + 1))
      }

      const self = this
      Promise.all(renderPagesPromises).then(pages => {
        self.$emit('finished-loading')
        self.$nextTick(() => {
          const pagesHTML = '<div style="width: 100%"><canvas></canvas></div>'.repeat(pages.length)
          self.viewport.innerHTML = pagesHTML
          pages.forEach(self.renderPage)
        })
      }).catch(error => {
        console.error(error)
        self.$emit('error-loading')
      })
    },
    renderPage (page) {
      let pdfViewport = page.getViewport({ scale: 1 })

      const container = this.viewport.children[this.cursorIndex]
      this.cursorIndex += 1
      pdfViewport = page.getViewport({ scale: container.offsetWidth / pdfViewport.width })
      const canvas = container.children[0]
      const context = canvas.getContext('2d')
      canvas.height = pdfViewport.height
      canvas.width = pdfViewport.width

      page.render({
        canvasContext: context,
        viewport: pdfViewport
      })
    }
  },
  mounted () {
    this.viewport = document.querySelector('#viewport')

    const self = this
    const base64Str = `data:application/pdf;base64,${this.fileContent}`
    pdfjs.getDocument(base64Str).promise.then(pdf => {
      self.render(pdf)
    }).catch(error => {
      console.error(error)
      self.$emit('error-loading')
    })
  }
}
</script>
