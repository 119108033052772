<template>
  <div class="w-full text-center block">
    <i class="fas fa-spinner fa-spin mr-1"/>
    <span>{{text}}</span>
  </div>
</template>

<script>
export default {
  name: 'loading',
  props: {
    text: { type: String, required: true }
  }
}
</script>
