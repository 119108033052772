<template>
  <div class="w-full space-y-2">
    <template v-if="submitStatus === 'pending' || submitStatus === 'error'">
      <div v-if="submitStatus === 'error'" class="text-red-500 font-bold text-center w-full">
        An error occurred while submitting your feedback. Please try again.
      </div>
      <div class="flex space-x-2 w-full">
        <div class="flex flex-col w-full">
          <strong>Username</strong>
          <input class="form-control" disabled v-model="currentUser.preferred_username" />
        </div>
        <div class="flex flex-col w-full">
          <strong>Name</strong>
          <input class="form-control" disabled v-model="currentUser.name" />
        </div>
      </div>
      <div class="flex flex-col w-full">
        <strong>Email</strong>
        <input class="form-control" disabled v-model="currentUser.email" />
      </div>
      <div class="flex flex-col w-full">
        <strong :class="{'text-red-500': feedbackError}">Feedback Type</strong>
        <v-select :options="feedbackTypes" v-model="feedbackType" placeholder="select the feedback type" :append-to-body="true"></v-select>
        <small v-if="feedbackError" class="text-red-500">You must choose a feedback type</small>
      </div>
      <div class="flex flex-col w-full">
        <strong :class="{'text-red-500': commentError}">Comment</strong>
        <small v-if="feedbackType?.id === 'data'" :class="{'text-red-500': commentError}">For Data Product requests, please be as descriptive as possible and include all of the columns from each dataset you'd like to see.</small>
        <textarea :class="['form-control', {'border-red-500': commentError}]" v-model="commentText" rows="4"></textarea>
        <small v-if="commentError" class="text-red-500">Comment text is required to submit feedback</small>
      </div>
      <div class="flex flex-col w-full">
        <strong :class="{'text-red-500': datasetsError}">Datasets <template v-if="!isDatasetRequired"> - Optional</template></strong>
        <unified-dataset-selector
            ref="datasetSelector"
            v-model="selectedDatasets"
            :allow-multiple="true">
        </unified-dataset-selector>
        <div v-if="datasets.length === 0" class="flex justify-center mt-2 text-gray-600 items-center">
          <i class="fas fa-magnifying-glass mr-2"></i>
          No Datasets Available
        </div>
        <small v-if="datasetsError" class="text-red-500">At least 1 Dataset must be selected</small>
      </div>
      <div class="flex flex-col w-full">
        <strong>Attachments ({{attachments.length}} of 3) - Optional</strong>
        <div v-if="attachments.length > 0" class="border-2 border-theme-500 rounded mb-1">
          <div v-for="(attachment, idx) in attachments" :key="attachment.id" :class="['flex justify-between w-full p-2', {'bg-white': idx % 2 === 0}, {'bg-theme-100': idx % 2 !== 0}]">
            <span>{{attachment.filename}}</span>
            <i class="fas fa-trash text-theme-500 hover:text-theme-200 cursor-pointer" title="Remove Attachment" @click="removeAttachment(attachment)" />
          </div>
        </div>
        <upload-file v-if="attachments.length < 3" @file-uploaded="onFileUploaded"></upload-file>
      </div>
    </template>
    <template v-else-if="submitStatus === 'success'">
      <div class="flex flex-col items-center">
        <h1 class="text-2xl">Thank You!</h1>
        <div>Your feedback has been successfully submitted!</div>
        <div>We will review your feedback and may reach out to you if we have any questions.</div>
      </div>
    </template>
    <template v-else-if="submitStatus === 'submiting'">
      <div class="flex flex-col items-center">
        <loading text="Submitting Feedback..." class="pl-4 pr-2 text-base h-6"></loading>
      </div>
    </template>
  </div>
</template>

<script>
import UnifiedDatasetSelector from '@/components/UnifiedDatasetSelector'
import Loading from '@/components/Loading'
import UploadFile from '@/components/UploadFile'

export default {
  name: 'feedback',
  emits: ['feedback-status'],
  components: {
    UnifiedDatasetSelector,
    Loading,
    UploadFile
  },
  data () {
    return {
      hasTriedToSubmitOnceWithErrors: false,
      feedbackError: false,
      datasetsError: false,
      commentText: '',
      commentError: false,
      feedbackType: null,
      selectedDatasets: [],
      attachments: [],
      feedbackTypes: [
        { id: 'algorithm', label: 'Algorithm Request' },
        { id: 'bug', label: 'Bug' },
        { id: 'data', label: 'Data Product Request' },
        { id: 'feedback', label: 'Feedback' }
      ],
      submitStatus: null
    }
  },
  computed: {
    isDatasetRequired () {
      if (this.feedbackType === null) return false
      return this.feedbackType.id === 'data'
    },
    currentUser () {
      return this.$store.state.currentUser
    },
    datasets () {
      if (this.$store.state.unifiedDatasetCategories === null) return []
      return this.$store.state.unifiedDatasetCategories
    }
  },
  watch: {
    feedbackType () {
      if (this.hasTriedToSubmitOnceWithErrors) this.validateForm()
    },
    commentText () {
      if (this.hasTriedToSubmitOnceWithErrors) this.validateForm()
    },
    selectedDatasets () {
      if (this.hasTriedToSubmitOnceWithErrors) this.validateForm()
    },
    submitStatus () {
      this.$emit('feedback-status', this.submitStatus)
    }
  },
  methods: {
    removeAttachment (attachment) {
      this.$swal({
        icon: 'question',
        title: 'Remove Attachment?',
        text: `Are you sure you want to remove the attachment "${attachment.filename}"?`,
        showCancelButton: true,
        confirmButtonText: 'Remove'
      }).then(result => {
        if (result.isConfirmed) {
          this.attachments = this.attachments.filter(a => a.id !== attachment.id)
        }
      })
    },
    onFileUploaded (file) {
      this.attachments.push(file)
    },
    validateForm () {
      this.feedbackError = this.feedbackType === null
      this.commentError = this.commentText.trim().length === 0
      this.datasetsError = this.isDatasetRequired && this.selectedDatasets.length === 0

      return this.feedbackError || this.commentError || this.datasetsError
    },
    submitFeedback: async function () {
      if (this.validateForm()) {
        this.hasTriedToSubmitOnceWithErrors = true
        return
      }

      this.submitStatus = 'submiting'
      const datasets = this.selectedDatasets.map(d => `${d.model.category.id}/${d.model.dataset.id}`)
      const feedbackPayload = {
        datasets,
        username: this.currentUser.preferred_username,
        name: this.currentUser.name,
        email: this.currentUser.email,
        type: this.feedbackType.id,
        description: this.commentText,
        attachments: this.attachments.map(a => a.id)
      }

      try {
        const response = await this.$store.dispatch('submitFeedback', feedbackPayload)
        if (response.status === 201) {
          this.submitStatus = 'success'
        } else {
          this.submitStatus = 'error'
        }
      } catch (error) {
        console.error(error)
        this.submitStatus = 'error'
      }
    }
  },
  mounted () {
    this.submitStatus = 'pending'
  }
}
</script>
