<template>
  <div class="w-full h-full">
    <map-component @map-init="onMapInit"></map-component>
  </div>
</template>

<script>
import MapComponent from '@/components/MapComponent'
import L from 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'map-polygons',
  components: {
    MapComponent
  },
  props: {
    modelValue: { type: Object, required: false, default: null },
    isReadOnly: { type: Boolean, required: false, default: false }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      map: null,
      polygons: []
    }
  },
  computed: {
    textual () {
      const pluralEnding = this.polygons.length === 1 ? '' : 's'
      return `${this.polygons.length} Geospatial Filter${pluralEnding}`
    }
  },
  watch: {
    polygons () {
      if (this.polygons.length === 0) {
        this.$emit('update:modelValue', null)
        return
      }

      const model = {
        textual: this.textual,
        polygons: this.polygons
      }
      this.$emit('update:modelValue', model)
    }
  },
  methods: {
    onMapInit (map) {
      this.map = map
      map.pm.setGlobalOptions({ allowSelfIntersection: false })

      if (this.isReadOnly === false) {
        map.pm.addControls({
          position: 'topleft',
          drawMarker: false,
          drawCircleMarker: false,
          drawPolyline: false,
          drawCircle: false,
          drawText: false,
          dragMode: false,
          cutPolygon: false,
          rotateMode: false,
          editMode: false,
          oneBlock: true
        })
      }

      const self = this
      map.on('pm:create', created => {
        const layer = created.layer
        layer.id = uuidv4()
        self.polygons.push(self.getPolygonFromLayer(layer))
        self.polygons = JSON.parse(JSON.stringify(self.polygons))
      })

      map.on('pm:remove', removed => {
        const layer = removed.layer
        self.polygons = self.polygons.filter(polygon => {
          return polygon.id !== layer.id
        })
      })

      this.populateMap()
    },
    getPolygonFromLayer (layer) {
      const polygon = JSON.parse(JSON.stringify(layer._latlngs[0]))

      const firstPoint = polygon[0]
      const lastPoint = polygon[polygon.length - 1]
      if (firstPoint.lat !== lastPoint.lat || firstPoint.lng !== lastPoint.lng) {
        // we need to clsoe the polygon
        polygon.push(polygon[0])
      }

      // lets "snap" polygons to be within -180...180
      for (const latlng of polygon) {
        if (latlng.lng < -180) {
          latlng.lng = -180
        } else if (latlng.lng > 180) {
          latlng.lng = 180
        }
      }

      return {
        id: layer.id,
        points: polygon
      }
    },
    populateMap () {
      if (this.map !== null && this.polygons.length > 0) {
        // add our polygons to the newly created map
        for (const polygon of this.polygons) {
          const points = polygon.points.map(point => {
            return [point.lat, point.lng]
          })
          const p = L.polygon(points).addTo(this.map)
          p.id = polygon.id
        }
      }
    }
  },
  mounted () {
    if (this.modelValue !== null) {
      this.polygons = this.modelValue.polygons
      this.populateMap()
    }
  },
  unmounted () {
    this.map.off('pm:create')
    this.map.off('pm:remove')
  }
}
</script>
