function formattedFileSize (bytes) {
  const thresh = 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)

  bytes = bytes.toFixed(1)

  if (bytes % 1 === 0) {
    bytes = Math.trunc(bytes)
  }

  return bytes + ' ' + units[u]
}

export { formattedFileSize }
