<template>
  <span class="whitespace-pre-wrap" v-html="syntaxHighlight(jsonString)"></span>
</template>

<script>

export default {
  name: 'json-viewer',
  components: {
  },
  props: {
    jsonString: { type: String, required: true }
  },
  methods: {
    syntaxHighlight (jsonStr) {
      // taken from https://stackoverflow.com/questions/4810841/pretty-print-json-using-javascript
      jsonStr = jsonStr.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      return jsonStr.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
        let cls = 'text-indigo-500' // number
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'text-red-500' // key
          } else {
            cls = 'text-emerald-700' // string
          }
        } else if (/true|false/.test(match)) {
          cls = 'text-blue-500' // boolean
        } else if (/null/.test(match)) {
          cls = 'text-pink-500' // null
        }
        return '<span class="' + cls + '">' + match + '</span>'
      })
    }
  }
}
</script>
