<template>
  <div>
    <form v-if="modelValue === null && isProcessingFile === false">
      <div class="border-dashed border-2 border-theme-500 hover:bg-theme-200 cursor-pointer p-2" @click="$refs.niflowFileInput.click()">
        <input
          type="file"
          class="hidden"
          accept="text/xml,application/xml,text/yaml,application/yaml,application/x-yaml"
          id="niflowFileInput"
          name="niflowFileInput"
          ref="niflowFileInput"
          :multiple="false"
          @drop="processNiFlowFile($event.dataTransfer.files[0])"
          @change="processNiFlowFile($event.target.files[0])"
        />
        <div class="flex flex-col cursor-pointer items-center justify-center text-theme-500">
          <i class="fas fa-upload block text-xl mb-1"></i>
          <span class="text-sm">Click to Upload a NiFlow File</span>
          <span class="text-xs">5MB File Size Limit</span>
        </div>
      </div>
    </form>
    <loading v-else-if="modelValue === null && isProcessingFile === true" text="Procesing NiFlow File..."></loading>
    <div v-else class="mt-1">
      <div class="flex text-sm font-bold gap-1 items-center"><i class="fas fa-file"></i>{{ fileName }}</div>
      <highlightjs autodetect :code="modelValue" class="max-w-1/2 overflow-x-auto"></highlightjs>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import hljsVuePlugin from '@highlightjs/vue-plugin'

const MAX_NIFLOW_FILE_SIZE = 5000000 // 5 MB

export default {
  name: 'ni-flow-request-file-upload',
  props: {
    modelValue: { type: String, required: false, default: null }
  },
  emits: ['update:modelValue'],
  components: {
    Loading,
    highlightjs: hljsVuePlugin.component
  },
  data () {
    return {
      isProcessingFile: false,
      fileName: null
    }
  },
  methods: {
    async processNiFlowFile (file) {
      if (file.size > MAX_NIFLOW_FILE_SIZE) {
        this.$swal({
          icon: 'error',
          title: 'Large File',
          text: `The file "${file.name}" is too large, files must be under 5MB.`
        })
        return
      }
      try {
        this.isProcessingFile = true
        const content = await file.text()
        this.fileName = file.name
        this.$emit('update:modelValue', content)
      } catch (error) {
        console.error('Error parsing NiFlow file.', error)
        this.$swal({
          icon: 'error',
          title: 'Failed to Read File',
          text: 'An error occurred reading the file. Ensure the file is valid and try again.'
        })
      } finally {
        this.isProcessingFile = false
      }
    }
  }
}
</script>
