<template>
  <fileset-browser :is-using-browser-navigation="true"></fileset-browser>
</template>

<script>
import FilesetBrowser from '@/components/FilesetBrowser'

export default {
  name: 'filesets',
  components: {
    FilesetBrowser
  },
  watch: {
    $route (to) {
      if (to.name === 'Filesets') {
        this.$store.commit('setFilesetQueryParams', to.query)
      }
    }
  }
}
</script>
