<template>
  <div class="w-full text-center mt-3">
    <i class="fas fa-exclamation-triangle mr-1"/>
    <span>{{text}}</span>
    <span :class="['ml-1 cursor-pointer underline', {'text-theme-500 hover:text-theme-300': !lightContent}, {'text-theme-200 hover:text-theme-100': lightContent}]" @click="$emit('retry')">Retry.</span>
  </div>
</template>

<script>
export default {
  name: 'retryable-error',
  props: {
    text: { type: String, required: true },
    lightContent: { type: Boolean, required: false, default: false }
  },
  emits: ['retry']
}
</script>
