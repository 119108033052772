<template>
  <div class="flex flex-col items-center justify-center h-screen w-screen bg-black/80 fixed text-white top-0 left-0 z-100">
    <div class="flex flex-col items-center fixed top-0 bg-black text-center w-full text-xl z-9999 max-h-full">
      <div class="flex justify-between py-2 w-full">
        <span></span>
        <span>{{filename}}</span>
        <span class="flex items-center mr-3">
          <i v-if="metadataFields.length > 0" class="fas fa-circle-info cursor-pointer hover:text-theme-200" @click="showDetails=!showDetails" title="file metadata"></i>
          <a class="ml-3 text-white hover:text-white focus:text-white visited:text-white" :href="downloadFileUrl" :download="filename"><i class="fas fa-download cursor-pointer hover:text-theme-200" title="download"></i></a>
          <i class="fas fa-times ml-3 cursor-pointer hover:text-theme-200" title="close" @click="$emit('close-clicked')"></i>
        </span>
      </div>
      <div v-if="showDetails" class="text-sm w-full justify-start ml-4 overflow-y-auto">
        <div v-for="field in metadataFields" :key="field.title" class="flex items-center justify-start border-t border-gray-700 py-2">
          <i :class="['fas mr-4', field.icon]"></i>
          <div class="flex flex-col items-start">
            <span class="font-bold">{{ field.title }}</span>
            <span>{{ field.value }}</span>
          </div>
        </div>
        <loading v-if="additionalMetadata === null" text="Loading additional metadata..." class="text-base py-2 border-t border-gray-700"></loading>
      </div>
    </div>

    <div v-if="!isFilePreviewable" class="text-white flex flex-col items-center w-2/3 z-999">
      <i class="fas fa-exclamation-triangle text-5xl"/>
      <h1 class="text-2xl">Unsupported File</h1>
      <div class="text-center">This file is not previewable through the web interface. To view the file please <a class="text-theme-200 hover:text-theme-100" :href="downloadFileUrl" :download="filename">download</a> it instead.</div>
    </div>
    <div v-else-if="isOverPreviewLimit && !tryOverLimit" class="text-white flex flex-col items-center w-2/3 z-999">
      <i class="fas fa-exclamation-triangle text-5xl"/>
      <h1 class="text-2xl">Large File</h1>
      <div class="text-center">This file is extremely large and previewing it may crash your browser. We recommend you <a class="text-theme-200 hover:text-theme-100" :href="downloadFileUrl" :download="filename">download</a> the file instead.</div>
      <div class="text-center mt-4">If you'd like to try to preview the file, you can <span class="cursor-pointer text-theme-200 hover:text-theme-100" @click="attemptOverLimitFile">click here.</span></div>
    </div>
    <div v-else-if="hasError" class="w-2/3 z-999">
      <retryable-error text="Error loading preview, try again or download the file." :light-content="true" @retry="loadContent"></retryable-error>
    </div>
    <loading v-else-if="isLoading" text="Loading Preview..." class="pl-4 pr-2 text-base h-6 text-white"></loading>

    <template v-if="fileContent">
      <component v-show="!isLoading && !hasError" :is="previewRenderer" :file-content="fileContent" @error-loading="onErrorLoading" @finished-loading="onFinishedLoading"></component>
    </template>

    <div v-if="isTraversible" class="flex items-center justify-between w-full absolute text-3xl">
      <button :disabled="!hasPrevious || isLoading" :class="['mx-6', {'cursor-pointer hover:text-theme-200': hasPrevious && !isLoading}, {'cursor-not-allowed text-gray-500': !hasPrevious || isLoading}]" @click="onPrevious">
        <i class="fas fa-chevron-left" title="Previous Iten"></i>
      </button>
      <button :disabled="!hasNext || isLoading" :class="['mx-6', {'cursor-pointer hover:text-theme-200': hasNext && !isLoading}, {'cursor-not-allowed text-gray-500': !hasNext || isLoading}]" @click="onNext">
        <i class="fas fa-chevron-right" title="Next Iten"></i>
      </button>
    </div>

  </div>
</template>

<script>
import Loading from '@/components/Loading'
import CsvRenderer from '@/components/renderers/CsvRenderer'
import IpynbRenderer from './renderers/IpynbRenderer'
import JpgRenderer from '@/components/renderers/JpgRenderer'
import JsonRenderer from '@/components/renderers/JsonRenderer'
import PdfRenderer from '@/components/renderers/PdfRenderer'
import PngRenderer from '@/components/renderers/PngRenderer'
import TxtRenderer from '@/components/renderers/TxtRenderer'
import RetryableError from '@/components/RetryableError'
import { Buffer } from 'buffer'
import { formattedFileSize } from '@/utils/Files'
const TXT_FORMATS = ['csv', 'json', 'txt']

const PREVIEWABLE_FILE_TYPES = ['csv', 'pdf', 'jpg', 'png', 'txt', 'json', 'ipynb']

export default {
  name: 'fullscreen-file-preview',
  components: {
    Loading,
    CsvRenderer,
    IpynbRenderer,
    JpgRenderer,
    JsonRenderer,
    PdfRenderer,
    PngRenderer,
    TxtRenderer,
    RetryableError
  },
  props: {
    filename: { type: String, required: true },
    fileUrl: { type: String, required: true },
    fileSize: { type: Number, required: false, default: 0 },
    isTraversible: { type: Boolean, required: false, default: false },
    hasPrevious: { type: Boolean, required: false, default: false },
    hasNext: { type: Boolean, required: false, default: false },
    fetchAdditionalMetadata: { type: Function, required: false, default: null }
  },
  emits: ['close-clicked', 'previous-clicked', 'next-clicked'],
  data () {
    return {
      fileContent: null,
      isLoading: false,
      hasError: false,
      tryOverLimit: false,
      showDetails: false,
      additionalMetadata: null
    }
  },
  computed: {
    metadataFields () {
      let metadataFields = []

      if (this.fileSize > 0) {
        metadataFields.push({
          title: 'File Size',
          icon: 'fa-hard-drive',
          value: formattedFileSize(this.fileSize)
        })
      }

      if (this.additionalMetadata != null) {
        metadataFields = metadataFields.concat(this.additionalMetadata)
      }

      return metadataFields
    },
    isFilePreviewable () {
      const extension = this.filename.split('.').pop()
      return PREVIEWABLE_FILE_TYPES.includes(extension)
    },
    downloadFileUrl () {
      const gatewayApiLocation = this.$store.state.gatewayApiLocation
      return gatewayApiLocation + this.fileUrl
    },
    previewRenderer () {
      return `${this.fileExtension}-renderer`
    },
    fileExtension () {
      return this.filename.split('.').pop().toLowerCase()
    },
    isOverPreviewLimit () {
      if (TXT_FORMATS.includes(this.fileExtension)) {
        // 5mb
        return this.fileSize >= 5000000
      }

      // 50mb
      return this.fileSize >= 50000000
    }
  },
  watch: {
    fileUrl () {
      this.fileContent = null
      this.isLoading = false
      this.hasError = false
      this.tryOverLimit = false
      this.loadContent()
    }
  },
  methods: {
    onKeyDown (event) {
      if (event.keyCode === 37) {
        this.onPrevious()
      } else if (event.keyCode === 39) {
        this.onNext()
      }
    },
    onPrevious () {
      if (this.hasPrevious && !this.isLoading) {
        this.$emit('previous-clicked')
      }
    },
    onNext () {
      if (this.hasNext && !this.isLoading) {
        this.$emit('next-clicked')
      }
    },
    attemptOverLimitFile () {
      this.tryOverLimit = true
      this.loadContent()
    },
    onErrorLoading () {
      this.hasError = true
      this.isLoading = false
      this.fileContent = null
    },
    onFinishedLoading () {
      this.hasError = false
      this.isLoading = false
    },
    async loadContent () {
      // always fetch metadata
      this.additionalMetadata = null
      if (this.fetchAdditionalMetadata === null) {
        this.additionalMetadata = []
      } else {
        this.additionalMetadata = await this.fetchAdditionalMetadata()
      }

      // incompatible type, don't load data
      if (!this.isFilePreviewable) return

      // file over size limit and user didn't ask to try, don't load
      if (this.isOverPreviewLimit && !this.tryOverLimit) return

      this.hasError = false
      this.isLoading = true
      try {
        const response = await this.$store.dispatch('fetchFilesetFile', this.fileUrl)
        this.fileContent = Buffer.from(response.data, 'binary').toString('base64')
      } catch (error) {
        console.error('Error fetching file content', error)
        this.hasError = true
        this.isLoading = false
      }
    }
  },
  mounted () {
    document.body.style.position = 'fixed'
    document.body.style.top = `-${window.scrollY}px`

    this.loadContent()
    window.addEventListener('keydown', this.onKeyDown)
  },
  unmounted () {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
    window.removeEventListener('keydown', this.onKeyDown)
  }
}
</script>
