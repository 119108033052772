<template>
  <entity-editor
    id-column="name"
    entity-type-display="Configuration"
    entity-type="config"
    :disabled-actions="['disable']"
    :columns="configColumns"
    :perform-clear="clearConfigs">
  </entity-editor>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'

export default {
  name: 'configuration-settings',
  components: {
    EntityEditor
  },
  data () {
    return {
      configColumns: [
        { field: 'prestoURL', display: 'Presto URL' },
        { field: 'prestoSource', display: 'Presto Source' },
        { field: 's3URL', display: 'S3 URL' },
        { field: 's3Region', display: 'S3 Region' },
        { field: 'graphiteEnabled', display: 'Graphite Enabled' },
        { field: 'graphiteUrl', display: 'Graphite URL' },
        { field: 'appTitle', display: 'App Title' },
        { field: 'appSubtitle', display: 'App Subtitle' }
      ]
    }
  },
  methods: {
    clearConfigs () {
      // the only setting in config applicable to the UI is the brannding, so update that
      const self = this
      this.$store.dispatch('fetchUIConfiguration').then(response => {
        const settings = response.data
        self.$store.commit('setBranding', settings.appTitle)
      }).catch(error => {
        console.error('Error getting ui configuration', error)
      })
    }
  }
}
</script>
